import { type AnyAction } from "redux";
import actions from "./actions";
import { type RootState } from "@/types/app";
import { type IOrganizationState } from "@/types/organization";

/** @TODO decouple IOrganizationState from the SDK response type. */
export const initialState: IOrganizationState = {
  loading: false,
  // @ts-ignore
  id: null,
  ordering: {
    // @ts-ignore
    driver_id: null,
    // @ts-ignore
    label: null,
    environment: "sandbox",
  },
  organization: {
    // @ts-ignore
    id: null,
    created_at: "",
    updated_at: "",
    deleted_at: "",
    label: "",
    // @ts-ignore
    ordering_driver_id: null,
    loyalty_driver_id: null,
    // @ts-ignore
    external_provider_id: null,
    last_synced_at: "",
    nutrition_url: "",
    // @ts-ignore
    upsell_driver_id: null,
  },
  payment: {
    // @ts-ignore
    driver_id: null,
    label: "",
    meta: [],
  },
  loyalty: {
    // @ts-ignore
    driver_id: null,
    label: "",
    // @ts-ignore
    features: null,
    // @ts-ignore
    program_type: null,
  },
  external_auth: {
    // @ts-ignore
    driver_id: null,
    label: "",
    // @ts-ignore
    registration_fields: null,
    // @ts-ignore
    updatable_fields: null,
    // @ts-ignore
    readonly_fields: null,
    // @ts-ignore
    features: null,
  },
};

export const organization = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_ORGANIZATION:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.FETCH_ORGANIZATION_SUCCESS:
      return {
        loading: false,
        id: action.organizationConfig?.organization.id,
        ...action.organizationConfig,
      };
    case actions.FETCH_ORGANIZATION_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
};

export const organizationSliceSelector = (state: RootState) =>
  state.app.organization;
