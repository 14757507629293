export interface IGlobalFeatureBag {
  [FEATURE_FLAGS.GLOBAL__LOCATIONS_PAGE]: boolean;
  [FEATURE_FLAGS.STORE__FULFILLMENT_TOGGLE]: boolean;
  [FEATURE_FLAGS.STORE__MENU_SEARCH]: boolean;
  [FEATURE_FLAGS.STORE__DIETARY_PREFERENCES]: boolean;
  [FEATURE_FLAGS.ACCOUNT__REWARDS]: boolean;
  [FEATURE_FLAGS.ACCOUNT__INBOX]: boolean;
  [FEATURE_FLAGS.ACCOUNT__ORDER_HISTORY]: boolean;
  [FEATURE_FLAGS.ACCOUNT__FAVORITES]: boolean;
  [FEATURE_FLAGS.ANDROID__FAVORITES]: boolean;
  [FEATURE_FLAGS.ACCOUNT__FAVORITE_LOCATIONS_ENABLED]: boolean;
  [FEATURE_FLAGS.ANDROID__REORDER]: boolean;
  [FEATURE_FLAGS.ANDROID__REORDER_LIST]: boolean;
  [FEATURE_FLAGS.ANDROID__REORDER_CTA]: boolean;
  [FEATURE_FLAGS.ME__REORDER]: boolean;
  [FEATURE_FLAGS.CART__CHANGE_LOCATION]: boolean;
  [FEATURE_FLAGS.CHECKOUT__HANDOFF_DETAILS]: boolean;
  [FEATURE_FLAGS.CHECKOUT__HANDOFF_DINE_IN_INSTRUCTIONS]: boolean;
  [FEATURE_FLAGS.CHECKOUT__WANTED_AT_TIME]: boolean;
  [FEATURE_FLAGS.CHECKOUT__ESCAPE_LOCKED_CONVEYANCE]: boolean;
  [FEATURE_FLAGS.DELIVERY_STATUS_ENABLED]: boolean;
}

export enum FEATURE_FLAGS {
  GLOBAL__LOCATIONS_PAGE = "GLOBAL__LOCATIONS_PAGE",
  STORE__FULFILLMENT_TOGGLE = "STORE__FULFILLMENT_TOGGLE",
  STORE__MENU_SEARCH = "STORE__MENU_SEARCH",
  STORE__DIETARY_PREFERENCES = "STORE__DIETARY_PREFERENCES",
  ACCOUNT__INBOX = "ACCOUNT__INBOX",
  ACCOUNT__REWARDS = "ACCOUNT__REWARDS",
  ACCOUNT__ORDER_HISTORY = "ACCOUNT__ORDER_HISTORY",
  ACCOUNT__FAVORITES = "ACCOUNT__FAVORITES",
  ACCOUNT__FAVORITE_LOCATIONS_ENABLED = "ACCOUNT__FAVORITE_LOCATIONS_ENABLED",
  ANDROID__FAVORITES = "ANDROID__FAVORITES",
  ANDROID__REORDER = "ANDROID__REORDER",
  ANDROID__REORDER_LIST = "ANDROID__REORDER_LIST",
  ANDROID__REORDER_CTA = "ANDROID__REORDER_CTA",
  ME__REORDER = "ME__REORDER",
  CART__CHANGE_LOCATION = "CART__CHANGE_LOCATION",
  CHECKOUT__HANDOFF_DETAILS = "CHECKOUT__HANDOFF_DETAILS",
  CHECKOUT__HANDOFF_DINE_IN_INSTRUCTIONS = "CHECKOUT__HANDOFF_DINE_IN_INSTRUCTIONS",
  CHECKOUT__WANTED_AT_TIME = "CHECKOUT__WANTED_AT_TIME",
  CHECKOUT__ESCAPE_LOCKED_CONVEYANCE = "CHECKOUT__ESCAPE_LOCKED_CONVEYANCE",
  DELIVERY_STATUS_ENABLED = "DELIVERY_STATUS_ENABLED",
}

export const FALLBACK_FEATURE_BAG: IGlobalFeatureBag = {
  [FEATURE_FLAGS.GLOBAL__LOCATIONS_PAGE]: true,
  [FEATURE_FLAGS.STORE__FULFILLMENT_TOGGLE]: true,
  [FEATURE_FLAGS.STORE__MENU_SEARCH]: false,
  [FEATURE_FLAGS.STORE__DIETARY_PREFERENCES]: true,
  [FEATURE_FLAGS.ACCOUNT__REWARDS]: false,
  [FEATURE_FLAGS.ACCOUNT__INBOX]: false,
  [FEATURE_FLAGS.ACCOUNT__ORDER_HISTORY]: true,
  [FEATURE_FLAGS.ACCOUNT__FAVORITES]: true,
  [FEATURE_FLAGS.ANDROID__FAVORITES]: true,
  [FEATURE_FLAGS.ACCOUNT__FAVORITE_LOCATIONS_ENABLED]: false,
  [FEATURE_FLAGS.ANDROID__REORDER]: false,
  [FEATURE_FLAGS.ANDROID__REORDER_LIST]: false,
  [FEATURE_FLAGS.ANDROID__REORDER_CTA]: true,
  [FEATURE_FLAGS.ME__REORDER]: false,
  [FEATURE_FLAGS.CART__CHANGE_LOCATION]: true,
  [FEATURE_FLAGS.CHECKOUT__HANDOFF_DETAILS]: true,
  [FEATURE_FLAGS.CHECKOUT__HANDOFF_DINE_IN_INSTRUCTIONS]: true,
  [FEATURE_FLAGS.CHECKOUT__WANTED_AT_TIME]: true,
  [FEATURE_FLAGS.CHECKOUT__ESCAPE_LOCKED_CONVEYANCE]: false,
  [FEATURE_FLAGS.DELIVERY_STATUS_ENABLED]: false,
};

export enum FLAG_RELATIONSHIP {
  AND = "AND",
  OR = "OR",
}
