import { type OrderDetails } from "@koala/sdk";
import find from "lodash/find";

export function fetchOrderDetails(orderId: string, pastOrders: Array<{}>) {
  return find(pastOrders, { order_data: { order: { order_number: orderId } } });
}

export const isOrderFavorited = (
  orderId: string,
  meFavoriteOrders: OrderDetails[]
) => {
  if (!meFavoriteOrders || !meFavoriteOrders.length) {
    return false;
  }

  return meFavoriteOrders.some((order: any) => order.id === orderId);
};

/**
 * Derive an array of orders by optional location ID and specified length
 *
 * @param pastOrders OrderDetails[]
 * @returns OrderDetails[]
 */
export const deriveOrdersByLocationID = (
  pastOrders: OrderDetails[],
  locationID?: number
): OrderDetails[] => {
  // If a location ID is provided, specifically filter reorders by this ID
  if (locationID) {
    return pastOrders.filter((order) => order.store_location_id === locationID);
  }

  return pastOrders;
};
