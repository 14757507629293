import { type AnyAction } from "redux";
import actions from "./actions";
import { type IAllergensState } from "@/types/allergens";

export const initialState: IAllergensState = {
  allergens: [],
  activeAllergens: [],
};

export const allergens = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_ALLERGENS:
      return state;
    case actions.ALLERGENS_SUCCESS:
      return Object.assign({}, state, {
        allergens: action.detail ? action.detail.slice(0) : [],
      });
    case actions.UPDATE_ALLERGENS:
      return Object.assign({}, state, {
        activeAllergens: action.updatedAllergens
          ? action.updatedAllergens.slice(0)
          : [],
      });
    case actions.TOGGLE_ALLERGEN:
      const activeAllergens = state.activeAllergens.slice(0);

      const index = activeAllergens.indexOf(action.id);
      if (index > -1) {
        activeAllergens.splice(index, 1);
      } else {
        activeAllergens.push(action.id);
      }

      return Object.assign({}, state, {
        activeAllergens,
      });
    case actions.ALLERGENS_FAIL:
      return state;
    default:
      return state;
  }
};
