export enum OrderStatusTypes {
  OK = "ok",
  WARNING = "warning",
  ERROR = "error",
  UPDATING = "updating",
  RECONCILIATION = "reconciliation",
  TRANSMITTING = "transmitting",
}

export enum OrderStatuses {
  ORDER_NOT_INITIALIZED = "orderNotInitialized",
  ORDER_PENDING = "orderPending",

  INITIALIZING_ORDER = "initializingOrder",
  INITIALIZING_ORDER_ERROR = "initializingOrderError",
  VALIDATING_ORDER = "validatingOrder",
  PAYMENT_TYPES_GETTING = "paymentTypesGetting",
  PROMO_CODE_ADDING = "promoCodeAdding",
  PROMO_CODE_REMOVING = "promoCodeRemoving",
  BASKET_REWARDS_GETTING = "basketRewardsGetting",
  BASKET_REWARDS_ADDING = "basketRewardsAdding",
  BASKET_REWARDS_REMOVING = "basketRewardsRemoving",
  TIP_ADDING = "tipAdding",
  UTENSILS_SETTING = "utensilsSetting",
  BASKET_INSTRUCTIONS_SETTING = "basketInstructionsSetting",
  CONVEYANCE_SETTING = "conveyanceSetting",
  CONVEYANCE_DELIVERY_SETTING = "conveyanceDeliverySetting",
  STORED_VALUE_POSTING = "storedValuePosting",
  WANTED_AT_BASKET_SETTING = "wantedAtBasketSetting",
  WANTED_AT_BASKET_REMOVING = "wantedAtBasketRemoving",
  GETTING_PAYMENT_TOKEN = "gettingPaymentToken",
  GETTING_PAYMENT_TOKEN_ERROR = "gettingPaymentTokenError",
  ORDER_SUBMISSION_TRIGGERED = "orderSubmissionTriggered",
  ORDER_SUBMISSION_PCI_FAILURE = "orderSubmissionPciFailure",
  ORDER_SUBMISSION_STARTED = "orderSubmissionStarted",
  ORDER_SUBMISSION_ERROR = "orderSubmissionError",

  ORDER_COMPLETE = "orderComplete",
}

export enum OrderStatusMessages {
  INITIALIZING_ORDER_ERROR = "An error occurred starting your order.",
  BASKET_REWARDS_ADDING_ERROR = "Oops, an error occurred adding the reward to your order.",
  BASKET_REWARDS_REMOVING_ERROR = "Oops, an error occurred removing the reward from your order.",
  TIP_ADDING_ERROR = "Oops, an error occurred adding a tip to your order.",
  UTENSIL_SETTING_ERROR = "Oops, an error occurred adding utensils to your order.",
  BASKET_INSTRUCTIONS_SETTING_ERROR = "Oops, an error occurred adding your basket instructions.",
  ORDER_SUBMISSION_ERROR = "Oops, an error occurred submitting your order.",
}

export interface IOrderStatusState {
  type: OrderStatusTypes;
  message: string | null;
  status: OrderStatuses;
}
