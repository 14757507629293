import { type SupportedPaymentTypes } from "@koala/sdk";
import { type AnyAction } from "redux";
import actions from "./actions";

export type PaymentTypesState = SupportedPaymentTypes | null;
export const initialState: PaymentTypesState = null;

export const paymentTypes = (
  state: PaymentTypesState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actions.PAYMENT_TYPES_GET_SUCCESS:
      return action.paymentTypes;
    case actions.PAYMENT_TYPES_CLEAR:
      return initialState;
    default:
      return state;
  }
};
