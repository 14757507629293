import { FEATURE_FLAGS } from "./features";
import { LOYALTY_ROUTES } from "./routes";

export const LOYALTY_MESSAGE_TYPES = {
  BUSINESS: "business_wide",
  PERSONAL: "user_specific",
};

export const REWARD_TYPE = {
  REWARD: "redeemable_id",
  OFFER: "reward_id",
};

export const MESSAGE_CTA_TYPES = {
  DEEP_LINK: "deep_link",
  DRILL_DOWN: "drill_down",
  WEB_LINK: "external_web_link",
};

export const MESSAGE_HERO_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
};

export const PROGRAM_TYPES = {
  // Punchh
  POINTS_CONVERT_REDEEMABLES: "points_convert_redeemables",
  POINTS_CONVERT_CURRENCY: "points_convert_currency",
  POINTS_CONVERT_REWARDS: "points_convert_rewards",
  // Spendgo
  SP_POINTS_CONVERT_REWARDS: "sp_points_convert_rewards",
};

export enum LOYALTY_ACCESSOR_TYPES {
  FEATURE,
  ROUTE,
}

export enum LOYALTY_FEATURES {
  GET_AVAILABLE_REWARDS = "getAvailableRewards",
  APPLY_REWARDS = "applyRewards",
  GET_APPLIED_REWARDS = "getAppliedRewards",
  DESTROY_REWARD = "destroyReward",
  CLAIM_REWARDS = "claimRewards", // not currently used
  CLAIM_REWARDS_BY_RECEIPT = "claimRewardsByReceipt",
  INDEX_MY_REWARDS = "indexMyRewards",
  INDEX_REDEEMABLES = "indexRedeemables",
  INDEX_REDEMPTIONS = "indexRedemptions",
  CREATE_REDEMPTION_USING_REDEEMABLE = "createRedemptionUsingRedeemable",
  CREATE_REDEMPTION_USING_REWARD = "createRedemptionUsingReward",
  SHOW_LOYALTY_HISTORY = "showLoyaltyHistory",
  INDEX_LOYALTY_MESSAGES = "indexLoyaltyMessages",
  DESTROY_LOYALTY_MESSAGE = "destroyLoyaltyMessage",
  MARK_READ_LOYALTY_MESSAGE = "markReadLoyaltyMessage",
  SHOW_LOYALTY_STATE = "showLoyaltyState",
  SUPPORTS_QR = "supportsQR",
}

export const LOYALTY_ROUTE_FEATURE_MAP = {
  [LOYALTY_ROUTES.ORDER_HISTORY]: FEATURE_FLAGS.ACCOUNT__ORDER_HISTORY,
  [LOYALTY_ROUTES.FAVORITES]: FEATURE_FLAGS.ACCOUNT__FAVORITES,
};
