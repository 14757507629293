import { type AnyAction } from "redux";
import actions from "./actions";
import { type IBasketRewardsState } from "@/types/basketRewards";

export const initialState: IBasketRewardsState = {
  rewardsApplied: [],
  rewardsAvailable: [],
  rewardStatus: "",
  unavailableRewards: [],
  rewardPending: null,
};

export const basketRewards = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // Basket Rewards Set
    case actions.SET_AVAILABLE_REWARDS:
      return {
        ...state,
        rewardsAvailable: action.rewardsAvailable,
      };
    // Basket Rewards Set
    case actions.SET_APPLIED_REWARDS: {
      return {
        ...state,
        rewardsApplied: action.rewardsApplied,
      };
    }
    case actions.SET_PENDING_REWARD:
      return {
        ...state,
        rewardPending: action?.id,
      };
    case actions.SET_UNAVAILABLE_REWARD: {
      return {
        ...state,
        unavailableRewards: state.unavailableRewards.concat([action.reward]),
      };
    }
    case actions.RESET_UNAVAILABLE_REWARDS: {
      return {
        ...state,
        unavailableRewards: [],
      };
    }
    case actions.RESET_BASKET_REWARDS: {
      return {
        ...state,
        rewardsApplied: [],
        unavailableRewards: [],
      };
    }
    default:
      return state;
  }
};
