// Koala Analytics Event Screens
export const K_ANALYTICS_EVENTS = {
  ACCOUNT_CREATED: "ACCOUNT_CREATED",
  BARCODE_APPLY: "BARCODE_APPLY",
  BASKET_CUSTOMIZED: "BASKET_CUSTOMIZED",
  BASKET_EDIT_TRIGGER: "BASKET_EDIT_TRIGGER",
  BASKET_INSTRUCTIONS_SET: "BASKET_INSTRUCTIONS_SET", // TODO
  BASKET_RECONCILIATION: "BASKET_RECONCILIATION",
  BASKET_STAGED: "BASKET_STAGED",
  BASKET_UPDATE: "BASKET_UPDATE",
  BASKET_WANTED_AT_UPDATE: "BASKET_WANTED_AT_UPDATE",
  CART_PERSISTED: "CART_PERSISTED",
  CART_RESET: "CART_RESET",
  CART_VIEW: "CART_VIEW",
  CHECKOUT_VIEW: "CHECKOUT_VIEW",
  CONFIRM_ARRIVAL_SUCCESS: "CONFIRM_ARRIVAL_SUCCESS",
  CONVEYANCE_TOGGLE: "CONVEYANCE_TOGGLE",
  CREDIT_CARD_DELETED: "CREDIT_CARD_DELETED",
  CREDIT_CARD_SAVED: "CREDIT_CARD_SAVED",
  CROSS_SELL_PRESENTED: "CROSS_SELL_PRESENTED",
  CTA: "CTA",
  DELIVERY_ADDRESS_VALIDATED: "DELIVERY_ADDRESS_VALIDATED",
  DELIVERY_TOGGLE: "DELIVERY_TOGGLE",
  DIETARY_PREFERENCES_UPDATED: "DIETARY_PREFERENCES_UPDATED",
  ERROR: "ERROR",
  FIRST_PRODUCT_ADDED: "FIRST_PRODUCT_ADDED",
  GEOLOCATION_SEARCH: "GEOLOCATION_SEARCH",
  GIFT_CARD_APPLIED: "GIFT_CARD_APPLIED",
  GIFT_CARD_REMOVED: "GIFT_CARD_REMOVED",
  ITEMS_NOT_AVAILABLE: "ITEMS_NOT_AVAILABLE",
  LOCATION_CHANGE: "LOCATION_CHANGE",
  LOCATION_CTA: "LOCATION_CTA",
  LOCATION_FAVORITE_CTA: "LOCATION_FAVORITE_CTA",
  LOCATION_FAVORITED: "LOCATION_FAVORITED",
  LOCATION_SEARCH_NOT_FOUND: "LOCATION_SEARCH_NOT_FOUND",
  LOCATION_SEARCH: "LOCATION_SEARCH",
  LOCATION_UNFAVORITED: "LOCATION_UNFAVORITED",
  LOCATION_VIEW_TOGGLE: "LOCATION_VIEW_TOGGLE",
  LOCATION_RECOMMENDATIONS_SURFACED: "LOCATION_RECOMMENDATIONS_SURFACED",
  LOG: "LOG",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  LOYALTY_MESSAGE_DELETE: "LOYALTY_MESSAGE_DELETE",
  LOYALTY_MESSAGE_READ: "LOYALTY_MESSAGE_READ",
  MASTER_CATEGORY_SELECTED: "MASTER_CATEGORY_SELECTED",
  MENU_SEARCH: "MENU_SEARCH",
  MENU_VIEW: "MENU_VIEW",
  ORDER_FAVORITED: "ORDER_FAVORITED",
  ORDER_SUBMISSION_STARTED: "ORDER_SUBMISSION_STARTED",
  ORDER_SUBMISSION_TRIGGERED: "ORDER_SUBMISSION_TRIGGERED",
  ORDER_SUBMITTED: "ORDER_SUBMITTED",
  ORDER_UNFAVORITED: "ORDER_UNFAVORITED",
  PRODUCT_VIEW: "PRODUCT_VIEW",
  PROMO_CODE_APPLIED: "PROMO_CODE_APPLIED",
  PROMO_CODE_REMOVED: "PROMO_CODE_REMOVED",
  QR_MEMBERSHIP_VIEW: "QR_MEMBERSHIP_VIEW",
  QR_OFFER_VIEW: "QR_OFFER_VIEW",
  QR_REWARD_VIEW: "QR_REWARD_VIEW",
  REORDER_ERROR: "REORDER_ERROR",
  REORDER: "REORDER",
  REWARD_APPLIED: "REWARD_APPLIED",
  REWARD_REMOVED: "REWARD_REMOVED",
  SCREEN: "SCREEN",
  SESSION_START_WITH_EXISTING_BASKET: "SESSION_START_WITH_EXISTING_BASKET",
  SESSION_START: "SESSION_START",
  SHOW_TOOLTIP: "SHOW_TOOLTIP",
  STORED_ADDRESS_DELETED: "STORED_ADDRESS_DELETED",
  TIP_ADDED: "TIP_ADDED",
  USER_UPDATE: "USER_UPDATE",
  UTENSILS_SET: "UTENSILS_SET", // TODO
  VIEW_ALL_LOCATIONS: "VIEW_ALL_LOCATIONS",
  VIEW_LOCATION_INFO: "VIEW_LOCATION_INFO",
  VIEW_REWARDS: "REWARDS_VIEW",
  VIEW_ORDER_HISTORY: "ORDER_HISTORY_VIEW",
  VIEW_FAVORITES: "FAVORITES_VIEW",
  VIEW_STORE_LOCATOR: "STORE_LOCATOR_VIEW",
  VIEW_INBOX: "INBOX_VIEW",
  VIEW_DISPATCH_TRACKING: "DISPATCH_TRACKING_VIEW",
  WANTED_AT_SELECTED: "WANTED_AT_SELECTED",
  ORDER_SUBMISSION_FAILED: "ORDER_SUBMISSION_FAILED",
};

export enum K_ANALYTICS_PLATFORMS {
  DESKTOP_WEB = "desktop_web",
  MOBILE_WEB = "mobile_web",
  // Android is for use when the web app is a web-view inside our Android Kotlin shell
  ANDROID = "mobile_android",
}

// Used in `fireGenericOrderEventsHandler`
export const BASKET_ORDER_KEYS = {
  TRANSACTION: "transaction",
  ORDER: "order",
  BASKET_PRODUCTS: "basket_products",
};

export const ERROR_MESSAGES = {
  APPLICATION_ERROR: "Application Error",
  BASKET_VALIDATION_ERROR: "Basket Validation Error",
  LOGIN_FAILURE: "Login Failure",
  LOGOUT_FAILURE: "Logout Failure",
  CREATE_ACCOUNT_FAILURE: "Create Account Failure",
  RESTART_USER_SESSION_FAILURE: "Restart User Session Failure",
  FORGOT_PASSWORD_FAILURE: "Forgot Password Failure",
  PASSWORD_RESET_FAILURE: "Password Reset Failure",
  VALIDATE_DELIVERY_ADDRESS_ERROR: "Validate Delivery Address Error",
  SET_DELIVERY_ADDRESS_ERROR: "Set Delivery Address Error",
  SET_WANTED_TIME_ERROR: "Set Wanted Time Error",
  FETCH_LOCATIONS_FAILURE: "Fetch Locations Failure",
  FETCH_MORE_LOCATIONS_FAILURE: "Fetch More Locations Failure",
  INVALID_ZIP_ERROR: "Invalid Zip Error",
  SEARCH_LOCATION_ERROR: "Search Location Error",
  FETCH_LOCATION_ERROR: "Fetch Location Error",
  HYDRATE_LOCATION_ERROR: "Hydrate Location Error",
  FETCH_REWARDS_ERROR: "Fetch Rewards Error",
  FETCH_SAVED_CARDS_FAILURE: "Fetch Saved Cards Failure",
  ADD_REWARDS_FAILURE: "Add Rewards Failure",
  REWARD_NOT_VALID: "Reward Not Valid",
  ADD_PROMO_FAILURE: "Add Promo Failure",
  CANNOT_FIND_PROMO_TO_REMOVE: "Cannot Find Promo To Remove",
  REMOVE_PROMO_FAILURE: "Remove Promo Failure",
  REMOVE_REWARDS_FAILURE: "Remove Rewards Failure",
  SET_PICKUP_TIME_ERROR: "Set Pickup Time Error",
  SET_CONVEYANCE_ERROR: "Set Conveyance Failure",
  SET_CONVEYANCE_ERROR_ERROR: "Error Setting Conveyance Failure",
  REMOVE_PICKUP_TIME_ERROR: "Remove Pickup Time Error",
  ADD_TIP_ERROR: "Add Tip Error",
  ADD_ITEM_TOAST_ERROR: "Add Item Toast Error",
  CROSS_SELL_QUICK_ADD_TOAST_ERROR: "Cross Sell Quick Add Toast Error",
  FETCH_USER_DETAILS_ERROR: "Fetch User Details Error",
  FETCH_FAVORITE_LOCATIONS_ERROR: "Fetch Favorite Locations Error",
  UPDATE_ME_FAILURE: "Update Me Failure",
  FETCH_FAVORITE_ORDERS_ERROR: "Fetch Favorite Orders Error",
  FETCH_ORDER_HISTORY_ERROR: "Fetch Order History Error",
  FETCH_PAYMENT_INFORMATION_ERROR: "Fetch Payment Information Error",
  FETCH_ALL_LOCATIONS_CC_ERROR: "Fetch All Locations Credit Cards Error",
  FETCH_ORDER_DETAILS_ERROR: "Fetch Order Details Error",
  DELETE_CREDIT_CARD_ERROR: "Delete Credit Card Error",
  UNFAVORITE_LOCATION_ERROR: "Unfavorite Location Error",
  FAVORITE_LOCATION_ERROR: "Favorite Location Error",
  UNFAVORITE_ORDER_ERROR: "Unfavorite Order Error",
  FAVORITE_ORDER_ERROR: "Favorite Order Error",
  FETCH_MENU_ERROR: "Fetch Menu Error",
  FETCH_HANDOFF_TIMES_BY_LOCATION_ERROR:
    "Fetch Handoff Times by Location Error",
  FETCH_HANDOFF_TIMES_BY_BASKET_ERROR: "Fetch Handoff Times by Basket Error",
  SIDECART_OPENED_BEFORE_MENU_LOAD:
    "Sidecart was opened before menu finished loading",
  FETCH_BASKET_MENU_ERROR: "Fetch Basket Menu Error",
  BASKET_MENU_MISMATCH: "Mismatch between basket items and sessionStorage menu",
  BASKET_MENU_NOT_FOUND:
    "There is no store menu associated with a user's current cart items",
  CHECK_DELIVERY_COVERAGE_ERROR: "Check Delivery Coverage error",
  FETCH_BATCH_CONFIGS_ERROR: "Error batch fetching configs",
  FETCH_CONFIG_ERROR: "Error batch fetching configs",
  FETCH_ORG_ERROR: "Error fetching organization",
  REDEEM_BARCODE_ERROR: "Error redeeming receipt barcode",
  REDEEM_REWARD_ERROR: "Error redeeming reward",
  REDEEM_OFFER_ERROR: "Error redeeming offer",
  FETCH_LOYALTY_STATE_ERROR: "Error fetching loyalty state",
  FETCH_MESSAGES_ERROR: "Error fetching messages",
  FETCH_REWARDS_HISTORY_ERROR: "Error fetching rewards history",
  MARK_AS_READ_ERROR: "Error marking message as read",
  DELETE_MESSAGE_ERROR: "Error deleting message",
  FETCH_REDEMPTIONS_ERROR: "Error fetching redemptions",
  REORDER_ERROR: "Error reordering basket",
  FETCH_TAGS_ERROR: "Error fetching tags",
  FETCH_STORED_ADDRESSES_ERROR: "Error fetching stored addresses",
  DELETE_STORED_ADDRESS_ERROR: "Error deleting stored address",
  SET_DEFAULT_STORED_ADDRESS_ERROR: "Error setting default stored address",
  UPDATE_STORE_MENU_URL_ERROR:
    "Error assembling and updating store menu URL with an invalid store ID",

  // Auth Errors
  SIGNUP_LOGIN_FAILURE: "Login failure after account creation",

  // Checkout Errors
  INIT_CHECKOUT_HANDLED_FAILURE: "Init Checkout Handled Failure",
  INIT_CHECKOUT_LOCK_FAILURE: "Init Checkout Lock Failure",
  INIT_CHECKOUT_UNHANDLED_FAILURE: "Init Checkout Unhandled Failure",
  INIT_CHECKOUT_THROTTLING_FAILURE: "Init Checkout Throttling Failure",
  INIT_CHECKOUT_PHONE_FAILURE: "Init Checkout Phone Failure",
  INIT_CHECKOUT_UNHANDLED_CONVEYANCE_FAILURE:
    "Init Checkout Unhandled Conveyance Failure",
  INIT_CHECKOUT_UNHANDLED_WANTED_AT_FAILURE:
    "Init Checkout Unhandled Wanted At Failure",
  INIT_CHECKOUT_UNHANDLED_BASKET_FAILURE:
    "Init Checkout Unhandled Basket Failure",
  POST_INIT_CHECKOUT_FAILURE: "Post-init Checkout Failure",
  PAYMENT_TYPES_GET_ERROR: "Error getting basket payment types",
  SUBMIT_EVENTS_ERROR: "Error submitting checkout events",
  UTENSILS_ERROR: "Error setting utensils",
  BASKET_INSTRUCTIONS_ERROR: "Error setting basket instructions",
  BASKET_STAGED_EVENTS_ERROR: "Error submitting basket staged events",
  KA_PRODUCT_OPTIONS_UNDEFINED: "Koala product options undefined",
  KA_PRODUCT_OPTIONS_NOT_FOUND: "Product option not found in KA dict",
  PCI_PROXY_UNCAUGHT_ERROR: "PCI Proxy Uncaught Error",
  PCI_PROXY_SLOW_IFRAME_LOAD: "PCI Proxy iFrame Slow To Load",
  PCI_PROXY_IFRAME_FAILED_TO_LOAD: "PCI Proxy iFrame Failed To Load",
  TRIGGER_SUBMIT_CHECKOUT_ERROR: "Trigger Submit Checkout Error",
  POST_STORED_VALUE_ERROR: "Error getting stored value",
  PAYMENT_TOGGLE_SAGA_ERROR: "Error toggling payment type",
  BASKET_VALIDATION_POST_TIP_REWARD:
    "Basket validation error after tip/reward add",
  ADD_USER_PHONE_ERROR: "Error adding user phone number",

  // Basket submission errors
  BASKET_NOT_VALID: "Basket Not Valid",
  LOCATION_NOT_AVAILABLE: "Online Ordering Not Available",
  PAYMENT_DENIED_ERROR: "Payment Denied Error",
  SUBMIT_CHECKOUT_ERROR: "Submit Checkout Error",

  // Commerce Errors
  ERROR_LOGGING_COMMERCE_EVENT: "Error logging commerce event",

  // Handoff-Based Search Results
  SEARCH_DELIVERY_COVERAGE_ERROR: "Error searching for delivery locations",

  // Google Maps/Places
  GOOGLE_PLACES_INIT_ERROR: "Error initializing Google Places",

  // Apple Sign-In
  APPLE_SIGN_IN_ERROR: "Error with Apple Sign In",

  // Customer Arrival Notification
  ARRIVAL_CONFIRM_ERROR: "Error confirming customer arrival",
  ARRIVAL_FETCH_ERROR: "Error fetching arrival status",
};

export const LOG_EVENTS = {
  SESSION_PERSISTED: "SESSION_PERSISTED",
  PERSISTED_WANTED_AT_UNAVAILABLE: "PERSISTED_WANTED_AT_UNAVAILABLE",
  GOOGLE_PLACES_INITIALIZED: "GOOGLE_PLACES_INITIALIZED",
  GOOGLE_ADDRESS_NOT_SELECTED: "GOOGLE_ADDRESS_NOT_SELECTED",
  USER_PHONE_ADDED: "USER_PHONE_ADDED",
  FACEBOOK_LOGIN_CLICK: "FACEBOOK_LOGIN_CLICK",
  GOOGLE_LOGIN_CLICK: "GOOGLE_LOGIN_CLICK",
  APPLE_LOGIN_CLICK: "APPLE_LOGIN_CLICK",
  MENU_SSR_FETCHED: "MENU_SSR_FETCHED",
  MENU_CSR_FETCHED: "MENU_CSR_FETCHED",
};

export const K_ANALYTICS_CTA_LOCATIONS = {
  SIDECART: "Sidecart",
  DETAIL_MODAL: "Store Detail Modal",
};

export const MODAL = {
  DIETARY_PREFERENCES_MODAL: "Dietary Preference Modal",
  CART_MIGRATION_MODAL: "Cart Migration Modal",
  CTA_OVERFLOW: "CTA Overflow Modal",
  FULFILLMENT_MANAGER: "Fulfillment Manager Modal",
  MANAGE_PAYMENT_METHODS: "Manage Payment Methods Modal",
  CART_MIGRATION_ACCEPTED: "Accepted - Cart Migration",
  CART_MIGRATION_DISMISSED: "Dismissed - Cart Migration",
  CART_MIGRATION_NOTICES_MODAL: "Cart Migration Notices Modal",
  CANNOT_DELIVER_TO_ADDRESS_MODAL: "Cannot Deliver to Address Modal",
  REWARDS_HISTORY: "Rewards History Modal",
  ACCOUNT_DROPDOWN: "Account Dropdown",
  REDEEM_IN_STORE: "Redeem In Store Modal",
  BARCODE_RECEIPT: "Barcode Receipt Modal",
  FAVORITE_LOCATION: "Favorite Location Modal",
  REORDER_PAST_ORDER: "Reorder Past Order Modal",
};

export enum API_CONVEYANCE_TYPES {
  DELIVERY = "delivery",
  PICKUP = "pick_up",
  CURBSIDE = "curbside",
  DINEIN = "dine_in",
  DRIVETHRU = "drive_thru",
  DRIVEIN = "drive_in",
}
