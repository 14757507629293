import {
  CONVEYANCE_TYPES,
  Customizer,
  type MenuProductOption,
  type MenuProductOptionGroup,
  type MenuProductWithCategory,
  type CheckoutBasket,
  type BasketFee,
  type BasketItem,
  type BasketItemOption,
  type BasketOrder,
  type Category,
  type DeliveryAddress,
  type MenuProduct,
  sdkUtils,
} from "@koala/sdk";
import each from "lodash/each";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import sum from "lodash/fp/sum";
import remove from "lodash/remove";
import { getPersistentParameterValue } from "./global";
import {
  ERROR_MESSAGES,
  K_ANALYTICS_EVENTS,
  type API_CONVEYANCE_TYPES,
} from "@/constants/events";
import {
  ALLOWED_URL_PARAMETER_KEYS,
  API_FOR_CONVEYANCE_TYPES_SWAP,
  LOCKED_FLOW_CONVEYANCE_TYPES,
} from "@/constants/global";
import { type BasketItemFinal } from "@/types/checkout";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";

/**
 * Get subtotal for an array of cart items
 *
 * @param {Array} products - assumes format matches the nullItem
 */
export const getCartSubtotal = (basketItems: (BasketItemFinal | null)[]) => {
  // does not currently account for the cost of selected modifiers --> SDK!
  return flow([
    map((basketItem: BasketItemFinal) => {
      // If we have a null item, the product id in our cart no longer exists in the menu
      // This condition will return a price of 0 for the null item so it at least doesn't crash the cart
      // However, the invalid item will still exist in localStorage
      // invalid items are removed from localStorage when INIT_LOCAL_STORAGE_BASKET is fired
      if (!basketItem) {
        return 0;
      }

      if (basketItem.final.final_cost) {
        return basketItem.final.final_cost * basketItem.item.quantity;
      }

      return basketItem.final.cost;
    }),
    sum,
  ])(basketItems);
};

export const toDollars = (x: number | undefined, quantity = 1) => {
  if (typeof x === "number") {
    return ((x * quantity) / 100).toFixed(2);
  }
  return "";
};

export const invertNumberIfOptionInverted = (
  value: number,
  isInverted: boolean
) => {
  if (!isInverted || value === 0) {
    return value;
  }
  return value > 0 ? value * -1 : Math.abs(value);
};

/**
 * Get Taxes & Fees Totals
 *
 */
export const getTotalTaxesAndFees = (
  checkoutBasket: CheckoutBasket
): string => {
  let total: number = checkoutBasket?.sales_tax + checkoutBasket?.delivery_fee;

  each(checkoutBasket?.fees, (fee: BasketFee) => {
    total += fee.amount;
  });

  return (total / 100).toFixed(2);
};

/**
 * Find a MenuProduct by global_id given a menu
 *
 * @param {itemGlobalId} string
 * @param {menuCategories} array
 */
const findMenuItemByGlobalId = (
  itemGlobalId: string,
  menuCategories: Category[]
) => {
  let product: MenuProduct | undefined;

  each(menuCategories, (category: Category) => {
    each(category.products, (menuProduct: MenuProduct) => {
      if (menuProduct.global_id === itemGlobalId) {
        product = menuProduct;
        return false;
      }

      return;
    });

    if (product && product.id) {
      return false;
    }

    return;
  });

  return product;
};

type Option = BasketItem["options"][number];

export const getOptionsDictionary = (
  selectedOptions: BasketItem["options"] | string[]
) => {
  const newOptionsDictionary:
    | Record<Option["id"], Option>
    | Record<string, { id: string; quantity: number }> = {};

  selectedOptions.forEach((option) =>
    typeof option === "object"
      ? (newOptionsDictionary[option.option_id ?? option.id] = option)
      : (newOptionsDictionary[option] = { id: option, quantity: 1 })
  );

  return newOptionsDictionary;
};

/**
 * @param  {BasketItem["options"]} basketItemOptions - collections of the basket item options selected by user
 * @param  {MenuProductOptionGroup[]} currentLocationProductOptionGroups - collection of option groups of the product that was fetch by product id (per location)
 * @param  {MenuProductOptionGroup[]} newLocationProductOptionGroups - collection of option groups of the product that was fetch by global product id (per brand)
 */
const migrateOptions = (
  basketItemOptions: BasketItem["options"],
  currentLocationProductOptionGroups: MenuProductOptionGroup[],
  newLocationProductOptionGroups: MenuProductOptionGroup[]
) => {
  // Create a dictionary of IDs to better keep track of quantities
  const newOptionsDictionary = getOptionsDictionary(basketItemOptions);

  const selectedOptionIdsOnly = Object.keys(newOptionsDictionary);
  const globalOptions: MenuProductOption[] = [];
  const migratedOptions: MenuProductOption[] = [];

  // Recursive function to search through all the product options until all of the selected options are found.
  /** @TODO improve typing of `currentOptionGroups`. */
  const loopThroughOptionGroups = (
    currentLocationProductOptionGroups: MenuProductOptionGroup[]
  ) => {
    // Loop through the option groups
    each(currentLocationProductOptionGroups, (og) => {
      // Loop through the option group options
      each(og.options, (productOption) => {
        // Base Case for recursion
        // Stop if we've eliminated all the selected options
        if (selectedOptionIdsOnly.length === 0) {
          return false;
        }

        if (selectedOptionIdsOnly.includes(productOption.id)) {
          const containsSubOptions = !!productOption.option_groups.length;

          globalOptions.push({
            ...productOption,
            quantity: newOptionsDictionary[productOption.id].quantity,
          });

          // Remove the found option
          remove(
            selectedOptionIdsOnly,
            (selectedOptionId) => selectedOptionId === productOption.id
          );

          // If the selected option has sub options, search those
          if (containsSubOptions) {
            loopThroughOptionGroups(productOption.option_groups);
          }
        }
      });

      // Stop if we've eliminated all the selected options
      if (selectedOptionIdsOnly.length === 0) {
        return false;
      }
    });
  };

  // Recursive function to search through all the product options until all of the selected options are found.
  const loopThroughNewOptionGroups = (
    newLocationProductOptionGroups: MenuProductOptionGroup[]
  ) => {
    // Loop through the option groups
    each(newLocationProductOptionGroups, (og) => {
      if (og)
        // Loop through the option group options
        each(og.options, (globalProductOption) => {
          // Stop if we've eliminated all the selected options
          if (globalOptions.length === 0) {
            return false;
          }

          const foundGlobalOption = globalOptions.find(
            (opt) =>
              opt.global_option_id === globalProductOption.global_option_id
          );

          if (foundGlobalOption) {
            const containsSubOptions =
              !!globalProductOption.option_groups.length;

            migratedOptions.push({
              ...globalProductOption,
              quantity: foundGlobalOption.quantity,
            });

            // Remove the found option
            remove(
              globalOptions,
              (so) =>
                so.global_option_id === globalProductOption.global_option_id
            );
            // If the selected option has sub options, search those
            if (containsSubOptions) {
              loopThroughNewOptionGroups(globalProductOption.option_groups);
            }
          }
        });
      // Stop if we've eliminated all the selected options
      if (globalOptions.length === 0) {
        return false;
      }
    });
  };

  // Start the search for global options
  loopThroughOptionGroups(currentLocationProductOptionGroups);
  // Match the global options with the new options group.
  loopThroughNewOptionGroups(newLocationProductOptionGroups);

  return migratedOptions;
};

/**
 * Find a Option by option id given a option group
 *
 * @param optionId
 * @param optionGroups
 * @TODO improve typing of optionGroups.
 */
export const findOptionByOptionId = (
  optionId: string,
  optionGroups: MenuProductOptionGroup[]
) => {
  let optionGroupOption: MenuProductOption | undefined;

  //Search through option groups for the option
  for (const og of optionGroups) {
    if (optionGroupOption) {
      break;
    }

    optionGroupOption = og.options.find((o) => o.id === optionId);
  }

  return optionGroupOption;
};

export const migrateBasketItems = (
  basketItems: BasketItem[],
  menuCategories: Category[],
  newMenuCategories: Category[]
) => {
  const productsNotMatched: MenuProductWithCategory[] = [];
  const migratedBasketItems = basketItems
    .map((item) => {
      const migratedItem = { ...item };

      const currentLocationProduct = sdkUtils.findMenuItemById(
        migratedItem.product.id,
        menuCategories
      );

      // Logic:
      // 1. we get current location product from menu by product id that user put in basket
      // 2. if that product found -> we try to find same product by global id for future location
      // 3. once that product found in new location menu we try to migrate options and apply them to existing basket item
      // 4. else we push current location product to not matched products collection
      if (currentLocationProduct) {
        const newLocationProduct = findMenuItemByGlobalId(
          currentLocationProduct.global_id,
          newMenuCategories
        );

        if (newLocationProduct) {
          const migratedOptions = migrateOptions(
            item.options,
            currentLocationProduct.option_groups,
            newLocationProduct.option_groups
          );

          migratedItem.product.id = newLocationProduct.id;

          // TODO: remove that mapping when we sync Basket Item Option model with Product Option Model
          migratedItem.options = migratedOptions.map((productOption) => ({
            ...productOption,
            quantity: productOption.quantity ?? 0,
          }));

          return migratedItem;
        }
      } else {
        // This indicates that there is a mismatch between the current basket location and the localStorage menu
        fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
          name: ERROR_MESSAGES.BASKET_MENU_MISMATCH,
        });
      }

      // Not Found
      if (currentLocationProduct) {
        productsNotMatched.push(currentLocationProduct);
      }
    })
    .filter((item): item is BasketItem => item !== undefined);

  return {
    migratedBasketItems,
    productsNotMatched,
  };
};

export function patchProductOptions(basketItem: BasketItem) {
  return {
    ...basketItem,
    options: basketItem.options.map((option) => ({
      ...option,
      option_id: option.option_id ?? option.id,
    })),
  };
}

export const patchBasketProductOptions = (basketOrder: BasketOrder) => ({
  ...basketOrder,
  basket_items: basketOrder.basket_items.map(patchProductOptions),
});

export const basketCheckInvalidItems = (
  basketItems: BasketItem[],
  menuCategories: Category[]
) => {
  return basketItems.filter(
    (item) => !sdkUtils.findMenuItemById(item.product.id, menuCategories)
  );
};

// Method to remove cart items that can not be found in the menu categories.
// whoever calls this method should use it to update in localStorage
export const removeInvalidItemsFromCart = (
  basketItems: BasketItem[],
  menuCategories: Category[]
) => {
  const validItems: BasketItem[] = [];

  basketItems.forEach((item) => {
    const menuProduct = sdkUtils.findMenuItemById(
      item.product.id,
      menuCategories
    );
    if (menuProduct) {
      validItems.push(item);
    }
  });

  return validItems;
};

/*
 * Recreates a Customizer product with existingOptions passed in (for editing a product)
 */
export const recreateCustomizerProductFromMenuProduct = (
  product: MenuProduct,
  options: BasketItemOption[]
): MenuProduct => {
  // Recreate existingOptions object
  const existingOptions = prepareExistingOptionsObject(options);

  // Create new Customizer product with existingOptions
  const customize = new Customizer(product, existingOptions);
  return customize.getProduct();
};

/*
 * Creates `existingOptions` object to pass to Customizer
 *
 * It seems that this function create object with basket item option id and its quantity.
 * The problem function is trying to solve is that at some point options are passed whether as:
 * - array of string
 * - BasketItemOption without option_id and id matching id of menu option
 * - BasketItemOption with option_id that matches id of menu option and id that is unique and does not match id of menu option
 *
 * TODO: This function needs to be refactored.
 * We need to narrow down why and where Basket Item options are populated and set.
 * Why {options} can be passed as array of strings or array of BasketItemOption.
 * We need to make it consistent and end up using one model BasketItemOption where potentially option_id AND id
 * are optional parameters that are set on creating Basket Item option on basket.
 */
export const prepareExistingOptionsObject = (
  options: BasketItemOption[] | string[]
) => {
  const existingOptions: Record<string, { id: string; quantity: number }> = {};

  options.map((option: BasketItemOption | string) => {
    // TODO: Remove casting of option to any when option_id is added to BasketItemOption
    const optionId =
      typeof option === "string"
        ? option
        : (option as any)?.option_id
        ? (option as any)?.option_id
        : option.id;
    const optionQuantity = typeof option === "string" ? 1 : option.quantity;

    // Create dictionary of existing options and quantities
    existingOptions[optionId] = {
      id: optionId,
      quantity: optionQuantity,
    };
  });

  return existingOptions;
};

/* basketProducts is an array of all the selected products trimmed down to their options
 similar to the customization page
 just need to iterate over every branch of the product options to render
 */
export const basketProductsWithOptionPricing = (
  basketContent: BasketOrder,
  menuCategories: Category[]
) =>
  basketContent.basket_items.map((item) => {
    const menuProduct = sdkUtils.findMenuItemById(
      item.product.id,
      menuCategories
    );
    if (menuProduct) {
      const final = recreateCustomizerProductFromMenuProduct(
        menuProduct,
        item.options
      );
      return {
        item,
        final,
        menuProduct,
      };
    }
    return null;
  });

/**
 * Determines a basket's fulfillment type based on the priorities, in order of importance:
 *
 * 1. Whether or not the user is in a locked conveyance flow (should always take precedence)
 * 2. Whether or not an address is present
 *
 * @param address deliveryAddress
 * @returns CONVEYANCE_TYPES
 */
export const determineBasketFulfillment = (
  address?: DeliveryAddress
): CONVEYANCE_TYPES => {
  const sessionConveyanceType = getPersistentParameterValue(
    ALLOWED_URL_PARAMETER_KEYS.HANDOFF
  );

  if (
    sessionConveyanceType &&
    LOCKED_FLOW_CONVEYANCE_TYPES.includes(
      sessionConveyanceType as API_CONVEYANCE_TYPES
    )
  ) {
    // @ts-expect-error sessionConveyanceType can't index API_FOR_CONVEYANCE_TYPES_SWAP.
    return API_FOR_CONVEYANCE_TYPES_SWAP[sessionConveyanceType];
  }

  return address ? CONVEYANCE_TYPES.DELIVERY : CONVEYANCE_TYPES.PICKUP;
};

/**
 * Derive a comma-separated string of a list of basket items with basket item quantities
 *
 * @param basketItems BasketItem[]
 * @returns string (name and quantity of basket item)
 */
export const deriveBasketItemsList = (basketItems: BasketItem[]): string => {
  const formattedBasketItems = basketItems.map((basketItem) => {
    const name = basketItem.product.name;
    const quantity = basketItem.quantity > 1 ? ` x${basketItem.quantity}` : "";

    return `${name}${quantity}`;
  });

  return formattedBasketItems.join(", ");
};

/**
 * Derive a comma-separated string of a list of basket item modifiers
 *
 * @param basketItem BasketItem
 * @returns string (list of comma-separated basket items)
 */
export const deriveBasketModifiersList = (basketItem: BasketItem): string => {
  const modifiers: string[] = [];
  basketItem?.options.map(
    (option) => option?.name && modifiers.push(option.name)
  );
  return modifiers.join(", ");
};

export const getBasketItemsCountWithQuantity = (
  basketItems: BasketItem[]
): number => {
  let count = 0;

  basketItems.forEach((item) => {
    count += item.quantity;
  });

  return count;
};

/**
 * A local basket and a remote basket item are almost identical except a local item has no id.
 * This makes checking equality between a remote basket item and a local basket item very difficult.
 * So as a work-around we can compare the following
 * - the product ids match
 * - the option ids match (these are product options like toppings, exclusings, etc)
 * - the recipeint names match (defaults to an empty string)
 * - the special instructions match (defaults to an emptry string)
 *
 * Essentially, we are cross referencing every unique value possible since we don't have per basket unique ids
 *
 * THIS IS NOT A FULLY ACCURATE COMPARISON!
 * ONLY USE WHEN COMPARING LOCAL ITEMS WITH REMOTE ITEMS!
 */
export function checkEqualityBetweenLocalAndRemoteBasketItems(
  localItem: Omit<BasketItem, "id">,
  remoteItem: BasketItem
) {
  return (
    localItem.product.id === remoteItem.product.id &&
    localItem.recipient === remoteItem.recipient &&
    localItem.special_instructions === remoteItem.special_instructions &&
    localItem.options
      .map((o) => o.option_id ?? o.id)
      .sort()
      .join() ===
      remoteItem.options
        .map((o) => o.option_id)
        .sort()
        .join()
  );
}
