import { getMenu } from "@koala/sdk/v4";
import { call, put, takeLatest, type SagaReturnType } from "redux-saga/effects";
import basketActions from "../basket/actions";
import actions from "./actions";
import { ERROR_MESSAGES } from "@/constants/events";
import { DELIVERY_TIME_WANTED_MODES } from "@/constants/global";
import { createHttpClient } from "@/services/client";
import { getOrigin } from "@/utils";
import { prepareErrorMessage } from "@/utils/global";
import { fireKAnalyticsError } from "@/utils/koalaAnalytics";
import { getMenuFromStorage } from "@/utils/menu";

function* refreshBasketMenuSaga(
  action: ReturnType<typeof actions.refreshBasketMenu>
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    let wantedAt = undefined;

    if (
      action.wantedAt &&
      action.wantedAt !== DELIVERY_TIME_WANTED_MODES.ASAP
    ) {
      wantedAt = action.wantedAt;
    }

    const [response, compressedMenu]: SagaReturnType<typeof getMenu> =
      yield call(
        getMenu,
        {
          id: action.id,
          ifModifiedSince: undefined,
          compressed: true,
          wantedAt,
        },
        { client }
      );

    // @ts-expect-error
    yield put(actions.setBasketMenu(response, compressedMenu));

    // Ensure that the cart doesn't contain any items no longer in the menu
    yield put(basketActions.revalidateBasketItems(response));
  } catch (error) {
    yield put({ type: actions.FETCH_BASKET_MENU_ERROR });

    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, null, error);

    // KA event
    fireKAnalyticsError(
      ERROR_MESSAGES.FETCH_BASKET_MENU_ERROR,
      error,
      errorResponse
    );
  }
}

function* hydrateBasketMenuFromStorageSaga() {
  try {
    const menuFromStorage = getMenuFromStorage();
    yield put(actions.setBasketMenuFromStorage(menuFromStorage));
  } catch (error) {
    console.log("Error getting menu from storage");
    console.log(error);
  }
}

export default function* rootSaga() {
  yield takeLatest(
    actions.HYDRATE_BASKET_MENU,
    hydrateBasketMenuFromStorageSaga
  );

  yield takeLatest(actions.FETCH_BASKET_MENU, refreshBasketMenuSaga);
}
