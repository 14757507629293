const paymentHandlerActions = {
  RESET_CARD_DATA: "/koala/paymentHandler/RESET_CARD_DATA",
  TRIGGER_GET_PAYMENT: "/koala/paymentHandler/TRIGGER_GET_PAYMENT",
  START_SUBMIT_PAYMENT: "/koala/paymentHandler/START_SUBMIT_PAYMENT",
  START_SQUARE_SUBMIT_PAYMENT:
    "/koala/paymentHandler/START_SQUARE_SUBMIT_PAYMENT",

  resetCardData: () => ({
    type: paymentHandlerActions.RESET_CARD_DATA,
  }),
  triggerGetPayment: () => ({
    type: paymentHandlerActions.TRIGGER_GET_PAYMENT,
  }),
  startSubmitPayment: () => ({
    type: paymentHandlerActions.START_SUBMIT_PAYMENT,
  }),
  startSquareSubmitPayment: (nonce: string) => ({
    type: paymentHandlerActions.START_SQUARE_SUBMIT_PAYMENT,
    nonce,
  }),
};

export default paymentHandlerActions;
