import { getCrossSells } from "@koala/sdk/v4";
import { call, put, type SagaReturnType, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { DELIVERY_TIME_WANTED_MODES } from "@/constants/global";
import { createHttpClient } from "@/services/client";
import { getOrigin } from "@/utils";

function* fetchUpsellsSaga(action: ReturnType<typeof actions.fetchUpsells>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof getCrossSells> = yield call(
      getCrossSells,
      {
        ...action.params,
        wanted_at:
          action.params.wanted_at === DELIVERY_TIME_WANTED_MODES.ASAP
            ? undefined
            : action.params.wanted_at,
      },
      { client }
    );

    // Success
    yield put({ type: actions.UPSELLS_SUCCESS, detail: response.data });
  } catch (error) {
    yield put({ type: actions.UPSELLS_FAIL });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_UPSELLS, fetchUpsellsSaga);
}
