import {
  type IGlobalEvent,
  type IGlobalEventContext,
} from "@/analytics/events/constants";

export const pushGTagGenericEvent = (
  event: IGlobalEvent,
  context: IGlobalEventContext
) => {
  const eventName = event.eventName ?? context.name;

  if (eventName && typeof window?.gtag === "function") {
    window?.gtag("event", eventName, event);
  }
};
