import {
  CONVEYANCE_TYPES,
  type DeliveryAddress,
  type Location,
} from "@koala/sdk";
import { DELIVERY_TIME_WANTED_MODES } from "@/constants/global";
import { type IConveyanceModeState } from "@/types/conveyanceMode";
import * as ErrorReporter from "@/utils/errorReporter";

export const coerceAddressValidationPayload = (address: DeliveryAddress) => {
  // Concatenating the date and time
  const isASAP = address.time_wanted === DELIVERY_TIME_WANTED_MODES.ASAP;

  // Cocerce form values for submission to Olo
  const deliveryAddressPayload: DeliveryAddress = Object.assign({}, address, {
    time_wanted: isASAP ? null : address.time_wanted,
    time_wanted_mode: isASAP
      ? DELIVERY_TIME_WANTED_MODES.ASAP
      : DELIVERY_TIME_WANTED_MODES.ADVANCE,
  });

  return deliveryAddressPayload;
};

/**
 * Determine the current fulfillment mode (delivery versus pickup)
 *
 * @param {DeliveryAddress} deliveryAddress
 * @param {Location} locationDetail
 * @param {Location} basketLocation
 * @param {object} basketFulfillment
 *
 * @return {CONVEYANCE_TYPES}
 */
export const determineFulfillment = (
  deliveryAddress: IConveyanceModeState,
  locationDetail: Location,
  basketLocation: Location,
  basketFulfillment: { type: CONVEYANCE_TYPES }
): CONVEYANCE_TYPES => {
  // If location is delivery only, return delivery conveyance type and skip remaining logic
  if (locationDetail.is_delivery_only) {
    return CONVEYANCE_TYPES.DELIVERY;
  }

  /**
   * TODO:
   * After completion of 4569, the conveyance type should be available to us earlier
   * which will allow us room to refactor the below logic.
   */
  let activeOrderMode = CONVEYANCE_TYPES.PICKUP;

  // If a deliveryAddress exists in redux
  if (deliveryAddress?.address) {
    // Ensure store ID from the fulfillment modal matches the store we're on
    // If so, that is the activeOrderMode
    if (deliveryAddress.fulfillmentModalStoreId === locationDetail.id) {
      activeOrderMode = deliveryAddress.type;
    } else {
      // If the IDs don't match, log in error reporter
      ErrorReporter.captureMessage(
        "Store id in redux does not match current location id",
        "info",
        {
          deliveryAddress,
          storeId: locationDetail.id,
          basketLocationId: basketLocation.id,
        }
      );
    }
  }

  // If a basket exists in location storage, that should be the canonical activeOrderMode
  // Assuming the basketLocation matches the store we're on
  if (basketLocation.id === locationDetail.id && basketFulfillment) {
    activeOrderMode = basketFulfillment.type;
  }

  return activeOrderMode;
};

// Logic for whether to display the manual delivery form
export const showManualDeliveryFormConditions = (
  displayStoredAddresses: boolean,
  storedAddressSelected: boolean,
  formValues: Record<string, string>
): boolean => {
  // Stored addresses not enabled or no stored addresses exist
  // Could also mean user not logged in
  if (!displayStoredAddresses) {
    return true;
  }

  // User has selected a stored address
  if (displayStoredAddresses && storedAddressSelected) {
    return false;
  }

  // No stored address selected and empty form
  if (
    displayStoredAddresses &&
    !storedAddressSelected &&
    !formValues?.street_address
  ) {
    return false;
  }

  // User has entered a manual address OR started with a stored address and then edited it
  if (formValues?.street_address && !formValues?.id) {
    return true;
  }
  return false;
};
