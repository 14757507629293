type RegexOptions =
  | "ALPHANUMERIC"
  | "NAMES"
  | "MM_DD_YYYY_DASHES_SLASHES"
  | "ZIP_CODE_USA_AND_POSTAL_CODE_CANADA"
  | "EMAIL"
  | "PASSWORD"
  | "PHONE";

export const REGEX: { [key in RegexOptions]: RegExp } = {
  ALPHANUMERIC: /^[a-zA-Z0-9 ]*$/,
  NAMES: /^[a-zA-Z- ]*$/, // only letters, spaces, and dashes allowed
  MM_DD_YYYY_DASHES_SLASHES:
    /^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\d\d$/,
  ZIP_CODE_USA_AND_POSTAL_CODE_CANADA:
    /^((\d{5}-\d{4})|(\d{5})|([AaBbCcEeGgHhJjKkLlMmNnPpRrSsTtVvXxYy]\d[A-Za-z]\s?\d[A-Za-z]\d))$/, // from https://regexlib.com/REDetails.aspx?regexp_id=417
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  PHONE: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
};

export const REGEX_VALIDATION_STRINGS: { [key in RegexOptions]: string } = {
  ALPHANUMERIC: "Alphanumeric characters only",
  NAMES: "Only letters, spaces, and dashes allowed",
  MM_DD_YYYY_DASHES_SLASHES: "Must be in format MM/DD/YYYY or MM-DD-YYYY",
  ZIP_CODE_USA_AND_POSTAL_CODE_CANADA: "Invalid zip/postal code",
  EMAIL: "Invalid email address",
  PASSWORD:
    "Must include a number, an uppercase letter, a lowercase letter, and a special character",
  PHONE: "Invalid phone number",
};

export const VALIDATION_STRINGS = {
  REQUIRED: "Required",
  EMAIL: "Invalid email address",
  PHONE: "Invalid phone number",
  PASSWORD_LENGTH: "Password must be at least 8 characters long",
  PASSWORD_MISMATCH: "Passwords do not match",
};
