import { sdkUtils, type TagItem } from "@koala/sdk";
import { put, select, takeLatest, delay } from "redux-saga/effects";
import basketActions from "../basket/actions";
import { BASKET_ERROR } from "../basket/messages";
import globalActions from "../global/actions";
import actions from "./actions";
import { PRODUCT_LOCATION_LABELS } from "@/constants/checkout";
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from "@/constants/events";
import { ERROR_DISPLAY_DURATION } from "@/constants/global";
import { type RootState } from "@/types/app";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";

function* triggerToastOptionErrorsSaga() {
  try {
    yield put(actions.toggleToastOptionsError(true));
    // Hide toast message after 5 seconds
    yield delay(ERROR_DISPLAY_DURATION.FIVE);
    yield put(actions.toggleToastOptionsError(false));
  } catch (error) {
    console.log(error, "Error displaying toast options error");
  }
}

function* setCrossSellQuickAddSaga(
  action: ReturnType<typeof actions.setCrossSellQuickAdd>
) {
  try {
    // Set product in the Customizer, but don't open the PDP modal
    yield put(
      actions.setProduct({
        product: action.payload.product,
        menuCategories: action.payload.menuCategories,
        label: PRODUCT_LOCATION_LABELS.CROSS_SELL,
        upsellId: action.payload.upsellId,
        open: false,
      })
    );

    // Grab categories and current product details from Customizer
    const state: RootState = yield select();
    const { product, options, quantity, upsellId, canonicalProduct, final } =
      state.app.customize;
    /** @TODO investigate why surfaceable_option_groups is absent from `MenuProduct`. */
    // @ts-expect-error
    const { surfaceable_option_groups } = final;

    // Grab category ID and label for Koala Analytics
    const productCategory = sdkUtils.findProductCategory(
      /** @TODO ensure that `product` is non-nullable. */
      // @ts-expect-error
      product.id,
      action.payload.menuCategories
    );
    const productCategoryLabel = productCategory?.name;
    const productCategoryId = productCategory?.global_id;

    // Format basket product
    const basketProduct = {
      /** @TODO ensure that `product` is non-nullable. */
      product: {
        // @ts-expect-error
        id: product.id,
        // @ts-expect-error
        global_id: product.global_id,
        // @ts-expect-error
        name: product.name,
        cross_sell_id: upsellId || null,
        // @ts-expect-error
        cost: canonicalProduct.cost * quantity, // as per Koala Analytics specs
        category_id: productCategoryId,
        category_label: productCategoryLabel,
        filter_tags: product?.filter_tags?.map((tag: TagItem) => ({
          id: String(tag?.id),
          label: tag?.label,
        })),
      },
      options,
      quantity,
      // These fields are not supported for Quick Add - however, they can update this from the sidecart
      special_instructions: "",
      recipient: "",
    };

    // Check to see if there are any option groups that are invalid
    /** @TODO investigate why surfaceable_option_groups is absent from `MenuProduct`. */
    // @ts-expect-error
    if (!surfaceable_option_groups.every((optionGroup) => optionGroup.valid)) {
      console.log("in surfaceable", surfaceable_option_groups);
      // If so, open PDP to prompt user to make selections
      yield put(
        actions.setProduct({
          product: action.payload.product,
          menuCategories: action.payload.menuCategories,
          label: PRODUCT_LOCATION_LABELS.CROSS_SELL,
          upsellId: action.payload.upsellId,
          open: true,
        })
      );
      return;
    }

    // If not, add straight to the basket!
    yield put(
      basketActions.addItem(
        // @ts-expect-error
        basketProduct,
        PRODUCT_LOCATION_LABELS.CROSS_SELL,
        undefined
      )
    );
  } catch (error) {
    console.log({ error });
    yield put(
      globalActions.displayErrorToast(BASKET_ERROR.addItemErrorToastSaga)
    );

    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
      name: ERROR_MESSAGES.CROSS_SELL_QUICK_ADD_TOAST_ERROR,
    });
  }
}

export default function* rootSaga() {
  yield takeLatest(
    actions.TRIGGER_TOAST_OPTIONS_ERROR,
    triggerToastOptionErrorsSaga
  );
  yield takeLatest(actions.SET_CROSS_SELL_QUICK_ADD, setCrossSellQuickAddSaga);
}
