import { API_CONVEYANCE_TYPES } from "./events";

// TODO - deprecate
export const API_FOR_CONVEYANCE_TYPES_SWAP = {
  pick_up: "Pickup",
  delivery: "Delivery",
  curbside: "Curbside",
  dine_in: "Dine-In",
  drive_thru: "Drive-Thru",
  drive_in: "Drive-In",
} as const;

export const LOCKED_FLOW_CONVEYANCE_TYPES = [API_CONVEYANCE_TYPES.DINEIN];

export const DELIVERY_TIME_WANTED_MODES = {
  ASAP: "asap",
  ADVANCE: "advance",
} as const;

export type DeliveryModes = "asap" | "advance";

export const ALLERGEN_CARD_MODE = {
  DETAIL: "detail",
  MENU: "menu",
  OPTIONS: "options",
} as const;

export const ALLERGEN_TAG_LOCATIONS = {
  MENU_ITEM: "menu-item",
  ITEM_DETAIL: "item-detail",
  ITEM_OPTION_IMAGE: "item-option-image",
  ITEM_OPTION_NO_IMAGE: "item-option-no-image",
  CART: "cart",
};

export const ERROR_DISPLAY_DURATION = {
  FIVE: 5000,
};

export const TIMESLOT_TYPES = {
  BASKET_TIMES: "basketTimes",
  LOCATION_TIMES: "locationTimes",
};

export const MAX_LENGTH = {
  DELIVERY_INSTRUCTIONS: 128,
  ITEM_RECIPIENT: 32,
};

export enum GOOGLE_LIBRARIES {
  PLACES = "places",
}

export const ALLOWED_URL_PARAMETER_KEYS = {
  HANDOFF: "handoff",
  TABLE_NUMBER: "table_number",
};

export const ALLOWED_URL_PARAMETER_VALUES = {
  [ALLOWED_URL_PARAMETER_KEYS.HANDOFF]: ["dine_in"],
  [ALLOWED_URL_PARAMETER_KEYS.TABLE_NUMBER]: [],
};

export enum KOALA_SESSION_STORAGE_KEYS {
  METADATA = "koalaMetadata",
  PARAMETERS = "koalaRouteParameters",
}

/**
 * Next v12 changed the default cache policy for server-rendered pages to
 * `no-cache, no-store, max-age=0, must-revalidate`, effectively disabling
 * all server-side caching. Since web-ordering currently doesn't support
 * server-side auth, we can set a pretty aggressive cache policy.
 * @see https://nextjs.org/docs/going-to-production#caching
 */
export const DEFAULT_SSR_CACHE_POLICY = "public, max-age=86400";

export const SMB_DRIVER_ID = 7;
