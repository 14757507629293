import * as Sentry from "@sentry/nextjs";

/**
 * Dispatch a captured message to Sentry.
 */
export function captureMessage(
  message: string,
  level?: Sentry.SeverityLevel,
  extra = {}
) {
  if (typeof window !== "undefined") {
    console.warn(message, { level, extra });
    Sentry.captureMessage(message, { level, extra });
  }
}

/**
 * Dispatch a captured exception to Sentry.
 */
export function captureException(
  exception: any,
  level?: Sentry.SeverityLevel,
  extra = {}
) {
  if (typeof window !== "undefined") {
    console.error(exception);
    Sentry.captureException(exception, { level, extra });
  }
}

/** Tag the brand name in Sentry and set up any extra event listeners. */
export function initialize(brand: string) {
  // Apply the brand name tag to events.
  Sentry.setTag("brand", brand);

  /**
   * Listens for Google Maps authentication errors.
   * For some insane reason, Google doesn't provide additional context
   * to this error handler so all we know is that something failed.
   * @see https://developers.google.com/maps/documentation/javascript/events#auth-errors
   */
  window.gm_authFailure = function () {
    captureMessage("Google Maps Authentication Error!", "fatal");
  };
}
