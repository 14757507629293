export const ROUTES = {
  HOMEPAGE: "/",
  ANDROID: "/android",
  ACCOUNT: "/account",
  CHECKOUT: "/checkout",
  FIND_A_LOCATION: "/find-a-location",
  FORGOT_PASSWORD: "/forgot-password",
  ORDER_CONFIRMATION: "/order-confirmation/[[...id]]",
  ORDER_DETAIL: "/account/order-detail/[id]",
  SIGN_UP: "/sign-up",
  LOGIN: "/login",
  STORE: "/store/[...id]",
  PAGE: "/[...id]",
  LOCATIONS: "/locations",
  ARRIVAL_CONFIRMATION: "/arrival-confirmation/[id]",
  NOT_FOUND: "/not-found",
};

export enum LOYALTY_ROUTES {
  REWARDS = "/account/rewards",
  INBOX = "/account/inbox",
  FAVORITES = "/account/favorites",
  ORDER_HISTORY = "/account/order-history",
  ACCOUNT = "/account",
}

export enum API_ROUTES {
  KOALA_ANALYTICS = "/api/proxy/koalaAnalytics",
  PAYMENT_SUBMIT_RISK = "/api/proxy/payment/submitRiskData",
  PAYMENT_UPDATE_RISK = "/api/proxy/payment/updateRiskData",
}
