import { type AllergenItem } from "@koala/sdk";

const allergenActions = {
  FETCH_ALLERGENS: "/koala/meta/FETCH_ALLERGENS",
  TOGGLE_ALLERGEN: "/koala/meta/TOGGLE_ALLERGEN",
  UPDATE_ALLERGENS: "/koala/meta/UPDATE_ALLERGENS",
  ALLERGENS_SUCCESS: "/koala/meta/ALLERGENS_SUCCESS",
  ALLERGENS_FAIL: "/koala/meta/ALLERGENS_FAIL",
  fetchAllergens: () => ({
    type: allergenActions.FETCH_ALLERGENS,
  }),
  updateAllergens: (updatedAllergenIds: number[]) => ({
    type: allergenActions.UPDATE_ALLERGENS,
    updatedAllergens: updatedAllergenIds,
  }),
  toggleAllergen: (id: number) => ({
    type: allergenActions.TOGGLE_ALLERGEN,
    id,
  }),
  allergensSuccess: (detail: AllergenItem[]) => ({
    type: allergenActions.ALLERGENS_SUCCESS,
    detail,
  }),
  allergensFail: () => ({
    type: allergenActions.ALLERGENS_FAIL,
  }),
};

export default allergenActions;
