import { type UpsellsRequest } from "@koala/sdk";

export const upsellsActions = {
  FETCH_UPSELLS: "/koala/upsells/FETCH_UPSELLS",
  UPSELLS_SUCCESS: "/koala/upsells/UPSELLS_SUCCESS",
  UPSELLS_FAIL: "/koala/upsells/UPSELLS_FAIL",

  fetchUpsells: (params: UpsellsRequest) => ({
    params,
    type: upsellsActions.FETCH_UPSELLS,
  }),
};

export default upsellsActions;
