import {
  fallbackConfig,
  fallbackStrings,
  type WebConfig,
  type Strings,
} from "@koala/sdk";
import { type AnyAction } from "redux";
import actions from "./actions";

export interface ICMSConfigState {
  loading: boolean;
  fetchServer: boolean;
  fetchClient: boolean;
  strings: Strings;
  webConfig: WebConfig;
}

export const initialState: ICMSConfigState = {
  loading: false,
  fetchServer: false,
  fetchClient: false,
  strings: fallbackStrings,
  webConfig: fallbackConfig,
};

export const cmsConfig = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_CONFIGS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.FETCH_CONFIGS_SUCCESS:
      return Object.assign({}, state, {
        [action.clientOrServer]: true,
        [action.configType]: action.configContent,
        loading: false,
      });
    case actions.FETCH_CONFIGS_FAILURE:
      return Object.assign({}, state, {
        [action.clientOrServer]: false,
        loading: false,
      });
    default:
      return state;
  }
};
