import { CONVEYANCE_TYPES } from "@koala/sdk";
import { createSelector } from "@reduxjs/toolkit";
import { type AnyAction } from "redux";
import basketActions from "../basket/actions";
import orderStatusActions from "../orderStatus/actions";
import actions from "./actions";
import {
  API_FOR_CONVEYANCE_TYPES_SWAP,
  DELIVERY_TIME_WANTED_MODES,
} from "@/constants/global";
import { selectBasketSlice } from "@/redux/basket";
import { locationsSliceSelector } from "@/redux/locations/reducer";
import { orderDetailsSelector } from "@/redux/orderDetails/reducer";
import { type RootState } from "@/types/app";
import { type IBasketState } from "@/types/basket";
import { type IConveyanceModeState } from "@/types/conveyanceMode";

export const initialState: IConveyanceModeState = {
  type: CONVEYANCE_TYPES.PICKUP,
  handoffDetails: null,
  loading: false,
  address: null,
};

export const conveyanceMode = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.CHECK_DELIVERY_ADDRESS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.CHECK_DELIVERY_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.SET_DELIVERY_ADDRESS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.SET_DELIVERY_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        type: action.address
          ? CONVEYANCE_TYPES.DELIVERY
          : CONVEYANCE_TYPES.PICKUP,
        address: action.address || null,
        fulfillmentModalStoreId: action.fulfillmentModalStoreId,
        loading: false,
      });
    case actions.SET_WANTED_TIME:
      // time_wanted can be undefined, but delivery address assumes null or a string
      if (
        action.conveyanceType === CONVEYANCE_TYPES.DELIVERY &&
        action.wantedAt
      ) {
        return {
          ...state,
          address: {
            ...state.address,
            time_wanted: action.wantedAt,
          },
        };
      }

      return {
        ...state,
        time_wanted: action.wantedAt,
      };
    case actions.HANDOFF_TYPE_TOGGLE:
      return Object.assign({}, state, {
        type: action.conveyanceType,
        handoffDetails: null,
        address: null,
        loading: false,
      });
    case actions.HANDOFF_DETAILS_SET:
      return Object.assign({}, state, {
        type: action.conveyanceType,
        handoffDetails: action.handoffDetails || null,
      });
    case basketActions.REPLACE_BASKET:
      return Object.assign({}, state, {
        ...action.conveyanceType,
      });
    case orderStatusActions.INITIALIZE_ORDER:
    case basketActions.INIT_NEW_BASKET:
    case actions.SET_DELIVERY_ADDRESS_FAILURE:
    case actions.CHECK_DELIVERY_ADDRESS_FAILURE:
      return initialState;

    case actions.CLEAR_DELIVERY_ADDRESS:
      return Object.assign({}, state, {
        address: null,
        loading: false,
        type: null,
      });
    default:
      return state;
  }
};

export const conveyanceSliceSelector = (state: RootState) =>
  state.app.conveyanceMode;

function getTimeWanted(
  conveyance: IConveyanceModeState,
  basket: IBasketState,
  activeConveyance: CONVEYANCE_TYPES
) {
  // keeping time wanted in the address object for Deliveries at this time
  /** @TODO move delivery time wanted out of the address object */
  if (activeConveyance === CONVEYANCE_TYPES.DELIVERY) {
    if (conveyance.address?.time_wanted) {
      return conveyance.address.time_wanted;
    } else if (basket.fulfillment?.address?.time_wanted) {
      return basket.fulfillment.address.time_wanted;
    } else {
      return;
    }
  }

  if (conveyance.time_wanted) {
    return conveyance.time_wanted;
  } else if (basket.fulfillment?.time_wanted) {
    return basket.fulfillment.time_wanted;
  } else {
    return DELIVERY_TIME_WANTED_MODES.ASAP;
  }
}

export const selectConveyance = createSelector(
  conveyanceSliceSelector,
  orderDetailsSelector,
  locationsSliceSelector,
  selectBasketSlice,
  (conveyance, order, activeLocation, basket) => {
    const orderConveyance =
      API_FOR_CONVEYANCE_TYPES_SWAP[
        order.order_data.basket.conveyance_type?.type
      ];

    /**
     * @TODO:
     * this is the wrong way to handle this (temporary conveyance is a very annoying feature)
     * we should instantiate a basket whenever a basket intent happens and remove this slice:
     *
     * - delivery or pickup intent is specifically set by the guest
     * - time wanted is updated by the guest
     * - an initial item is added
     * */
    const activeConveyance = basket.fulfillment?.type ?? conveyance.type;

    return {
      basket: {
        basket,
        type: basket.fulfillment?.type ?? CONVEYANCE_TYPES.PICKUP,
      },
      type: activeConveyance,
      order: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        matchesCurrent: orderConveyance === activeConveyance,
        type: orderConveyance ?? CONVEYANCE_TYPES.PICKUP,
      },
      address: basket.fulfillment?.address ?? conveyance.address,
      time_wanted: getTimeWanted(conveyance, basket, activeConveyance),
      // if there's a basket location, use that for information
      // otherwise, default to the location slice, which already
      // includes some property defaults.
      location: basket?.location?.id
        ? basket?.location?.id
        : activeLocation?.detail?.id,
    };
  }
);
