import {
  type CheckoutBasket,
  CONVEYANCE_TYPES,
  PAYMENT_TYPES,
  type CustomerDetails,
  type LoyaltyUser,
  type PaymentDetails,
  type StoredValueRequirement,
  type SubmitBasketData,
} from "@koala/sdk";
import { getPersistentParameterValue } from "./global";
import { type IFrameChangeEvent } from "@/components/checkout/payment/pci-proxy/form/types";
import {
  InitializationErrorTypes,
  INITIALIZATION_ERRORS,
} from "@/constants/basket";
import { API_CONVEYANCE_TYPES } from "@/constants/events";
import {
  ALLOWED_URL_PARAMETER_KEYS,
  API_FOR_CONVEYANCE_TYPES_SWAP,
} from "@/constants/global";
import { type RootState } from "@/types/app";
import { type IUserInfo } from "@/types/checkout";
import {
  type IBasketFulfillment,
  type IConveyanceDeliveryAddress,
} from "@/types/fulfillment";
import { type IStoredValue } from "@/types/paymentInfo";
import { toDollars } from "@/utils/basket";
import { invalidPhone } from "@/utils/validation";

export const isGuestOrUser = (
  guestUser: IUserInfo | null,
  me: LoyaltyUser | null
) => !!guestUser || !!me?.id;

export const getIdsFromState = (state: RootState) => {
  const locationId = state.app.basket.checkoutBasket?.location?.id;
  const basketId = state.app.basket.checkoutBasket?.id;

  return {
    basketId,
    locationId,
  };
};

export const getLocationFromState = (state: RootState) => {
  return state.app.basket?.location;
};

export const normalizeExpiry = (value: string, previousValue: string) => {
  if (value) {
    let expires = value.replace(/\D/g, "").substr(0, 4);
    const length = value.length;
    // Allow the user to type in the /
    if (length === 3 && value.slice(2) === "/") {
      return value;
    }
    // User can delete the /
    if (previousValue && length === 2 && previousValue.slice(2) === "/") {
      return expires;
    }
    // Or just add / for them
    if (expires.length > 1) {
      expires = expires.substr(0, 2) + "/" + expires.substr(2);
    }
    // return a single digit
    return expires;
  }

  return "";
};

export const formatExpiry = (expiry: string) => {
  const expParts = expiry ? expiry.split("/") : [];

  return {
    expiry_month: parseInt(expParts[0], 0),
    expiry_year: parseInt(`20${expParts[1]}`, 0),
  };
};

/** @TODO refactor this component to remove recursion. */
const capturePhone = (reconciledPhone: string | null): any => {
  const validationMessage =
    reconciledPhone !== null ? "\n10 digits without spaces or dashes." : "";
  reconciledPhone = prompt(
    `Whoops, we need a phone number in order to check out! ${validationMessage}`,
    reconciledPhone || ""
  );
  if (reconciledPhone) {
    reconciledPhone = reconciledPhone.replace(/[^0-9.]/g, "");
  }
  if (reconciledPhone !== null && invalidPhone(reconciledPhone)) {
    return capturePhone(reconciledPhone);
  }
  return reconciledPhone;
};

export const assemblePayment = (
  payment: PaymentDetails | null,
  storedValue: IStoredValue | null,
  customerDetails: CustomerDetails,
  checkoutBasket: CheckoutBasket
): PaymentDetails[] => {
  if (!payment && !storedValue) {
    throw new Error("Oops, please ensure you've added a payment method.");
  }

  const assembledPayment: PaymentDetails[] = [];
  const storedValueBalance = storedValue?.balance ?? 0;
  const { paymentMethodAmount, storedValueAmount } = assignPaymentAmounts(
    checkoutBasket.total,
    storedValueBalance
  );
  const { paymentMethodTip, storedValueTip } = assignPaymentTips(
    checkoutBasket.tip,
    storedValue?.balance
  );

  // If a positive stored value has been applied to an order
  if (storedValue && storedValueAmount > 0) {
    const storedValuePayload = getStoredValuePaymentType(
      storedValue,
      storedValueAmount,
      storedValueTip,
      customerDetails
    );

    // Another check to ensure the gift card covers the order total
    // in cases where split payment is disabled
    if (
      !checkoutBasket.location.supports_split_payment &&
      storedValueAmount < checkoutBasket.total
    ) {
      throw new Error(
        `Gift Card balance $${toDollars(
          storedValueAmount
        )} does not cover order total. Please try another gift card or a different payment method.`
      );
    }

    // Yet another check to ensure the gift card covers the order total
    // in cases where split payment is ENABLED
    // if the total is greater than the gift card balance and we haven't applied any other payment methods
    if (
      checkoutBasket.location.supports_split_payment &&
      storedValueAmount < checkoutBasket.total &&
      !payment?.type
    ) {
      throw new Error(
        `Gift card balance $${toDollars(
          storedValueAmount
        )} does not cover order total. Please cover the remaining balance with another payment method to proceed.`
      );
    }

    assembledPayment.push(storedValuePayload);
  }

  // If there is no remaining balance on the order,
  // do not attempt to assemble the payment method object
  if (paymentMethodAmount === 0) {
    return assembledPayment;
  }

  // This case will only be true if split-payment is disabled
  // and gift card is an option in the payment toggle
  if (
    payment?.type === PAYMENT_TYPES.STORED_VALUE &&
    !storedValue?.card_number
  ) {
    throw new Error("Review your gift card or payment method to proceed.");
  }

  if (!payment) {
    throw new Error("Please ensure you've added a payment method.");
  }

  const paymentMethod: PaymentDetails = {
    ...payment,
    amount: paymentMethodAmount,
    tip: paymentMethodTip,
    customer: customerDetails,
  };

  // Push primary payment method
  assembledPayment.push(paymentMethod);

  return assembledPayment;
};

const getStoredValuePaymentType = (
  storedValue: IStoredValue,
  storedValueAmount: number,
  storedValueTip: number,
  customerDetails: CustomerDetails
): PaymentDetails => {
  // Assemble stored value reqs
  const storedValueRequirements: StoredValueRequirement[] = [
    {
      name: "number",
      value: storedValue?.card_number,
    },
  ];

  // If we have a pin, push that into the payload
  if (storedValue?.pin) {
    storedValueRequirements.push({
      name: "pin",
      value: storedValue?.pin,
    });
  }

  return {
    type: PAYMENT_TYPES.STORED_VALUE,
    amount: storedValueAmount,
    tip: storedValueTip,
    stored_value_requirements: storedValueRequirements,
    customer: customerDetails,
  };
};

const getUserDataFromState = (state: RootState) => {
  const me = state.app.me.data;
  const guestUser = state.app.auth.guestUser;
  return guestUser ?? (me?.id ? me : null);
};

export const assembleDeliveryObjectFromState = (
  state: RootState,
  instructions?: string,
  staleId?: number
): IConveyanceDeliveryAddress | null => {
  const userData = getUserDataFromState(state);
  const basketFulfillment: IBasketFulfillment = state.app.basket?.fulfillment;

  if (!basketFulfillment) {
    return null;
  }

  /** @TODO reconcile the IConveyanceDeliveryAddress type. */
  const addressObj: IConveyanceDeliveryAddress = {
    id: basketFulfillment.address?.id ?? undefined,
    stale_id: staleId || basketFulfillment.address?.stale_id,
    // @ts-expect-error
    day_wanted: basketFulfillment.address?.day_wanted,
    // @ts-expect-error
    time_wanted: basketFulfillment.address?.time_wanted,
    // @ts-expect-error
    city: basketFulfillment.address?.city,
    // @ts-expect-error
    state: basketFulfillment.address?.state,
    // @ts-expect-error
    street_address: basketFulfillment.address?.street_address,
    street_address_2: basketFulfillment.address?.street_address_2 || null,
    // @ts-expect-error
    zip_code: basketFulfillment.address?.zip_code,
    // @ts-expect-error
    phone_number: userData?.phone,
    special_instructions:
      instructions || basketFulfillment.address?.special_instructions || "",
    default: basketFulfillment.address?.default,
  };

  if (addressObj.stale_id) {
    delete addressObj.id;
  }

  return addressObj;
};

export const assembleOrderDataFromState = (
  state: RootState
): SubmitBasketData => {
  const conveyanceModeState = state.app.conveyanceMode;
  const paymentTypeState = state.app.payment.paymentType;
  const storedValueState = state.app.payment.storedValue;
  const checkoutBasket = state.app.basket.checkoutBasket;
  const userData: LoyaltyUser | IUserInfo | null = getUserDataFromState(state);

  // Don't allow basket to be submitted without a phone number
  let reconciledPhone = "";
  if (invalidPhone(userData?.phone)) {
    reconciledPhone = capturePhone(userData?.phone ? userData.phone : null);
    if (!reconciledPhone) {
      throw new Error("Oops, please provide your phone number.");
    }
  }

  // Don't allow basket submission if we are in a locked dine in conveyance with no table number
  // AND location supports_dine_in_table_number
  if (
    getPersistentParameterValue(ALLOWED_URL_PARAMETER_KEYS.HANDOFF) ===
      API_CONVEYANCE_TYPES.DINEIN &&
    !checkoutBasket?.conveyance_type?.table_tent_number &&
    checkoutBasket?.location.supports_dine_in_table_number
  ) {
    throw new Error(
      "Oops, please ensure you've entered your table number for dine in."
    );
  }

  // Don't allow basket submission if there are conveyance mismatches
  if (
    conveyanceModeState?.type !==
    // @ts-expect-error `conveyance_type.type` can't index `API_FOR_CONVEYANCE_TYPES_SWAP`.
    API_FOR_CONVEYANCE_TYPES_SWAP[checkoutBasket?.conveyance_type?.type]
  ) {
    if (conveyanceModeState?.type === CONVEYANCE_TYPES.CURBSIDE) {
      throw new Error(
        "Oops, please ensure you've submitted your vehicle details for curbside pickup."
      );
    }

    throw new Error(
      "Oops, there was an error setting your handoff mode. Please refresh this page and try again."
    );
  }

  /** @TODO reconcile the CustomerDetails type. */
  const customerDetails: CustomerDetails = {
    // @ts-expect-error
    first_name: userData?.first_name,
    // @ts-expect-error
    last_name: userData?.last_name,
    // @ts-expect-error
    email: userData?.email,
    contact_number: userData?.phone || reconciledPhone,
  };
  const orderData = {
    payment: assemblePayment(
      paymentTypeState,
      storedValueState,
      customerDetails,
      checkoutBasket
    ),
    customer: {
      ...customerDetails,
      opt_in: userData?.opt_in,
      email_receipt: false, // TODO make config if necessary in the future
    },
    allergies: [],
  };

  return orderData;
};

export const assignPaymentAmounts = (
  checkoutTotal: number,
  storedValueBalance: number
) => {
  // Payment type covers the current checkout total by default
  let paymentMethodAmount = checkoutTotal;
  let storedValueAmount = 0;
  let storedValueRemaining = 0;

  // If we have a gift card
  if (storedValueBalance > 0) {
    const checkoutTotalSansGiftCard = checkoutTotal - storedValueBalance;
    // If there is remaining checkout total after subtracting the gift card,
    // - calculate the remaining payment type amount
    // - assign the storedValueBalance to the storedValueAmount for checkout
    if (checkoutTotalSansGiftCard > 0) {
      paymentMethodAmount = checkoutTotalSansGiftCard;
      storedValueAmount = storedValueBalance;
    }

    // If the gift card balance exceeds the cost of checkout
    // - there is no amount obligation on the payment type
    // - set the gift card remaining and the gift card amount
    if (checkoutTotalSansGiftCard <= 0) {
      paymentMethodAmount = 0;
      storedValueAmount = checkoutTotal;
      storedValueRemaining = storedValueBalance - checkoutTotal;
    }
  }

  return {
    paymentMethodAmount,
    storedValueAmount,
    storedValueRemaining,
  };
};

export const assignPaymentTips = (
  tipAmount: number,
  storedValueBalance?: number
) => {
  // Default tip assignments to 0
  let paymentMethodTip = 0;
  let storedValueTip = 0;

  // If stored value exists assign the tip to the stored value
  if (storedValueBalance) {
    storedValueTip = tipAmount;

    // If the stored value balance cannot cover the tip, assign the remander to the payment method
    if (storedValueBalance < tipAmount) {
      storedValueTip = storedValueBalance;
      paymentMethodTip = tipAmount - storedValueBalance;
    }
  }

  return {
    paymentMethodTip,
    storedValueTip,
  };
};

/**
 * Given a basket initialization error determine
 * the means by which it should be handled
 */
export const getInitializationErrorType = (message?: string) => {
  const initErrorKey = Object.keys(INITIALIZATION_ERRORS).find((key) =>
    message?.includes(key)
  );
  const initErrorType = initErrorKey
    ? INITIALIZATION_ERRORS[initErrorKey]
    : InitializationErrorTypes.UNHANDLED;

  return initErrorType;
};

/**
 * Process a PCI Proxy change event and extract some non-sensitive card information
 * mainly the card type and the expiration date
 * see: https://docs.pci-proxy.com/collect-and-store-cards/capture-iframes/events
 *
 * @param data PCI Proxy iFrame change event payload
 * @param current state of payment data (mainly expiry)
 * @returns updated state
 */
export const handlePciProxyIFrameChangeEvent = (
  data: IFrameChangeEvent,
  { expiry }: { expiry: string }
) => {
  // currently we only handle the autocomplete event and only for
  if (data.event.type === "autocomplete") {
    // the expire date is stored as a short date (e.g. 12/25)
    // so splitting at the slash gives month and year
    const date = (expiry || "/").split("/");
    const month = date[0]?.trim() || "";
    const year = date[1]?.trim() || "";

    switch (data.event.field) {
      case "expiryMonth":
        expiry = `${data.event.value}/${year}`;
        break;
      case "expiryYear":
        // grab only the last two digits for the year
        expiry = `${month}/${data.event.value?.slice(-2)}`;
        break;
      default:
        break;
    }
  }

  const cardType = data.fields.cardNumber.paymentMethod;

  return {
    cardType,
    expiry,
  };
};
