import { sdkStorage } from "@koala/sdk";
import {
  checkDeliveryCoverage,
  setConveyance,
  CONVEYANCE_TYPES,
} from "@koala/sdk/v4";
import Router from "next/router";
import {
  call,
  put,
  race,
  select,
  take,
  takeLatest,
  type SagaReturnType,
} from "redux-saga/effects";
import actions from "./actions";
import { fireGACommerceAddShippingInfoEvent } from "@/analytics/commerce/google";
import {
  API_CONVEYANCE_TYPES,
  ERROR_MESSAGES,
  K_ANALYTICS_EVENTS,
} from "@/constants/events";
import { API_FOR_CONVEYANCE_TYPES_SWAP } from "@/constants/global";
import { ROUTES } from "@/constants/routes";
import { getMappedConveyanceType } from "@/features/handoff/time-picker/utils";
import basketActions from "@/redux/basket/actions";
import globalActions from "@/redux/global/actions";
import orderStatusActions from "@/redux/orderStatus/actions";
import { createHttpClient } from "@/services/client";
import { type RootState } from "@/types/app";
import { getOrigin } from "@/utils";
import { userLoggedIn } from "@/utils/auth";
import {
  assembleDeliveryObjectFromState,
  getIdsFromState,
} from "@/utils/checkout";
import { coerceAddressValidationPayload } from "@/utils/fulfillment";
import { prepareErrorMessage } from "@/utils/global";
import {
  fireKAnalyticsError,
  fireKAnalyticsEvent,
} from "@/utils/koalaAnalytics";
import { getLocationId } from "@/utils/locations";

function* checkDeliveryAddressSaga(
  action: ReturnType<typeof actions.checkDeliveryAddress>
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const coercedAddress = coerceAddressValidationPayload(action.address);
    const response: SagaReturnType<typeof checkDeliveryCoverage> = yield call(
      checkDeliveryCoverage,
      action.location.id,
      coercedAddress,
      { client }
    );
    // If valid
    if (response?.data.can_deliver) {
      yield put(
        actions.setDeliveryAddress(
          action.address,
          action.location,
          action.revalidate,
          action.shouldAutoRedirect
        )
      );
      yield put({ type: globalActions.DELIVERY_ADDRESS_VALID });
    } else {
      // If not valid
      yield put({
        type: globalActions.DELIVERY_ADDRESS_INVALID,
        errorMessage: response?.data.message,
      });

      // KA event
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
        name: ERROR_MESSAGES.CHECK_DELIVERY_COVERAGE_ERROR,
        details: response?.data.message,
      });

      yield put({ type: actions.CHECK_DELIVERY_ADDRESS_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.CHECK_DELIVERY_ADDRESS_FAILURE });
    yield put(
      globalActions.displayErrorToast(
        "There was an error validating your delivery address. Please try again in a few minutes.",
        true
      )
    );

    // KA event
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, null, error);
    fireKAnalyticsError(
      ERROR_MESSAGES.VALIDATE_DELIVERY_ADDRESS_ERROR,
      error,
      errorResponse
    );
  }
}

function* setDeliveryAddressSaga(
  action: ReturnType<typeof actions.setDeliveryAddress>
) {
  const state: RootState = yield select();
  const { basketId } = getIdsFromState(state);
  const storedAddressesEnabled =
    state.app.cmsConfig.webConfig.accounts.stored_addresses;

  // If stored addresses are enabled in the web config AND user is logged in
  const useAddressId = storedAddressesEnabled && userLoggedIn() ? true : false;
  const addressObject = Object.assign({}, action.address);
  try {
    const koalaOrderJSON = sdkStorage.basket.get();

    if (koalaOrderJSON && koalaOrderJSON.location.id === action.location.id) {
      // Delete address id and stale_id from payload if not relevant for stored addresses
      if (!useAddressId) {
        /** @TODO unravel type issues with the `id` and `stale_id`. */
        delete addressObject.id;
        delete addressObject.stale_id;
      }

      // Reset conveyance on existing baskets
      yield put(
        basketActions.updateBasketFulfillment({
          address: addressObject,
          type: CONVEYANCE_TYPES.DELIVERY,
        })
      );

      if (basketId) {
        const currentDeliveryInstructions = addressObject.special_instructions;

        yield put(
          orderStatusActions.conveyanceDeliverySet(
            currentDeliveryInstructions,
            addressObject.stale_id
          )
        );
      }
      // If basket exists AND the basket is the same location as the new fulfillment, re-update the basket
      yield put(
        basketActions.updateBasketFulfillment({
          address: addressObject,
          type: CONVEYANCE_TYPES.DELIVERY,
        })
      );
    } else {
      // Else, update redux
      yield put(
        // @ts-expect-error
        actions.setDeliveryAddressSuccess(addressObject, action.location.id)
      );

      const state: RootState = yield select();
      if (state.app.delivery?.deliveryType?.type) {
        fireGACommerceAddShippingInfoEvent(
          state.app.basket,
          state.app.menu.basketMenu,
          state.app.promoCode.applied,
          state.app.delivery?.deliveryType?.type
        );
      }
    }

    if (action.shouldAutoRedirect) {
      // Automatically redirect the user to the correct store page.
      yield Router.push(
        ROUTES.STORE,
        `/store/${getLocationId(
          action.location,
          state.app.cmsConfig.webConfig
        )}/${action.location.label}`
      );
    } else {
      // Wait for the user to either click the Continue button or close the modal.
      const { save } = yield race({
        save: take(actions.SET_DELIVERY_ADDRESS_CONTINUE),
        cancel: take(globalActions.TOGGLE_FULFILLMENT_MODAL),
      });

      // If they click continue, redirect them to the correct store page.
      if (save) {
        // Fire KA Event
        const isDeliveryAddressStored = !!action.address.id;
        const eventDetails: string = isDeliveryAddressStored
          ? "Stored address"
          : "Non-stored address";
        fireKAnalyticsEvent(K_ANALYTICS_EVENTS.DELIVERY_ADDRESS_VALIDATED, {
          name: action.location?.label,
          details: eventDetails,
        });
        yield Router.push(
          ROUTES.STORE,
          `/store/${getLocationId(
            action.location,
            state.app.cmsConfig.webConfig
          )}/${action.location.label}`
        );
      }
    }
    if (action.revalidate) {
      yield put({ type: globalActions.DELIVERY_ADDRESS_VALID });
    }
  } catch (error) {
    yield put({ type: actions.SET_DELIVERY_ADDRESS_FAILURE });

    // If error results from a stored address, record the address ID
    const state: RootState = yield select();
    const deliveryAddress = assembleDeliveryObjectFromState(state);
    let additionalDetails;
    if (deliveryAddress?.id) {
      additionalDetails = `Stored Address ID: ${deliveryAddress.id}`;
    }

    // KA event
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, null, error);
    fireKAnalyticsError(
      ERROR_MESSAGES.SET_DELIVERY_ADDRESS_ERROR,
      error,
      errorResponse,
      additionalDetails
    );
  }
}

function* setWantedTimeSaga(action: ReturnType<typeof actions.setWantedTime>) {
  const state: RootState = yield select();
  const fulfillment = state.app.basket.fulfillment;

  try {
    const koalaOrderJSON = sdkStorage.basket.get();

    if (koalaOrderJSON && koalaOrderJSON.location.id === action.locationId) {
      if (
        action.conveyanceType === CONVEYANCE_TYPES.DELIVERY &&
        action.wantedAt
      ) {
        const address = fulfillment.address ?? {};

        yield put(
          basketActions.updateBasketFulfillment({
            ...fulfillment,
            type: action.conveyanceType,
            // @ts-expect-error: address will be an object, but typescript doesn't know that
            address: {
              ...address,
              time_wanted: action.wantedAt,
            },
          })
        );
      } else {
        yield put(
          basketActions.updateBasketFulfillment({
            ...fulfillment,
            type: action.conveyanceType,
            time_wanted: action.wantedAt,
          })
        );
      }
    }
  } catch (error) {
    console.warn(error);

    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, null, error);

    fireKAnalyticsError(
      ERROR_MESSAGES.SET_WANTED_TIME_ERROR,
      error,
      errorResponse
    );
  }
}

function* clearDeliveryAddressSaga(
  action: ReturnType<typeof actions.clearDeliveryAddress>
) {
  try {
    const koalaOrderJSON = sdkStorage.basket.get();

    if (koalaOrderJSON && koalaOrderJSON.location.id === action.locationId) {
      // If basket exists, ensure this is also reset
      yield put({
        type: basketActions.UPDATE_BASKET_FULFILLMENT,
        fulfillment: {
          address: null,
          type: CONVEYANCE_TYPES.PICKUP,
          // @ts-expect-error: guarded time_wanted, and it can be undefined
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          time_wanted: koalaOrderJSON.fulfillment?.address?.time_wanted,
        },
      });
    }
  } catch (error) {
    console.log("Error clearing delivery address: ", error);
  }
}

export function* conveyanceSetSaga(
  action: ReturnType<typeof actions.conveyanceModeSet>
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const { basketId, locationId: basketLocationId } = yield select(
      getIdsFromState
    );

    // @ts-expect-error: sagas have terrible typing
    const locationId = yield select((state) => state.app.locations.detail.id);

    // We set conveyance on back-end only if we have basket created
    // Basket should be create when user navigates to checkout and signs in
    if (basketId && basketLocationId) {
      const basketResponse: SagaReturnType<typeof setConveyance> = yield call(
        // @ts-expect-error ensure that `action.conveyanceDetails` is defined.
        setConveyance,
        {
          basketId,
          locationId: basketLocationId,
          type: action.conveyanceType,
          data: action.conveyanceDetails,
        },
        { client }
      );
      yield put(basketActions.success(basketResponse));
      yield put(orderStatusActions.orderPending());
    }

    // Update handoff details
    // TODO! - this is bad
    yield put(
      actions.handoffDetailsSet(
        API_FOR_CONVEYANCE_TYPES_SWAP[action.conveyanceType],
        // @ts-expect-error
        action.conveyanceDetails
      )
    );

    // Ensure the local basket wanted at time is valid
    yield put(
      actions.setWantedTime(
        action.wantedAt,
        // we called `setConveyanceMode` in a lot of places, some need to explicitly pass a location id
        // due to the context of where they are displayed (ex: menu availability in the header)
        action.locationId ?? locationId,
        getMappedConveyanceType(action.conveyanceType)
      )
    );

    // @IMPLEMENTATION-TODO:
    // remove in favor of hooks in `<FeatureAccessor />` after Next upgrade
    yield put(globalActions.generateFeatureBag());
  } catch (error) {
    yield put(
      actions.handleConveyanceFailure(error as Error, action.conveyanceType)
    );
  }
}

function* conveyanceDeliverySetSaga(
  action: ReturnType<typeof orderStatusActions.conveyanceDeliverySet>
) {
  const client = createHttpClient({
    origin: getOrigin(window.location.host),
  });

  try {
    const state: RootState = yield select();
    const { basketId, locationId } = getIdsFromState(state);

    const deliveryAddress = assembleDeliveryObjectFromState(
      state,
      action.instructions,
      action.staleId
    );

    // TODO update types
    let basketResponse: SagaReturnType<typeof setConveyance> = yield call(
      // @ts-expect-error ensure that `deliveryAddress` is defined.
      setConveyance,
      {
        basketId,
        locationId,
        type: API_CONVEYANCE_TYPES.DELIVERY,
        data: deliveryAddress,
      },
      { client }
    );

    // Handle editing conveyance
    if (deliveryAddress?.stale_id) {
      // Create a new delivery address using the ID from the basket response
      const newDeliveryAddress = Object.assign({}, deliveryAddress, {
        id: basketResponse?.conveyance_type?.delivery_address?.id,
      });

      // Now delete the stale ID
      delete newDeliveryAddress.stale_id;

      // Trigger yet another basket response with the NEW address ID
      basketResponse = yield call(
        // @ts-expect-error
        setConveyance,
        {
          basketId,
          locationId,
          type: API_CONVEYANCE_TYPES.DELIVERY,
          data: newDeliveryAddress,
        },
        { client }
      );

      // Take the latest basket response and format the data to update basket fulfillment with
      const basketDeliveryAddress =
        basketResponse?.conveyance_type?.delivery_address;
      const newBasketDeliveryAddress = Object.assign(
        {},
        basketDeliveryAddress,
        {
          zip_code: basketResponse?.conveyance_type?.delivery_address?.zip_code,
        }
      );

      // Update basket fulfillment with the new address
      yield put({
        type: basketActions.UPDATE_BASKET_FULFILLMENT,
        fulfillment: {
          address: newBasketDeliveryAddress,
          type: CONVEYANCE_TYPES.DELIVERY,
        },
      });
    }
    yield put(basketActions.success(basketResponse));
    yield put(orderStatusActions.orderPending());

    // Update handoff details
    yield put(
      actions.handoffDetailsSet(CONVEYANCE_TYPES.DELIVERY, {
        instructions: action.instructions ?? "",
      })
    );
  } catch (error) {
    yield put(
      actions.handleConveyanceFailure(error as Error, CONVEYANCE_TYPES.DELIVERY)
    );
  }
}

function* handleConveyanceFailureSaga(
  action: ReturnType<typeof actions.handleConveyanceFailure>
) {
  // Place order in pending state
  yield put(orderStatusActions.orderPending());

  try {
    // Assemble error message
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, "", action.error);
    let errorMessage = errorResponse.message;

    // Curbside setting fails
    if (action.conveyanceType === API_CONVEYANCE_TYPES.CURBSIDE) {
      errorMessage = `Oops. There was an issue setting your curbside details. ${errorResponse.message}`;
    }

    // Show error
    yield put(orderStatusActions.orderStatusMessageErrorSet(errorMessage));

    // If error results from a stored address, record the address ID
    const state: RootState = yield select();
    const deliveryAddress = assembleDeliveryObjectFromState(state);
    let additionalDetails = "";
    if (deliveryAddress?.id) {
      additionalDetails = `Stored Address ID: ${deliveryAddress.id}`;
    }

    // KA error event
    fireKAnalyticsError(
      ERROR_MESSAGES.SET_CONVEYANCE_ERROR,
      action.error,
      errorResponse,
      additionalDetails
    );
  } catch (error) {
    // KA error event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
      name: ERROR_MESSAGES.SET_CONVEYANCE_ERROR_ERROR,
      details: (error as Error).message,
    });
  }
}

function* clearLockedConveyanceModeSaga() {
  /**
   * In order to clear a locked in conveyance mode, we need to do several things:
   *
   * 1. Clear out persistent route parameters otherwise they will keep informing the
   *    feature flagging system to keep certain features locked down
   * 2. Reset conveyanceMode to pickup
   * 3. Switch the checkout basket fulfillment to pickup
   * 4. Update the basket fulfillment to pickup in Redux as well as localStorage
   * 5. re-generate the feature bag
   * 6. Finally, clear the route parameters from the URL
   */
  yield put(globalActions.clearPersistentParameters());
  yield take([globalActions.CLEAR_PERSISTENT_PARAMETERS_SUCCESS]);

  yield put(actions.toggleHandoff(CONVEYANCE_TYPES.PICKUP));
  yield put(orderStatusActions.conveyanceSet());
  yield put(
    actions.conveyanceModeSet({
      type: API_CONVEYANCE_TYPES.PICKUP,
      details: [],
    })
  );
  yield put(
    basketActions.updateBasketFulfillment({
      type: CONVEYANCE_TYPES.PICKUP,
    })
  );
  yield put(globalActions.generateFeatureBag());

  // Clear URL parameters
  const state: RootState = yield select();

  if (!Object.keys(state.app.global.routerStatus.persistentParameters).length) {
    void Router.push(ROUTES.CHECKOUT);
  }
}

function* setDeliveryAddressSuccessSaga() {
  const state: RootState = yield select();

  if (state.app.delivery?.deliveryType?.type) {
    fireGACommerceAddShippingInfoEvent(
      state.app.basket,
      state.app.menu.basketMenu,
      state.app.promoCode.applied,
      state.app.delivery?.deliveryType?.type
    );
  }
}

// @IMPLEMENTATION-TODO:
// remove in favor of hooks in `<FeatureAccessor />` after Next upgrade
function* handoffTypeToggleSaga() {
  yield put(globalActions.generateFeatureBag());
}

export default function* rootSaga() {
  yield takeLatest(actions.CHECK_DELIVERY_ADDRESS, checkDeliveryAddressSaga);
  yield takeLatest(actions.SET_DELIVERY_ADDRESS, setDeliveryAddressSaga);
  yield takeLatest(actions.CLEAR_DELIVERY_ADDRESS, clearDeliveryAddressSaga);
  yield takeLatest(actions.SET_WANTED_TIME, setWantedTimeSaga);
  yield takeLatest(
    actions.HANDLE_CONVEYANCE_FAILURE,
    handleConveyanceFailureSaga
  );
  yield takeLatest(
    actions.RESET_LOCKED_CONVEYANCE_MODE,
    clearLockedConveyanceModeSaga
  );
  yield takeLatest(actions.HANDOFF_TYPE_TOGGLE, handoffTypeToggleSaga);
  yield takeLatest(actions.CONVEYANCE_MODE_SET, conveyanceSetSaga);
  yield takeLatest(
    orderStatusActions.CONVEYANCE_DELIVERY_SET,
    conveyanceDeliverySetSaga
  );
  yield takeLatest(
    actions.SET_DELIVERY_ADDRESS_SUCCESS,
    setDeliveryAddressSuccessSaga
  );
}
