import {
  updateArrivalStatus,
  ARRIVAL_STATUS,
  getOrderSummary,
} from "@koala/sdk/v4";
import Router from "next/router";
import { call, put, type SagaReturnType, takeLatest } from "redux-saga/effects";
import globalActions from "../global/actions";
import { default as actions, default as arrivalStatusActions } from "./actions";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents } from "@/analytics/events/constants";
import { ERROR_MESSAGES } from "@/constants/events";
import { ROUTES } from "@/constants/routes";
import { createHttpClient } from "@/services/client";
import { getOrigin } from "@/utils";
import { prepareErrorMessage } from "@/utils/global";
import { fireKAnalyticsError } from "@/utils/koalaAnalytics";

export function* fetchArrivalStatusSaga(
  action: ReturnType<typeof actions.fetchArrivalStatus>
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof getOrderSummary> = yield call(
      getOrderSummary,
      action.orderId,
      { client }
    );

    yield put(
      arrivalStatusActions.fetchArrivalStatusSuccess(response, action.label)
    );
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(
        prepareErrorMessage,
        "There was an error checking the status of your order. Please try again.",
        error
      );

    // This order is not allowed to be updated, return to homepage and display only the toast error
    if (errorResponse?.error?.error === "access_denied") {
      void Router.push(ROUTES.HOMEPAGE);
      yield put(arrivalStatusActions.fetchArrivalStatusError(false));
    } else {
      yield put(arrivalStatusActions.fetchArrivalStatusError(true));
    }

    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    fireKAnalyticsError(
      ERROR_MESSAGES.ARRIVAL_FETCH_ERROR,
      error,
      errorResponse
    );
  }
}

export function* updateArrivalStatusSaga(
  action: ReturnType<typeof actions.updateArrivalStatus>
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    yield call(
      updateArrivalStatus,
      { orderId: action.orderId, externalOrder: action.externalOrder },
      { client }
    );

    yield put(
      arrivalStatusActions.updateArrivalStatusSuccess(
        { arrival_status: ARRIVAL_STATUS.ARRIVED },
        action.label
      )
    );

    genericEventHandler(GlobalEvents.ARRIVAL__CONFIRM_ARRIVAL_SUCCESS, {
      name: action.label,
      details: action.orderId,
    });
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(
        prepareErrorMessage,
        "There was an error notifying the restaurant. Please try again.",
        error
      );

    // Customer already claimed arrival. Treat as successful status notification
    if (
      errorResponse.error?.error_description ===
      "Customer already claimed arrival for this Order"
    ) {
      yield put(
        arrivalStatusActions.updateArrivalStatusSuccess(
          { arrival_status: ARRIVAL_STATUS.ARRIVED },
          action.label
        )
      );
    } else {
      yield put(arrivalStatusActions.updateArrivalStatusFail());
      yield put(globalActions.displayErrorToast(errorResponse.message, true));
    }

    fireKAnalyticsError(
      ERROR_MESSAGES.ARRIVAL_CONFIRM_ERROR,
      error,
      errorResponse
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ARRIVAL_STATUS, fetchArrivalStatusSaga);
  yield takeLatest(actions.UPDATE_ARRIVAL_STATUS, updateArrivalStatusSaga);
}
