import { type TokenConfig, getOrgConfig } from "@koala/sdk/v4";
import { type QueryClient, useQuery } from "@tanstack/react-query";
import { queryKeys } from "./queries";
import { createHttpClient } from "@/services/client";
import { getOrigin, __DANGEROUS_EMPTY_REQUEST_ORIGIN } from "@/utils";

// fetch the organization, which is needed so we can pass the org id into the config request
// this may be cached depending on if its the first or nth time this request is being made
export function useOrganization() {
  /**
   * Make sure that `useFooterQuery` is prefetched before
   * this component renders, otherwise it will explode!
   * @see __DANGEROUS_EMPTY_REQUEST_ORIGIN
   */
  let origin: string;
  if (typeof window !== "undefined") {
    origin = getOrigin(window.location.host);
  } else {
    origin = getOrigin(__DANGEROUS_EMPTY_REQUEST_ORIGIN);
  }

  return useQuery({
    queryKey: [queryKeys.organization],
    queryFn: () =>
      getOrgConfig({
        client: createHttpClient({ origin }),
      }),
  });
}

// fetch the organization, which is needed so we can pass the org id into the config request
// this may be cached depending on if its the first or nth time this request is being made
export async function getOrganization(
  queryClient: QueryClient,
  origin: string
): Promise<TokenConfig> {
  return await queryClient.fetchQuery(
    [queryKeys.organization],
    () =>
      getOrgConfig({
        client: createHttpClient({ origin }),
      }),
    { staleTime: 60000 } // set a 1 hour stale time to prevent duplicate network calls
  );
}
