const timeslotActions = {
  FETCH_HANDOFF_TIMES_BY_BASKET:
    "/koala/timeslots/FETCH_HANDOFF_TIMES_BY_BASKET",
  FETCH_HANDOFF_TIMES_BY_LOCATION:
    "/koala/timeslots/FETCH_HANDOFF_TIMES_BY_LOCATION",
  FETCH_HANDOFF_TIMES_SUCCESS: "/koala/timeslots/FETCH_HANDOFF_TIMES_SUCCESS",
  FETCH_HANDOFF_TIMES_FAILURE: "/koala/timeslots/FETCH_HANDOFF_TIMES_FAILURE",
  CLEAR_HANDOFF_TIMES: "/koala/timeslots/CLEAR_HANDOFF_TIMES",
  SET_WANTED_AT: "/koala/timeslots/SET_WANTED_AT",

  fetchHandoffTimesByLocation: (
    locationId: number,
    hoursType: string,
    includeAsap: boolean,
    wantedAtDay?: string
  ) => ({
    type: timeslotActions.FETCH_HANDOFF_TIMES_BY_LOCATION,
    locationId,
    hoursType,
    includeAsap,
    wantedAtDay,
  }),
  fetchHandoffTimesByBasket: (
    basketId: string,
    locationId: number,
    hoursType: string,
    includeAsap: boolean,
    wantedAtDay: string,
    previousAction?: string,
    wantedAt?: string
  ) => ({
    type: timeslotActions.FETCH_HANDOFF_TIMES_BY_BASKET,
    basketId,
    locationId,
    hoursType,
    includeAsap,
    wantedAtDay,
    previousAction,
    wantedAt,
  }),
  fetchHandoffTimesSuccess: (
    timeslotType: string,
    timeslots: any[],
    wantedAtDay?: string
  ) => ({
    type: timeslotActions.FETCH_HANDOFF_TIMES_SUCCESS,
    timeslotType,
    timeslots,
    wantedAtDay,
  }),
  setWantedAt: (wantedAt: string | null, error?: boolean) => ({
    type: timeslotActions.SET_WANTED_AT,
    wantedAt,
    error,
  }),
  clearHandoffTimes: () => ({
    type: timeslotActions.CLEAR_HANDOFF_TIMES,
  }),
};

export default timeslotActions;
