import { type AnyAction } from "redux";
import orderStatusActions from "../orderStatus/actions";
import actions from "./actions";
import { type IPromoCodeState } from "@/types/promoCode";

export const initialState: IPromoCodeState = {
  applied: "",
  error: "",
};

export const promoCode = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.PROMO_CODE_SET:
      return {
        ...state,
        applied: action.promoCode,
        error: initialState.error,
      };
    case actions.PROMO_CODE_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    case orderStatusActions.PROMO_CODE_ADD:
      return {
        ...state,
        error: action.message,
      };
    default:
      return state;
  }
};
