import { all } from "redux-saga/effects";
import allergenSagas from "./allergens/saga";
import arrivalSagas from "./arrivalStatus/saga";
import authSagas from "./auth/saga";
import basketSagas from "./basket/saga";
import basketRewardsSagas from "./basketRewards/saga";
import configSaga from "./cmsConfig/saga";
import commerceSagas from "./commerce/saga";
import conveyanceModeSagas from "./conveyanceMode/saga";
import customizeSagas from "./customize/saga";
import deliveryStatusSagas from "./deliveryStatus/saga";
import globalSagas from "./global/saga";
import locationsSagas from "./locations/saga";
import meSagas from "./me/saga";
import menuSagas from "./menu/saga";
import orderDetailsSagas from "./orderDetails/saga";
import orderStatusSagas from "./orderStatus/saga";
import organizationSagas from "./organization/saga";
import paymentSagas from "./payment/saga";
import paymentTypesSagas from "./paymentTypes/saga";
import promoCode from "./promoCode/saga";
import tagsSagas from "./tags/saga";
import timeslotsSagas from "./timeslots/saga";
import upsellsSagas from "./upsells/saga";

export default function* rootSaga() {
  yield all([
    arrivalSagas(),
    authSagas(),
    basketSagas(),
    basketRewardsSagas(),
    commerceSagas(),
    configSaga(),
    globalSagas(),
    locationsSagas(),
    meSagas(),
    menuSagas(),
    allergenSagas(),
    paymentTypesSagas(),
    paymentSagas(),
    promoCode(),
    upsellsSagas(),
    orderStatusSagas(),
    organizationSagas(),
    conveyanceModeSagas(),
    timeslotsSagas(),
    tagsSagas(),
    customizeSagas(),
    deliveryStatusSagas(),
    orderDetailsSagas(),
  ]);
}
