// Integrations
import { type GlobalEvents, type IGlobalEventContext } from "./constants";
import { BranchAnalytics } from "@/analytics/integrations/branch";
import { FacebookAnalytics } from "@/analytics/integrations/facebook";
import { GoogleAnalytics } from "@/analytics/integrations/google";
import { GenericKoalaAnalyticsWrapper } from "@/utils/koalaAnalytics";

export const pageViewEventHandler = (page?: string) => {
  const path = page || window.location.pathname;

  // Google
  GoogleAnalytics.pageView(path);

  // Branch
  BranchAnalytics.pageView();

  // Facebook
  FacebookAnalytics.pageView();

  // Koala Analytics
  GenericKoalaAnalyticsWrapper.pageView(path);

  // React Native
  if (typeof window !== "undefined" && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(path);
  }
};

export const genericEventHandler = (
  eventName: GlobalEvents,
  eventContext: IGlobalEventContext = {}
) => {
  // Google
  GoogleAnalytics.genericEvent(eventName, eventContext);

  // Branch
  BranchAnalytics.genericEvent(eventName, eventContext);

  // Koala Analytics
  GenericKoalaAnalyticsWrapper.genericEvent(eventName, eventContext);
};
