import {
  fallbackConfig,
  type WebConfig,
  type OrgTokenConfig,
} from "@koala/sdk";
import Head from "next/head";
import { useRouter } from "next/router";
import { ENV } from "@/constants/envConfig";
import { SMB_DRIVER_ID } from "@/constants/global";
import { getCanonicalUrl } from "@/utils/global";

interface Props {
  config: WebConfig;
  organization: OrgTokenConfig;
}

export function MetaSite({ config, organization }: Props) {
  const router = useRouter();
  const alternateDataLayerName = "ga4DataLayer";
  const driverId = organization.ordering_driver_id;

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Set canonical urls */}
      {ENV === "production" && (
        <>
          <link
            rel="canonical"
            href={getCanonicalUrl(config.seo.base_url, router)}
          />
          <meta
            property="og:url"
            key="og:url"
            content={getCanonicalUrl(config.seo.base_url, router)}
          />
        </>
      )}

      {/* Facebook Pixel */}
      {config.integrations.facebook_pixel && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
                  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${config.integrations.facebook_pixel});
                `,
          }}
        />
      )}

      {/* Global SEO Config */}
      <meta
        name="og:site_name"
        content={config.seo.site_name}
        key="og:site_name"
      />
      <link
        rel="icon"
        type="image/png"
        // @ts-expect-error: `fallbackConfig.global.favicon` is a valid string, but the return type conforms to WebConfiguration which marks the favicon as potentially null
        href={config.global.favicon ?? fallbackConfig.global.favicon}
      />

      {/* iPhone Smart Banner */}
      {config.integrations.apple_itunes_app_id && (
        <meta
          name="apple-itunes-app"
          content={`app-id=${config.integrations.apple_itunes_app_id}`}
          key="apple-itunes-app"
        />
      )}

      {/* Webmaster tools */}
      {config.google.site_verification && (
        <meta
          name="google-site-verification"
          content={config.google.site_verification}
          key="google-site-verification"
        />
      )}

      {/* Google Universal Analytics - UA */}
      {config.google.analytics_id && (
        <>
          <script async src="/static/scripts/autotrack.js" />
          <script
            dangerouslySetInnerHTML={{
              __html: `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', '${
          config.google.analytics_id
        }', {'siteSpeedSampleRate': 100});
        ${config.google.ecommerce && `ga('t0.require', 'ec');`}
        ga('require', 'maxScrollTracker', {
          maxScrollMetricIndex: 1,
        });
      `,
            }}
          />
        </>
      )}

      {/* Google Analytics 4 - GA4/gtag */}
      {/* Use a separate dataLayer function to segregate events sent through gtag from the GTM Data Layer */}
      {config.google.gtag_id && (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.google.gtag_id}&l=${alternateDataLayerName}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
        window.${alternateDataLayerName} = window.${alternateDataLayerName} || [];
        function gtag(){${alternateDataLayerName}.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${config.google.gtag_id}');
      `,
            }}
          />
        </>
      )}

      {/* Google Tag Manager */}
      {config.google.tag_manager_id && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${config.google.tag_manager_id}');
  `,
            }}
          />
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${config.google.tag_manager_id}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            />
          </noscript>
        </>
      )}

      {/* Userway */}
      {config.integrations.userway_id && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `var _userway_config = { account: "${config.integrations.userway_id}" };`,
            }}
          />
          <script
            type="text/javascript"
            src="https://cdn.userway.org/widget.js"
          ></script>
        </>
      )}

      {/* Branch */}
      {config.integrations.branch_id && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
                (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
                  branch.init('${config.integrations.branch_id}');
                `,
          }}
        />
      )}

      {/* Heap */}
      {ENV === "production" && driverId === SMB_DRIVER_ID && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
              heap.load("3745098455");
            `,
          }}
        />
      )}

      {ENV === "sandbox" && driverId === SMB_DRIVER_ID && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load("3177339425");
          `,
          }}
        />
      )}
    </Head>
  );
}
