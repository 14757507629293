import { type AnyAction } from "redux";
import actions from "./actions";
import { type IUpsellsState } from "@/types/upsells";

export const initialState: IUpsellsState = {
  error: false,
  detail: null,
  loading: false,
  fetchTimestamp: null,
};

export const upsells = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_UPSELLS:
      return Object.assign({}, state, {
        error: false,
        loading: true,
      });
    case actions.UPSELLS_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        loading: false,
        fetchTimestamp: new Date(),
        detail: action.detail,
      });
    case actions.UPSELLS_FAIL:
      return Object.assign({}, state, {
        error: true,
        loading: false,
      });
    default:
      return state;
  }
};
