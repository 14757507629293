import { setCookie, deleteCookie, getCookie } from "cookies-next";

export function setSelectedPopularItem(id: string) {
  setCookie("selected-popular-item", id, { maxAge: 300 }); // expires after 5 minutes
}

export function getSelectedPopularItem() {
  return getCookie("selected-popular-item");
}

export function removeSelectedPopularItem() {
  deleteCookie("selected-popular-item");
}
