import { type RewardItem } from "@koala/sdk";

const basketRewardsActions = {
  GET_REWARDS: "/koala/basketRewards/GET_REWARDs",
  ADD_REWARD: "/koala/basketRewards/ADD_REWARD",
  REMOVE_REWARD: "/koala/basketRewards/REMOVE_REWARD",
  SET_AVAILABLE_REWARDS: "/koala/basketRewards/SET_AVAILABLE_REWARDS",
  SET_APPLIED_REWARDS: "/koala/basketRewards/SET_APPLIED_REWARDS",
  SET_PENDING_REWARD: "/koala/basketRewards/SET_PENDING_REWARD",
  SET_UNAVAILABLE_REWARD: "/koala/basketRewards/SET_UNAVAILABLE_REWARD",
  RESET_BASKET_REWARDS: "/koala/basketRewards/RESET_BASKET_REWARDS",
  RESET_UNAVAILABLE_REWARDS: "koala/basketRewards/RESET_UNAVAILABLE_REWARDS",

  getRewards: () => ({
    type: basketRewardsActions.GET_REWARDS,
  }),
  setAvailableRewards: (rewardsAvailable: RewardItem[]) => ({
    type: basketRewardsActions.SET_AVAILABLE_REWARDS,
    rewardsAvailable,
  }),
  setAppliedRewards: (rewardsApplied: RewardItem[]) => ({
    type: basketRewardsActions.SET_APPLIED_REWARDS,
    rewardsApplied,
  }),
  addReward: (reward: RewardItem) => ({
    type: basketRewardsActions.ADD_REWARD,
    reward,
  }),
  setPendingReward: (id: string | null) => ({
    type: basketRewardsActions.SET_PENDING_REWARD,
    id,
  }),
  removeReward: (reward: RewardItem) => ({
    type: basketRewardsActions.REMOVE_REWARD,
    reward,
  }),
  setUnavailableReward: (reward: RewardItem) => ({
    type: basketRewardsActions.SET_UNAVAILABLE_REWARD,
    reward,
  }),
  resetUnavailableRewards: () => ({
    type: basketRewardsActions.RESET_UNAVAILABLE_REWARDS,
  }),
  resetBasketRewards: () => ({
    type: basketRewardsActions.RESET_BASKET_REWARDS,
  }),
};

export default basketRewardsActions;
