import { type AnyAction } from "redux";
import { type IArrivalStatusState } from "../../types/arrivalStatus";
import actions from "./actions";

export const initialState: IArrivalStatusState = {
  loading: false,
  data: null,
  error: false,
  orderDetails: null,
};

export const arrivalStatus = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_ARRIVAL_STATUS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.FETCH_ARRIVAL_STATUS_SUCCESS:
      return Object.assign({}, state, {
        orderDetails: action.orderDetails,
        loading: false,
      });
    case actions.FETCH_ARRIVAL_STATUS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: false,
      });
    case actions.UPDATE_ARRIVAL_STATUS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.UPDATE_ARRIVAL_STATUS_SUCCESS:
      return Object.assign({}, state, {
        data: action.arrivalResponse,
        error: false,
        loading: false,
      });
    case actions.UPDATE_ARRIVAL_STATUS_FAILURE:
      return Object.assign({}, state, {
        error: true,
        loading: false,
      });
    case actions.CLEAR_ARRIVAL_STATUS:
      return initialState;
    default:
      return state;
  }
};
