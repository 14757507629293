import {
  type BasketItem,
  type BasketOrder,
  type Location,
  type OrderDetails,
} from "@koala/sdk";
import {
  type IGlobalEventContext,
  type IGlobalEvents,
  type GlobalEvents,
} from "@/analytics/events/constants";
import { toDollars } from "@/utils/basket";

// Generic Events
const BranchEvents: IGlobalEvents = {
  HEADER__VIEW_BASKET: {
    eventName: "VIEW_CART",
  },
};

/**
 * Branch
 */
const hasBranch = () => typeof window.branch !== "undefined";
export class BranchAnalytics {
  /**
   * Page View Event
   */
  static pageView() {
    if (!hasBranch()) {
      return;
    }

    window.branch.track("pageview");
  }

  /**
   * Generic Events
   */
  static genericEvent(globalEvent: GlobalEvents, context: IGlobalEventContext) {
    if (!hasBranch()) {
      return;
    }

    if (!BranchEvents[globalEvent]) {
      return;
    }

    /** @TODO ensure that the event name is defined. */
    // @ts-ignore
    window.branch.logEvent(BranchEvents[globalEvent].eventName, context);
  }

  /**
   * Commerce Events
   */
  static addProduct(item: BasketItem, location: Location) {
    if (!hasBranch()) {
      return;
    }

    window.branch.logEvent("ADD_TO_CART", {
      productName: item.product.name,
      location: location.label,
    });
  }

  static paymentSet() {
    if (!hasBranch()) {
      return;
    }

    window.branch.logEvent("ADD_PAYMENT_INFO");
  }

  static purchase(orderDetails: OrderDetails, state: any) {
    if (!hasBranch()) {
      return;
    }

    const { location }: { content: BasketOrder; location: Location } =
      state.app.basket;
    const coupon = state.app.promoCode.applied;

    /** @TODO ensure that `promo_discount` is defined. */
    window.branch.logEvent("PURCHASE", {
      id: orderDetails.order_data.order.order_number,
      affiliation: `Store: ${location.location_id} - ${location.label}`,
      revenue: toDollars(
        orderDetails.order_data.order.total +
          // @ts-ignore
          orderDetails.order_data.order.promo_discount
      ),
      tax: toDollars(orderDetails.order_data.order.sales_tax),
      coupon,
    });
  }
}
