import {
  fallbackConfig,
  fallbackStrings,
  type Location,
  type Strings,
  type WebConfig,
} from "@koala/sdk";
import { encode } from "html-entities";
import find from "lodash/find";
import get from "lodash/get";
import { supportsBrandId } from "./config";
import { CTA } from "@/constants/cta";

/**
 * Interpolate variables from a data object into a string
 * assuming the text to swap within the string are wrapped
 * in the $ character and the inner text is a key that
 * exists within the data object
 *
 * @param {string} string
 * @param {data} object
 *
 * @returns {string}
 * @TODO determine if `string` could actually be null in practice.
 * @TODO rework this function to not mutate the input value.
 */
export const interpolateIntoString = (
  string?: string | null,
  data?: object,
  disableDataObjEncode?: boolean
) => {
  if (!string) {
    return "";
  }

  // Interpolate values from a resource passed in - i.e. $label$ or $location_id$ from locationDetail
  const substring = string.match(/\$.*?\$/g);

  if (substring && data) {
    substring.map((entry: string) => {
      // find the variable between the two dollar signs
      const key = entry.slice(1, -1);
      // optionally encode HTML special characters
      /** @TODO improve the typing of the data object. */
      // @ts-expect-error
      const targetValue = disableDataObjEncode ? data[key] : encode(data[key]);
      // find the associated key on the object if it exists
      /** @TODO improve the typing of the data object. */
      // @ts-expect-error
      if (data[key]) {
        // replace $var$ with the value from the data
        // @ts-expect-error
        string = string.replace(entry, targetValue);
      }
    });
  }

  return string;
};

// Interpolate variables into strings
export const safelyGetString = (
  webStrings: Strings,
  accessor: string,
  dataObj?: object,
  disableDataObjEncode?: boolean
) => {
  const strings = webStrings || fallbackStrings;

  // Default to returning an empty string.
  const str = get(strings, accessor, "") || "";

  return interpolateIntoString(str, dataObj, disableDataObjEncode);
};

// Get color, image, icon strings from web config
export const safelyGetConfig = (webConfig: WebConfig, accessor: string) => {
  let config = webConfig;

  // If the web config has failed to load, the fallback should come from the defaults
  if (!webConfig) {
    config = fallbackConfig;
  }

  return get(config, accessor, "");
};

// Interpolate location CTA Value
export const interpolateLocationCtaValue = (
  value: string,
  location: Location,
  config: WebConfig
) => {
  let newValue: string | object = value;
  // @ts-expect-error brand_id or id will always exist on location.
  const locationId: string | number = supportsBrandId(config)
    ? location.brand_id
    : location.id;

  if (value) {
    switch (value.trim()) {
      case CTA.PICKUP_URL:
        newValue = `/store/${locationId}/${location.label}`;
        break;
      default:
        break;
    }
  }

  return { finalValue: newValue };
};

export const reconcileCustomAttributeWithGlobalValue = (
  attributes: { value: any; label: any }[],
  positionId: number,
  globalValue: string,
  globalLabel: string
) => {
  const displayAttributes = {
    label: globalLabel,
    value: globalValue,
  };

  // Attempt to find location attribute
  const relevantAttribute: any = find(attributes, {
    position: { id: positionId },
  });

  if (relevantAttribute) {
    // Override global attribute values if the value is not an empty string
    if (relevantAttribute.value !== "") {
      displayAttributes.value = relevantAttribute.value;
    }
    if (relevantAttribute.label !== "") {
      displayAttributes.label = relevantAttribute.label;
    }
  }

  return displayAttributes;
};

// Remove special characters and capitalize a string
export const toTitleCase = (str?: string): string => {
  if (!str) {
    return "";
  }

  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

/**
 * Check if string ends in an 's' before adding another 's'. Accepts an optional condition to pluralize against.
 *
 * @param str string
 * @param condition boolean
 * @returns string
 */
export const pluralizeString = (str?: string, condition?: boolean): string => {
  if (!str) {
    return "";
  }

  if (str.endsWith("s") || condition === false) {
    return str;
  }

  return str + "s";
};

export const capitalizeFirstLetter = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
