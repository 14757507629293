import { addPromo, removePromo } from "@koala/sdk/v4";
import {
  call,
  put,
  select,
  takeLatest,
  type SagaReturnType,
} from "redux-saga/effects";
import basketActions from "../basket/actions";
import orderStatusActions from "../orderStatus/actions";
import promoCodeActions from "./actions";
import { fireGACommerceSelectPromotionEvent } from "@/analytics/commerce/google";
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from "@/constants/events";
import globalActions from "@/redux/global/actions";
import { createHttpClient } from "@/services/client";
import { type RootState } from "@/types/app";
import { getOrigin } from "@/utils";
import { getIdsFromState } from "@/utils/checkout";
import { prepareErrorMessage } from "@/utils/global";
import { fireGaEvent, gaActions, gaCats } from "@/utils/googleAnalytics";
import {
  fireKAnalyticsError,
  fireKAnalyticsEvent,
} from "@/utils/koalaAnalytics";

/**
 * Add promo code
 *
 */
function* promoCodeAddSaga(
  action: ReturnType<typeof orderStatusActions.promoCodeAdd>
) {
  try {
    const state: RootState = yield select();
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const { basketId, locationId } = getIdsFromState(state);
    const response: SagaReturnType<typeof addPromo> = yield call(
      addPromo,
      { basketId, locationId, promoCode: action.promoCode },
      { client }
    );

    yield put(basketActions.success(response));
    yield put(orderStatusActions.orderPending());
    yield put(promoCodeActions.promoCodeSet(action.promoCode));

    // Silently get validate basket
    yield put(orderStatusActions.validateOrder(response.id, locationId));

    // Events
    fireGaEvent(gaCats.order, gaActions.couponApplied, {
      label: action.promoCode,
    });
    fireGACommerceSelectPromotionEvent(
      state.app.basket,
      state.app.menu.basketMenu,
      action.promoCode
    );
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.PROMO_CODE_APPLIED);
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, null, error);

    yield put(globalActions.displayErrorToast(errorResponse.message, true));
    yield put(orderStatusActions.orderPending());

    // Events
    fireGaEvent(gaCats.order, gaActions.couponDenied, {
      label: action.promoCode,
    });
    fireKAnalyticsError(ERROR_MESSAGES.ADD_PROMO_FAILURE, error, errorResponse);
  }
}

/**
 * Remove promo code
 *
 */
function* promoCodeRemoveSaga(
  action: ReturnType<typeof orderStatusActions.promoCodeRemove>
) {
  try {
    const state: RootState = yield select();
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const { basketId, locationId } = getIdsFromState(state);
    const promoCode = state.app.promoCode.applied;

    if (promoCode) {
      const response: SagaReturnType<typeof removePromo> = yield call(
        removePromo,
        { basketId, promoCode, locationId },
        { client }
      );

      yield put(basketActions.success(response));
      yield put(orderStatusActions.orderPending());
      yield put(promoCodeActions.promoCodeSet(""));

      // Silently get validate basket
      yield put(
        orderStatusActions.validateOrder(response.id, response.location?.id)
      );

      // Events
      fireGaEvent(gaCats.order, gaActions.couponRemoved, {
        // @ts-expect-error
        label: action.promoCode,
      });
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.PROMO_CODE_REMOVED);
    } else {
      // Not sure how we'd end up here but should probably log it
      yield put(orderStatusActions.orderPending());
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
        name: ERROR_MESSAGES.CANNOT_FIND_PROMO_TO_REMOVE,
      });
    }
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, null, error);

    // yield put(globalActions.displayErrorToast(errorResponse.message, true));
    yield put(promoCodeActions.promoCodeError(errorResponse.message));
    yield put(orderStatusActions.orderPending());

    // Events
    fireGaEvent(gaCats.order, gaActions.couponRemovalFailed, {
      // @ts-expect-error
      label: action.promoCode,
    });
    fireKAnalyticsError(
      ERROR_MESSAGES.REMOVE_PROMO_FAILURE,
      error,
      errorResponse
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(orderStatusActions.PROMO_CODE_ADD, promoCodeAddSaga);
  yield takeLatest(orderStatusActions.PROMO_CODE_REMOVE, promoCodeRemoveSaga);
}
