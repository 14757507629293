import { type AnyAction } from "redux";
import actions from "./actions";
import { type ITagsState } from "@/types/tags";

export const initialState: ITagsState = {
  activeTagIds: null,
};

export const tags = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_ACTIVE_TAG:
      return Object.assign({}, state, {
        activeTagIds: [action.id],
      });

    default:
      return state;
  }
};
