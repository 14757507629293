import { type AnyAction } from "redux";
import actions from "./actions";
import { DATE_FORMAT } from "@/constants/dates";
import { type RootState } from "@/types/app";
import { type IMeState } from "@/types/me";
import { formatDate } from "@/utils/dates";
import { toggleBodyScroll } from "@/utils/global";

export const initialState: IMeState = {
  data: null,
  error: false,
  loading: false,
  cardsLoading: "init",
  cardDeleting: false,
  favoriteLocations: null,
  favoriteLocationsLoading: false,
  favoriteOrders: [],
  pastOrders: [],
  reorderModalOpen: false,
  paymentInfoLoading: false,
  cards: [],
  allLocationsCards: [],
  orderDetails: null,
  myOffers: [],
  myAddresses: [],
  storedAddressesMenuOpen: false,
  storedAddressId: null,
  // Punchh V2 only
  loyaltyState: null,
  redeemables: [],
  messages: null,
  rewardsHistory: [],
  redemptions: [],
  accountDropdownOpen: false,
  activeRedemption: null,
  receiptBarcodeLoading: false,
  favoriteLocationFormOpen: false,
  favoriteLocationMenuOpen: false,
  favoriteLocationId: null,
};

export const me = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_ME:
    case actions.FETCH_LOYALTY_STATE:
    case actions.FETCH_REDEEMABLES:
    case actions.FETCH_MESSAGES:
    case actions.FETCH_REWARDS_HISTORY:
    case actions.FETCH_REDEMPTIONS:
    case actions.CREATE_REDEMPTION:
      return Object.assign({}, state, {
        error: false,
        loading: true,
      });
    case actions.FETCH_ME_FAVORITE_LOCATIONS:
      return Object.assign({}, state, {
        error: false,
        favoriteLocationsLoading: true,
      });
    case actions.CLEAR_ME:
      return initialState;
    case actions.ME_SUCCESS:
      const successResult = Object.assign({}, state, {
        data: action.data,
        loading: false,
      });
      return successResult;
    case actions.UPDATE_ME_FAIL:
      const result = Object.assign({}, state, {
        loading: false,
      });
      return result;
    case actions.FETCH_ME_ORDER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        orderDetails: action.orderDetails,
      });
    case actions.CLEAR_ME_ORDER_DETAILS:
      return Object.assign({}, state, {
        orderDetails: null,
      });
    case actions.ME_FAVORITE_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        favoriteLocations: action.favoriteLocations,
        favoriteLocationsLoading: false,
      });
    case actions.FETCH_ME_FAVORITE_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        favoriteOrders: action.favoriteOrders,
      });
    case actions.FETCH_ME_PAST_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        pastOrders: action.pastOrders,
      });
    case actions.TOGGLE_REORDER_RECEIPT:
      return Object.assign({}, state, {
        reorderModalOpen: action.show,
      });
    case actions.FETCH_ME_CREDIT_CARDS:
      return Object.assign({}, state, {
        cardsLoading: "fetching",
      });
    case actions.FETCH_ME_CREDIT_CARDS_SUCCESS:
      return Object.assign({}, state, {
        cardsLoading: "success",
        cards: action.cards,
      });
    case actions.FETCH_ME_CREDIT_CARDS_FAIL:
      return Object.assign({}, state, {
        cardsLoading: "failure",
        cards: [],
      });
    case actions.FETCH_ADDRESSES_SUCCESS:
      return Object.assign({}, state, {
        myAddresses: action.myAddresses,
      });
    case actions.TOGGLE_STORED_ADDRESSES_MENU:
      return Object.assign({}, state, {
        storedAddressesMenuOpen: action.show,
      });
    case actions.SET_ACTIVE_STORED_ADDRESS:
      return Object.assign({}, state, {
        storedAddressId: action.addressId,
      });
    case actions.FETCH_ALL_LOCATIONS_CREDIT_CARDS:
      return Object.assign({}, state, {
        paymentInfoLoading: true,
      });
    case actions.FETCH_ALL_LOCATIONS_CREDIT_CARDS_SUCCESS:
      return Object.assign({}, state, {
        paymentInfoLoading: false,
        allLocationsCards: action.allLocationsCards,
      });
    case actions.DELETE_ME_CREDIT_CARD:
      return Object.assign({}, state, {
        cardDeleting: true,
      });
    case actions.DELETE_ME_CREDIT_CARD_SUCCESS:
      return Object.assign({}, state, {
        cardDeleting: false,
      });
    case actions.ME_FAIL:
      return Object.assign({}, state, {
        error: true,
        loading: false,
      });
    case actions.ME_STOP_LOADING:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.UPDATE_ME:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.UPDATE_ME_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.FETCH_OFFERS_SUCCESS:
      return Object.assign({}, state, {
        myOffers: action.rewards,
      });
    case actions.APPEND_USER_PHONE:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          phone: action.phone,
        }),
      });
    // Punchh V2 only
    case actions.FETCH_LOYALTY_STATE_SUCCESS:
      return Object.assign({}, state, {
        loyaltyState: action.data,
        loading: false,
      });
    case actions.FETCH_REDEEMABLES_SUCCESS:
      return Object.assign({}, state, {
        redeemables: action.redeemables,
        loading: false,
      });
    case actions.FETCH_MESSAGES_SUCCESS:
      return Object.assign({}, state, {
        messages: action.messages,
      });
    case actions.MARK_MESSAGE_AS_READ:
      return {
        ...state,
        messages: state.messages
          ? state.messages.map((message) => {
              if (message.id !== action.messageId) {
                return message;
              }

              return {
                ...message,
                read_at: formatDate(null, DATE_FORMAT.ISO_DATE),
              };
            })
          : null,
      };
    case actions.FETCH_REWARDS_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        rewardsHistory: action.rewardsHistory,
        loading: false,
      });
    case actions.FETCH_REDEMPTIONS_SUCCESS:
      return Object.assign({}, state, {
        redemptions: action.redemptions,
      });
    case actions.CREATE_REDEMPTION_SUCCESS:
      return Object.assign({}, state, {
        activeRedemption: action.activeRedemption,
        loading: false,
      });
    case actions.CREATE_REDEMPTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
      });
    case actions.CLEAR_ACTIVE_REDEMPTION:
      return Object.assign({}, state, {
        activeRedemption: null,
      });
    case actions.TOGGLE_ACCOUNT_DROPDOWN:
      const accountDropdownOpen =
        typeof action.show !== "undefined"
          ? action.show
          : !state.accountDropdownOpen;

      // Disable / enable body scrolling
      toggleBodyScroll(!accountDropdownOpen);

      return Object.assign({}, state, {
        accountDropdownOpen:
          typeof action.show !== "undefined"
            ? action.show
            : !state.accountDropdownOpen,
      });
    case actions.REDEEM_RECEIPT_BARCODE:
      return Object.assign({}, state, {
        receiptBarcodeLoading: true,
      });
    case actions.REDEEM_RECEIPT_BARCODE_SUCCESS:
    case actions.REDEEM_RECEIPT_BARCODE_FAILURE:
      return Object.assign({}, state, {
        receiptBarcodeLoading: false,
      });
    case actions.SET_ME_CREDIT_CARDS_LOADING_TO_INIT_STATE:
      return Object.assign({}, state, {
        cardsLoading: "init",
      });
    default:
      return state;
  }
};

export const meSliceSelector = (state: RootState) => state.app.me;
