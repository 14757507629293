export const DEFAULT_SOCIAL_EXTRA_PAYLOAD = { flow_type: "implicit" };
export const DEFAULT_PASSWORD_LOGIN_EXTRA_PAYLOAD = { flow_type: "password" };

export enum CAPTCHA_ACTIONS {
  LOGIN = "login",
  REGISTER = "register",
  STORED_VALUE_BALANCE = "stored_value_balance",
  SUBMISSION = "submission",
}

type CAPTCHA_FIELD_NAME = "captcha_response";

export const CAPTCHA_CONFIG = {
  FIELD_NAME: "captcha_response" as CAPTCHA_FIELD_NAME,
  API_ACTIONS: CAPTCHA_ACTIONS,
};

export enum SIGNUP_OPTIONAL_FIELDS {
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  OPT_IN = "opt_in",
  BIRTHDAY = "birthday",
  REFERRAL_CODE = "invite_code",
  ZIP_CODE = "zip_code",
}

export enum EXTERNAL_AUTH_FEATURES {
  REQUIRES_EMAIL_VERIFICATION = "requiresEmailVerification",
  SUPPORT_FOR_LOGIN_WITH_SOCIAL = "supportForLoginWithSocial",
  SUPPORTS_STORED_CARDS = "supportsStoredCards",
  SUPPORTS_SINGLE_FAVORITE_LOCATIONS = "supportsSingleFavoriteLocations",
  SUPPORTS_STORED_ADDRESSES = "supportsStoredAddresses",
  UPDATE_STORED_ADDRESSES = "updateStoredAddresses",
  LIST_STORED_ADDRESSES = "listStoredAddresses",
  DELETE_STORED_ADDRESSES = "deleteStoredAddresses",
  SUPPORTS_ACCOUNT_DELETION = "supportsAccountDeletion",
}
