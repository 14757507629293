import { type SupportedPaymentTypes } from "@koala/sdk";

const paymentActions = {
  PAYMENT_TYPES_GET: "/koala/paymentTypes/PAYMENT_TYPES_GET",
  PAYMENT_TYPES_GET_SUCCESS: "/koala/paymentTypes/PAYMENT_TYPES_GET_SUCCESS",
  PAYMENT_TYPES_CLEAR: "/koala/paymentTypes/PAYMENT_TYPES_CLEAR",

  paymentTypesGet: (basketId: string, locationId: number) => ({
    type: paymentActions.PAYMENT_TYPES_GET,
    basketId,
    locationId,
  }),
  paymentTypesGetSuccess: (paymentTypes: SupportedPaymentTypes) => ({
    type: paymentActions.PAYMENT_TYPES_GET_SUCCESS,
    paymentTypes,
  }),
  paymentTypesClear: () => ({
    type: paymentActions.PAYMENT_TYPES_CLEAR,
  }),
};

export default paymentActions;
