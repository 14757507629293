import { type RequestMeta, ConversionTrackingHelper } from "@koala/sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { withUrqlClient } from "next-urql";
import App, { type AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { END } from "redux-saga";
import { ThemeProvider } from "styled-components";
import { MetaDefault } from "@/components/meta/default";
import { MetaSite } from "@/components/meta/site";
import { RouteHandler } from "@/components/routeHandler";
import { ERROR_MESSAGES } from "@/constants/events";
import { DEFAULT_SSR_CACHE_POLICY } from "@/constants/global";
import { getConfigs } from "@/features/configs/configs";
import { getOrganization } from "@/features/configs/organization";
import { getStrings } from "@/features/configs/strings";
import { useDispatch, useSelector } from "@/redux";
import basketActions from "@/redux/basket/actions";
import cmsConfigActions from "@/redux/cmsConfig/actions";
import meActions from "@/redux/me/actions";
import organizationActions from "@/redux/organization/actions";
import { type SagaStore, wrapper } from "@/redux/store";
import { contentSetupAPIRequests } from "@/services/content.service";
import { getOrigin, getRequestOrigin } from "@/utils";
import * as ErrorReporter from "@/utils/errorReporter";
import { prepareErrorMessage } from "@/utils/global";
import {
  getGoogleFontsCssUrl,
  maybeReloadGoogleFonts,
} from "@/utils/google-fonts";
import {
  fireKAnalyticsError,
  initializeKoalaAnalytics,
} from "@/utils/koalaAnalytics";

/**
 * A custom `App` component that wraps every page in the application.
 *
 * @see https://nextjs.org/docs/advanced-features/custom-app
 */
const OrderingApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  // Get the brand config from the store.
  const initialConfig = useSelector((state) => state.app.cmsConfig.webConfig);
  const { organization } = useSelector((state) => state.app.organization);
  const [queryClient] = useState(() => new QueryClient());

  // Set up error reporting.
  useEffect(() => {
    ErrorReporter.initialize(organization.label);
  }, [organization.label]);

  // DO NOT REMOVE
  // This code ensures configs are hydrated on the client to avoid CloudFront caching issues
  useEffect(() => {
    dispatch(cmsConfigActions.fetchConfigs("fetchClient", queryClient));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Whenever the config slice is updated, refetch google fonts
  useEffect(() => {
    const googleFontUrl = getGoogleFontsCssUrl(initialConfig);

    if (googleFontUrl) {
      maybeReloadGoogleFonts(googleFontUrl);
    }
  }, [initialConfig]);

  useEffect(
    function hydrateClientState() {
      // If a basket exists in localStorage check that it's is still fresh and valid
      dispatch(basketActions.initializeLocalStorageBasket());
      // check logged-in status
      dispatch(meActions.fetchMe());
    },
    [router.pathname, dispatch]
  );

  // If the component is rendered on the client, derive the page name and instantiate Koala Analytics.
  if (typeof window !== "undefined") {
    initializeKoalaAnalytics(organization.id, organization.label);

    // Retrieve rwg_token from the URL query string
    const conversionTracker = new ConversionTrackingHelper();
    conversionTracker.setTrackingKey();
  }

  return (
    <>
      <MetaSite config={initialConfig} organization={organization} />
      <MetaDefault config={initialConfig} />
      <ThemeProvider theme={initialConfig}>
        <QueryClientProvider client={queryClient}>
          <RouteHandler />
          <Component {...pageProps} />
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
};

/**
 * Fetching the brand config is a blocking data requirement for every page,
 * so we need to opt-out of Next's automatic static optimization and run
 * the following dispatch on every page.
 */
OrderingApp.getInitialProps = wrapper.getInitialAppProps<string>(
  (store) => async (context) => {
    /**
     * 🚨 REDUX SAGAS TRIGGERED SERVER-SIDE THAT CALL THE SDK WILL NOT WORK!
     * All SDK-dependant Sagas rely on `typeof window !== "undefined"` in
     * order to determine the request origin. This condition won't be met
     * server-side and the sagas will fail silently.
     *
     * If you need to make SDK requests server-side, move the fetch logic
     * directly into this function for now!
     *
     * @see https://github.com/Chowly/koala-ordering-webapp/pull/1786
     */
    const queryClient = new QueryClient();
    let origin: string;
    if (typeof window !== "undefined") {
      origin = getOrigin(window.location.host);
    } else if (context.ctx.req?.headers.host) {
      origin = getOrigin(context.ctx.req.headers.host);
    } else {
      throw new Error("Missing request origin");
    }
    try {
      // Fetch organization details, strings, and webConfig.
      const [webConfig, strings, organization] = await Promise.all([
        getConfigs(queryClient, origin),
        getStrings(queryClient, origin),
        getOrganization(queryClient, origin),
      ]);

      // Store success responses
      store.dispatch(
        cmsConfigActions.fetchConfigsSuccess(
          "fetchServer",
          "webConfig",
          webConfig
        )
      );
      store.dispatch(
        cmsConfigActions.fetchConfigsSuccess("fetchServer", "strings", strings)
      );
      store.dispatch(
        // @ts-expect-error: mismatched types
        organizationActions.fetchOrganizationSuccess(organization)
      );
    } catch (error) {
      store.dispatch(organizationActions.fetchOrganizationFail());

      // KA Events
      const errorResponse = await prepareErrorMessage(null, error);
      fireKAnalyticsError(
        ERROR_MESSAGES.FETCH_BATCH_CONFIGS_ERROR,
        error,
        errorResponse
      );
    }

    // Then call the page-level `getInitialProps` function that's wrapped by _app.
    const appInitialProps = await App.getInitialProps(context);

    // This only runs if the component is rendered on the server.
    if (context.ctx.req) {
      try {
        /**
         * Set the cache header to re-enable SRR page caching in Next@12+.
         * @see https://nextjs.org/docs/going-to-production#caching
         */
        context.ctx.res?.setHeader("Cache-Control", DEFAULT_SSR_CACHE_POLICY);
      } catch (error) {
        // setting the cache header can fail for 404 redirects
        console.error(error);
      }

      // Wait for all sagas to run before returning.
      store.dispatch(END);
      await (store as SagaStore).sagaTask?.toPromise();
    }

    /**
     * Return the page's initial props. If props from _app.getInitialProps, were
     * added, those would have to be spread onto appInitialProps.
     */
    return appInitialProps;
  }
);

/**
 * Wrap the OrderingApp component in the `next-redux-wrapper`. Wrapping _app
 * will provide the store to the entire application and reconcile store state
 * across client- and server-side rendered components.
 *
 * One important note: when using `getInitialProps` or `getServerSideProps`,
 * you must use the versions exposed by the wrapper. Client-side components
 * can use `connect` or `useDispatch` / `useStore` as usual.
 *
 * @see https://nextjs.org/docs/advanced-features/custom-app
 * @see https://github.com/kirill-konshin/next-redux-wrapper#usage
 */

export default withUrqlClient(
  (ssrExchange, ctx) => {
    let meta: RequestMeta | undefined = undefined;
    if (typeof window !== "undefined") {
      meta = getRequestOrigin(window.location.host);
    } else if (ctx?.req?.headers.host) {
      meta = getRequestOrigin(ctx.req.headers.host);
    }
    return contentSetupAPIRequests(ssrExchange, meta);
  },
  { neverSuspend: true }
)(wrapper.withRedux(OrderingApp));
