type breakpointSize = "small" | "medium" | "large";

export const isBreakpointSizeMin = (size: breakpointSize): boolean => {
  if (typeof window === "undefined") {
    return false;
  }
  switch (size) {
    case "small":
      return window.matchMedia("(min-width: 576px)").matches;
    case "medium":
      return window.matchMedia("(min-width: 768px)").matches;
    case "large":
      return window.matchMedia("(min-width: 1025px)").matches;
    default:
      return false;
  }
};
