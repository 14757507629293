import { type ISecondaryCtaMapping } from "@/types/locations";

export enum SUPPORTED_COUNTRIES {
  CANADA = "CA",
  USA = "US",
}

export const LOCATION_OPTIONS = {
  defaultSearchRadius: 10,
};

export const LOCATION_STATUSES = {
  ACTIVE: 4,
  HIDDEN: 11,
  INACTIVE: 5,
};

export const LOCATION_ATTRIBUTE_POSITIONS = {
  PRIMARY_ORDER_CTA: 1,
  SECONDARY_ORDER_CTA: 2,
  THIRD_ORDER_CTA: 3,
  FOURTH_ORDER_CTA: 4,
  STORE_DETAIL_HERO: 5,
  FIFTH_ORDER_CTA: 7,
  SIXTH_ORDER_CTA: 8,
  SEVENTH_ORDER_CTA: 9,
  EIGHTH_ORDER_CTA: 10,
  NINTH_ORDER_CTA: 11,
};

export const LOCATIONS_CONFIG = {
  ALL_DISPLAY: {
    GROUP_BY_STATE: "group",
    LIST: "list",
  },
};

export enum LOCATION_VIEW_STATES {
  SEARCH = "search",
  VIEW_ALL = "view_all",
}

export const SECONDARY_CTA_MAPPINGS: ISecondaryCtaMapping[] = [
  {
    accessor: "third",
    position: LOCATION_ATTRIBUTE_POSITIONS.THIRD_ORDER_CTA,
  },
  {
    accessor: "fourth",
    position: LOCATION_ATTRIBUTE_POSITIONS.FOURTH_ORDER_CTA,
  },
  {
    accessor: "fifth",
    position: LOCATION_ATTRIBUTE_POSITIONS.FIFTH_ORDER_CTA,
  },
  {
    accessor: "sixth",
    position: LOCATION_ATTRIBUTE_POSITIONS.SIXTH_ORDER_CTA,
  },
  {
    accessor: "seventh",
    position: LOCATION_ATTRIBUTE_POSITIONS.SEVENTH_ORDER_CTA,
  },
  {
    accessor: "eighth",
    position: LOCATION_ATTRIBUTE_POSITIONS.EIGHTH_ORDER_CTA,
  },
  {
    accessor: "ninth",
    position: LOCATION_ATTRIBUTE_POSITIONS.NINTH_ORDER_CTA,
  },
];

export const CART_MIGRATION_TYPES = {
  MIGRATE: "Cart Migrate",
  RESET: "Cart Reset",
};

export enum GOOGLE_MAP_MARKER_ICON_PATHS {
  "driver" = "/static/img/google-map-marker-icons/driver-google-map-marker.svg",
  "customer" = "/static/img/google-map-marker-icons/customer-google-map-marker.svg",
  "store" = "/static/img/google-map-marker-icons/store-google-map-marker.svg",
}
