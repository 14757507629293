import { createWrapper } from "next-redux-wrapper";
import {
  applyMiddleware,
  createStore,
  type Middleware,
  type Store,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware, { type Task } from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { ENV } from "@/constants/envConfig";
import { type RootState } from "@/types/app";

// Extend the Redux Store type to include the Redux-Saga task type.
export interface SagaStore extends Store<RootState> {
  sagaTask?: Task;
}

/**
 * Applies middleware to the Redux Store and conditionally sets up Redux
 * DevTools in development environments.
 *
 * @param middleware Redux middleware to apply to the store.
 * @returns A Store enhancer with middleware applied.
 */
const bindMiddleware = (middleware: Middleware[]) => {
  /**
   * Redux DevTools are only enabled in these deployment environments.
   *
   * @TODO always enable DevTools when developing locally, regardless of env.
   */
  const shouldEnableDevTools = ["local", "dev", "staging", "sandbox"].includes(
    ENV
  );
  if (shouldEnableDevTools) {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

/**
 * Configures a Redux Store for use in the app including
 * Redux-Saga middleware and DevTools.
 *
 * @returns a configured Redux Store.
 */
const configureStore = () => {
  /**
   * The Saga middleware must be instantiated for every `configureStore` call
   * in order for Next's Server-Side Rendering to work properly.
   */
  const sagaMiddleware = createSagaMiddleware();
  // Set up the Redux Store and configure it to use the Saga middleware.
  // @ts-ignore
  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));
  // Run the Redux-Saga middleware.
  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);
  return store;
};

/**
 * The Redux Store wrapped by `next-redux-wrapper`. This wrapper acts as the
 * store provider and also reconciles the client and server stores to support
 * Next's server-side & static rendering.
 *
 * One important note: when using `getInitialProps` or `getServerSideProps`,
 * you must use the versions exposed by the wrapper. Client-side components
 * can use `connect` or `useDispatch` / `useStore` as usual.
 *
 * @see https://github.com/kirill-konshin/next-redux-wrapper#getserversideprops
 * @see https://github.com/vercel/next.js/tree/canary/examples/with-redux-saga
 */
export const wrapper = createWrapper(configureStore);
