import { NotModified, type Strings } from "@koala/sdk";
import {
  removePickupTime,
  getPickupTimeByBasket,
  getPickupTime,
  setPickupTime,
} from "@koala/sdk/v4";
import {
  call,
  put,
  select,
  takeLatest,
  type SagaReturnType,
} from "redux-saga/effects";
import basketActions from "../basket/actions";
import { BASKET_ERROR } from "../basket/messages";
import globalActions from "../global/actions";
import orderStatusActions from "../orderStatus/actions";
import actions from "./actions";
import { DATE_FORMAT } from "@/constants/dates";
import {
  ERROR_MESSAGES,
  K_ANALYTICS_EVENTS,
  LOG_EVENTS,
} from "@/constants/events";
import { DELIVERY_TIME_WANTED_MODES, TIMESLOT_TYPES } from "@/constants/global";
import { createHttpClient } from "@/services/client";
import { type RootState } from "@/types/app";
import { getOrigin } from "@/utils";
import { getIdsFromState } from "@/utils/checkout";
import { getFormattedDateInTimezone } from "@/utils/dates";
import { prepareErrorMessage } from "@/utils/global";
import {
  fireKAnalyticsError,
  fireKAnalyticsEvent,
} from "@/utils/koalaAnalytics";
import { safelyGetString } from "@/utils/stringHelpers";

function* fetchHandoffTimesByLocationSaga(
  action: ReturnType<typeof actions.fetchHandoffTimesByLocation>
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof getPickupTime> = yield call(
      getPickupTime,
      {
        locationId: action.locationId,
        wantedAtType: action.hoursType,
        dayWanted: action.wantedAtDay,
      },
      { client }
    );

    // Include asap here to avoid mutations downstream
    if (action.includeAsap) {
      response.unshift(DELIVERY_TIME_WANTED_MODES.ASAP);
    }

    yield put(
      actions.fetchHandoffTimesSuccess(
        TIMESLOT_TYPES.LOCATION_TIMES,
        response,
        action.wantedAtDay
      )
    );
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, BASKET_ERROR.getPickupTimesSaga, error);
    yield put(globalActions.displayErrorToast(errorResponse.message, true));
    yield put({ type: actions.FETCH_HANDOFF_TIMES_FAILURE });

    // KA event
    fireKAnalyticsError(
      ERROR_MESSAGES.FETCH_HANDOFF_TIMES_BY_LOCATION_ERROR,
      error,
      errorResponse
    );
  }
}

function* fetchHandoffTimesByBasketSaga(
  action: ReturnType<typeof actions.fetchHandoffTimesByBasket>
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof getPickupTimeByBasket> = yield call(
      getPickupTimeByBasket,
      {
        basketId: action.basketId,
        locationId: action.locationId,
        hoursType: action.hoursType,
        dayWanted: action.wantedAtDay,
      },
      { client }
    );

    // Include asap here to avoid mutations downstream
    if (action.includeAsap) {
      response.unshift(DELIVERY_TIME_WANTED_MODES.ASAP);
    }

    // Optionally attempt to default set the wanted at time as the first item
    // in the array. This occurs if a user has manually toggled
    // to a new day in the checkout day dropdown.
    if (action.previousAction !== orderStatusActions.POST_INITIALIZE_ORDER) {
      if (response[0] === DELIVERY_TIME_WANTED_MODES.ASAP) {
        yield put(orderStatusActions.wantedAtBasketRemove());
      } else if (response[0]) {
        yield put(orderStatusActions.wantedAtBasketSet(response[0]));
      } else {
        // We have no new valid response times--most likely the store is closed.
        // Throw an error to stop execution of this saga, revert to previous wanted at time + basket times,
        // and display a toast error to notify the user.
        throw new NotModified();
      }
    }

    // Store handoff times
    yield put(
      actions.fetchHandoffTimesSuccess(
        TIMESLOT_TYPES.BASKET_TIMES,
        response,
        action.wantedAtDay
      )
    );

    if (action.previousAction === orderStatusActions.POST_INITIALIZE_ORDER) {
      // Wanted at is either set or it is ASAP
      const wantedAt = action.wantedAt || DELIVERY_TIME_WANTED_MODES.ASAP;

      // Ensure the wanted at time is available in the response.
      // If not, attempt to set the first item in the response.
      // Do not set if the first item is ASAP, as this is
      // already the current state of the basket
      if (!response.includes(wantedAt)) {
        // Lets also ensure we're not trying to set ASAP and
        // there's actually availability for the selected day
        if (response[0] !== DELIVERY_TIME_WANTED_MODES.ASAP && response[0]) {
          yield put(orderStatusActions.wantedAtBasketSet(response[0]));
        }

        // KA event
        fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
          name: LOG_EVENTS.PERSISTED_WANTED_AT_UNAVAILABLE,
          details: action.wantedAt,
        });
      }
    }
  } catch (error) {
    if (error instanceof NotModified) {
      const wantedAtDay = getFormattedDateInTimezone(
        action.wantedAtDay,
        DATE_FORMAT.WRITTEN_OUT_DATE,
        "+00:00"
      );
      const strings: Strings = yield select(
        (state: RootState) => state.app.cmsConfig.strings
      );
      const errorString = safelyGetString(
        strings,
        "handoff_time.no_available_basket_times",
        { date: wantedAtDay }
      );

      yield put(globalActions.displayErrorToast(errorString, false));
    } else {
      const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
        yield call(prepareErrorMessage, BASKET_ERROR.getPickupTimesSaga, error);
      yield put(globalActions.displayErrorToast(errorResponse.message, true));

      // KA event
      fireKAnalyticsError(
        ERROR_MESSAGES.FETCH_HANDOFF_TIMES_BY_BASKET_ERROR,
        error,
        errorResponse
      );
    }

    yield put({ type: actions.FETCH_HANDOFF_TIMES_FAILURE });
  }
}

function* wantedAtBasketSetSaga(
  action: ReturnType<typeof orderStatusActions.wantedAtBasketSet>
) {
  try {
    // Eagerly store wanted at in redux, pre-API call
    yield put(actions.setWantedAt(action.wantedAt));
    const state: RootState = yield select();
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const { basketId, locationId } = getIdsFromState(state);
    const response: SagaReturnType<typeof setPickupTime> = yield call(
      setPickupTime,
      { basketId, locationId, wanted_at: action.wantedAt },
      { client }
    );

    // Success
    yield put(orderStatusActions.orderPending());
    yield put(basketActions.success(response));

    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.BASKET_WANTED_AT_UPDATE);
  } catch (error) {
    yield put(orderStatusActions.orderPending());

    // We must revert the timeslot state if the attempted time setting fails
    const state: RootState = yield select();
    yield put(
      actions.setWantedAt(state.app.basket.checkoutBasket?.wanted_at, true)
    );

    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, BASKET_ERROR.setPickupTimeSaga, error);
    yield put(
      orderStatusActions.orderStatusMessageErrorSet(errorResponse.message)
    );

    // KA event
    fireKAnalyticsError(
      ERROR_MESSAGES.SET_PICKUP_TIME_ERROR,
      error,
      errorResponse
    );
  }
}

function* wantedAtBasketRemoveSaga() {
  try {
    // Eagerly store wanted at in redux, pre-API call
    yield put(actions.setWantedAt(null));
    const state: RootState = yield select();
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const { basketId, locationId } = getIdsFromState(state);
    const response: SagaReturnType<typeof removePickupTime> = yield call(
      removePickupTime,
      { basketId, locationId },
      { client }
    );

    yield put(orderStatusActions.orderPending());
    yield put(basketActions.success(response));

    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.BASKET_WANTED_AT_UPDATE);
  } catch (error) {
    yield put(orderStatusActions.orderPending());

    // We must revert the timeslot state if the attempted time setting fails
    const state: RootState = yield select();
    yield put(
      actions.setWantedAt(state.app.basket.checkoutBasket?.wanted_at, true)
    );

    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, BASKET_ERROR.setPickupTimeSaga, error);
    yield put(
      orderStatusActions.orderStatusMessageErrorSet(errorResponse.message)
    );

    // KA event
    fireKAnalyticsError(
      ERROR_MESSAGES.REMOVE_PICKUP_TIME_ERROR,
      error,
      errorResponse
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(
    actions.FETCH_HANDOFF_TIMES_BY_BASKET,
    fetchHandoffTimesByBasketSaga
  );
  yield takeLatest(
    actions.FETCH_HANDOFF_TIMES_BY_LOCATION,
    fetchHandoffTimesByLocationSaga
  );

  yield takeLatest(
    orderStatusActions.WANTED_AT_BASKET_SET,
    wantedAtBasketSetSaga
  );
  yield takeLatest(
    orderStatusActions.WANTED_AT_BASKET_REMOVE,
    wantedAtBasketRemoveSaga
  );
}
