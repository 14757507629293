import {
  type BasketItemOption,
  type Category,
  type MenuProduct,
  type MenuProductOptionGroup,
  type MenuInStorage,
  type TagItem,
  CALS_IDENTIFIER,
  type AllergenItem,
  sdkUtils,
  sdkStorage,
  type MenuProductOption,
} from "@koala/sdk";
import find from "lodash/find";
import { type StoreRouteParams } from "./locations";
import { ALLERGEN_CARD_MODE } from "@/constants/global";
import { invertNumberIfOptionInverted } from "@/utils/basket";

// TODO
export const deriveCategoryNavId = (categoryId: string, context: string) =>
  `Category-nav-link-${context}-${categoryId.replace(/\s/g, "")}`;

/**
 * Determine whether any options in the modifier group have images.
 *
 * @param group modifier group to check
 * @returns boolean
 */
export const doesGroupHaveImages = (group: MenuProductOptionGroup) =>
  group.options.some((opt) => opt.images?.image_url_1_by_1);

/**
 * Extract explicit query parameters from the URL
 *
 */
export const getParameterByName = (name: string, url?: string) => {
  const regex = new RegExp(
    `[?&]${name.replace(/[\[\]]/g, "\\$&")}(=([^&#]*)|&|#|$)`
  );
  const results = regex.exec(url || window.location.href);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * Safely encode url strings that contain % signs which would otherwise not be correctly encoded by `encodeURIComponent`
 *
 * @param uri string
 * @returns string
 */
export const encodeURIComponentSafe = (uri: string) => {
  // replace any % signs not followed by a HEX code with %25
  // see: https://stackoverflow.com/questions/7449588/why-does-decodeuricomponent-lock-up-my-browser
  return encodeURIComponent(uri.replace(/%(?![0-9][0-9a-fA-F]+)/g, "%25"));
};

/** Returns a new store menu based on the given route params and tags. */
export const updateStoreMenuUrl = (
  routeParams: StoreRouteParams,
  tags?: number[] | null
) => {
  let url = `/store/${routeParams.id}`;
  if (routeParams.name) {
    url = `${url}/${encodeURIComponentSafe(routeParams.name)}`;
  }
  if (routeParams.catId && routeParams.catName) {
    url = `${url}/${routeParams.catId}/${encodeURIComponentSafe(
      routeParams.catName
    )}`;
  }
  if (routeParams.productId && routeParams.productName) {
    url = `${url}/${routeParams.productId}/${encodeURIComponentSafe(
      routeParams.productName
    )}`;
  }
  if (tags) {
    url = `${url}?filter=${tags.join()}`;
  }
  return url;
};

/**
 * Get the product tags
 * Method to get the allergen tags based on default modifiers or selectedOption values.
 * If selectedOptions is empty then defaults will be shown.  IE menu has defaults / detail as selections
 *
 * @product: MenuProduct A valid product to extract active allergen tags from
 * @selectedOptions: number[] an array of selected optons from the customize props.
 */
export const getAllProductOptionAllergens = (
  product: MenuProduct,
  selectedOptions: BasketItemOption[] = [],
  mode?: string
) => {
  // Check if selections are provided
  const selectionsAvailable = selectedOptions.length;
  const max_recursion = 10;
  let current_recursion = 0;
  // Internal Method to recursive loop over a MenuProduct option groups and options
  const optionAllergens = (
    option_groups: MenuProductOptionGroup[],
    allergensArray: AllergenItem[]
  ): AllergenItem[] => {
    // This would happen if allergens is enabled in the cms, but the menu feature has not been turned on
    if (!allergensArray) {
      console.log("Augments allergens not enabled.");
      return [];
    }

    if (!option_groups || !option_groups.length) {
      return allergensArray;
    }

    option_groups.map((group) => {
      // With defaults we only care about groups with min selection.
      // Or when we have selections we need to check all the groups.
      if (
        (group.min_selections && group.min_selections > 0) ||
        selectionsAvailable
      ) {
        // foundDefault - I thought all the groups would have default options, but that's not true, this is set true when the group has one.
        let foundDefault = false;
        group.options.map((option) => {
          // Test the option for allergens if it is selected or if no selections check for defaults
          const selected =
            selectionsAvailable &&
            find(selectedOptions, { id: option.id.toString() });
          // Test the option for allergens if it is selected or no selected and a default.
          const optionAllergensArray = option?.allergens ?? [];
          if (selected || (!selected && optionAllergensArray?.length)) {
            if (selected || option.is_default) {
              // Found a default in this group!
              foundDefault = true;
              // We don't want "removal tags" is a selected option
              allergensArray = allergensArray.concat(
                optionAllergensArray.filter((a: AllergenItem) => !a.is_removal)
              );
            } else if (!selected) {
              // If we don't find a default or selected option we display "removal tags" if found
              allergensArray = allergensArray.concat(
                optionAllergensArray.filter((a: AllergenItem) => a.is_removal)
              );
            }
          }
          // OHH, here we go again, this opton contains more group_options
          if (option.option_groups && option.option_groups.length) {
            current_recursion++;
            if (current_recursion < max_recursion) {
              const subAllergensArray = optionAllergens(
                option.option_groups,
                []
              );
              if (subAllergensArray.length) {
                allergensArray = allergensArray.concat(subAllergensArray);
              }
            }
          }
        });

        // Don't include 0 index defaults on the menu
        if (mode !== ALLERGEN_CARD_MODE.MENU) {
          // Finally, if nothing is selected, and there is no group options we need to select something because group.min_selections > 0
          const firstOptionAllergens = group?.options[0]?.allergens ?? [];
          if (
            !selectionsAvailable &&
            !foundDefault &&
            firstOptionAllergens?.length
          ) {
            // A made up "default" selection doesn't show "removal tags"
            allergensArray = allergensArray.concat(
              firstOptionAllergens.filter((a: AllergenItem) => !a.is_removal)
            );
            // were we go again, this opton contains more group_options
            if (group?.options[0]?.option_groups) {
              current_recursion++;
              if (current_recursion < max_recursion) {
                const subAllergensArray = optionAllergens(
                  group.options[0].option_groups,
                  []
                );
                if (subAllergensArray.length) {
                  allergensArray = allergensArray.concat(subAllergensArray);
                }
              }
            }
          }
        }
      }
    });

    return allergensArray;
  };

  return optionAllergens(product.option_groups, product.allergens);
};

export const filterProductsByTagId = (
  tags: TagItem[] | null,
  activeTagIds: number[] | null
) => {
  // If no activeTagId, just return all the products
  if (!activeTagIds || !activeTagIds.length) {
    return true;
  }

  // Product filter tags should never be null
  // If they are, change to empty array to prevent crashing
  if (!tags) {
    tags = [];
    console.warn("ERROR: Product-level filter tags should never be null");
  }

  return tags.map((tag) => tag?.id).some((id) => activeTagIds.includes(id));
};

export const filterCategoriesByTagId = (
  category: Category,
  activeTagIds: number[] | null
) => {
  // If no activeTagIds, return all categories
  if (!activeTagIds || !activeTagIds.length) {
    return true;
  }

  const tagIds = category.products
    .flatMap((product) => product?.filter_tags)
    .filter((tag) => tag?.id !== undefined)
    .map((tag) => Number(tag?.id));
  const categoryTagsIds = [...new Set(tagIds)]; // filter only unique values

  return categoryTagsIds.some((id) => activeTagIds.includes(id));
};

export const setMenuInStorage = (menu: MenuInStorage) => {
  return sdkStorage.menu.set(menu);
};

/**
 * Retrieve a menu from localStorage
 *
 */
export const getMenuFromStorage = () => {
  return sdkStorage.menu.get();
};

export const setOptionQuantityRequirementsText = (
  minSelection: number | null,
  maxSelection: number | null,
  minAggregate: number | null,
  maxAggregate: number | null
) => {
  const pluralize = (str: string, rule: number) =>
    rule === 1 ? str : str + "s";

  // Case 1 (label for Jest tests)
  // Option group has min_selection greater than 0 but no max selection and no aggregate quantity requirements.
  if (
    minSelection &&
    minSelection > 0 &&
    !maxSelection &&
    !minAggregate &&
    !maxAggregate
  ) {
    return pluralize(`Select ${minSelection} option`, minSelection);
  }

  // Case 2
  // Option group has max_selection greater than 0 but no min selection and no aggregate quantity requirements.
  if (
    !minSelection &&
    maxSelection &&
    maxSelection > 0 &&
    !minAggregate &&
    !maxAggregate
  ) {
    return pluralize(`Select up to ${maxSelection} option`, maxSelection);
  }

  // Case 3
  // Option group has no min or max selections, but max_aggregate_quantity must be greater than 0, and min_aggregate must be less than max_aggregate_quantity.
  if (
    !minSelection &&
    !maxSelection &&
    maxAggregate &&
    maxAggregate > 0 &&
    // @ts-expect-error
    minAggregate < maxAggregate
  ) {
    return `Select up to ${maxAggregate}`;
  }

  // Case 4
  // Both min_selections and max_selections must be greater than zero, with min being less than max_selection. However, no aggregate requirements.
  if (
    minSelection &&
    maxSelection &&
    minSelection > 0 &&
    maxSelection > 0 &&
    minSelection < maxSelection &&
    !minAggregate &&
    !maxAggregate
  ) {
    return pluralize(
      `Select between ${minSelection} and ${maxSelection} option`,
      maxSelection
    );
  }

  // Case 5
  // Min_selection and max_selection must both be greater than 0 and equal each other. No aggregate requirements.
  if (
    minSelection &&
    maxSelection &&
    minSelection > 0 &&
    maxSelection > 0 &&
    minSelection === maxSelection &&
    !minAggregate &&
    !maxAggregate
  ) {
    return pluralize(`Select ${minSelection} option`, minSelection);
  }

  // Case 6
  // No min or max selections, but min_aggregate must equal max_aggregate, and both must be greater than 0.
  if (
    !minSelection &&
    !maxSelection &&
    minAggregate &&
    maxAggregate &&
    minAggregate > 0 &&
    maxAggregate > 0 &&
    minAggregate === maxAggregate
  ) {
    return `Select ${maxAggregate}`;
  }

  // Case 7
  // Either min_selection OR max_selection is equal to 1, and BOTH min_aggregate and max_aggregate are equal to 1.
  if (
    (minSelection || maxSelection) === 1 &&
    minAggregate === 1 &&
    maxAggregate === 1
  ) {
    return "Select 1";
  }

  // Case 8
  // Min_selection is greater than 0, but no max_selection. Min_aggregate must equal max_aggregate.
  if (
    minSelection &&
    minSelection > 0 &&
    !maxSelection &&
    minAggregate === maxAggregate
  ) {
    return pluralize(
      `Make ${minAggregate} selections from at least ${minSelection} option`,
      minSelection
    );
  }

  // Case 9
  // No min_selection, but max_selection is greater than zero OR max_selection is greater than min_selection (i.e. min_selection can be null) AND min_aggregate and max_aggregate are both greater than zero and equal each other.
  if (
    ((!minSelection && maxSelection && maxSelection > 0) ||
      (minSelection && maxSelection && minSelection < maxSelection)) &&
    minAggregate &&
    maxAggregate &&
    minAggregate === maxAggregate
  ) {
    return (
      pluralize(`Make ${minAggregate} selection`, minAggregate) +
      pluralize(` from up to ${maxSelection} option`, maxSelection)
    );
  }

  // Case 10
  // Min_selection and max_selection are both greater than 0 and equal each other AND min_aggregate and max_aggregate are equal.
  if (
    minSelection &&
    minSelection > 0 &&
    maxSelection &&
    maxSelection > 0 &&
    minSelection === maxSelection &&
    minAggregate &&
    maxAggregate &&
    minAggregate === maxAggregate
  ) {
    return (
      pluralize(`Make ${minAggregate} selection`, minAggregate) +
      pluralize(` from ${minSelection} option`, minSelection)
    );
  }

  // Else, option quantity helper text is not needed
  return null;
};

/**
 * Derive option calorie display string
 *
 */
export const deriveCalorieDisplay = (
  calories: number | null,
  maxCalories: number | null,
  calorieSeparator: string,
  isInverted: boolean,
  calorieDisplayOverride?: number,
  option?: MenuProductOption
) => {
  // Always show display override if it exists.
  if (calorieDisplayOverride && calorieDisplayOverride > 0) {
    return `${calorieDisplayOverride} ${CALS_IDENTIFIER}`;
  }

  // If the option contains advanced nested mod options and is not selected we should show the calories of the default nested modifiers.
  if (
    option?.contains_adv_nested_modifiers &&
    (calories === null || calories === 0) &&
    !maxCalories
  ) {
    const advancedModOptionGroup = option.option_groups.find(
      (og) => og.is_adv_nested_modifier
    );

    const cals = advancedModOptionGroup?.options.find(
      (opt) => opt.is_default
    )?.calories;

    if (cals) return `${cals} ${CALS_IDENTIFIER}`;

    return "";
  }

  // If calories are null, there are no calories or max calories
  if (calories === null) {
    return "";
  }

  // If calories are 0 and there are no max calories, we also display nothing
  if (calories === 0 && !maxCalories) {
    return "";
  }

  // Start with base calories
  let calorieString: string = invertNumberIfOptionInverted(
    calories,
    isInverted
  )?.toString();

  // If there are max calories, assemble the whole thing
  if (maxCalories) {
    calorieString = `${calorieString}${calorieSeparator}${invertNumberIfOptionInverted(
      maxCalories,
      isInverted
    )}`;
  }

  // Return string with cal identifier
  return `${calorieString} ${CALS_IDENTIFIER}`;
};

export const filterTagsByUnavailableProducts = (
  tags: TagItem[],
  categories: Category[]
) => {
  const acceptedTagIDs = new Set<number>();

  categories.forEach((category: Category) => {
    category.products.forEach((product: MenuProduct) => {
      // safely handle cases where filter tags are null
      // TODO: enable strictNullChecks to ensure this is caught by the compiler
      product.filter_tags?.forEach((tag: TagItem) =>
        acceptedTagIDs.add(tag.id)
      );
    });
  });

  return tags.filter((tag: TagItem) => acceptedTagIDs.has(tag.id));
};

/**
 * ⚠️ Hack Alert ⚠️
 * Reformats a product's `pretty_price` to include the Canadian Dollars
 * symbol prefix. For a more thorough explanation, see the MenuCard component.
 *
 * @param prettyPrice USD-formatted product price string.
 * @param country country that the currency should be formatted to match.
 * @returns localized product display price.
 *
 * @TODO remove this utility function and move to the SDK.
 */
export function formatDisplayPriceByCountry(
  prettyPrice: string | null,
  country: string
) {
  if (!prettyPrice) {
    return null;
  }
  return country.toLocaleUpperCase() === "CA"
    ? `$CA${prettyPrice.slice(1)}`
    : prettyPrice;
}

export const augmentMenuProduct = sdkUtils.augmentMenuProduct;
