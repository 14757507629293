import {
  getStoredValue,
  type PaymentDetails,
  getTokenizedCreditCard,
} from "@koala/sdk/v4";
import {
  call,
  put,
  type SagaReturnType,
  select,
  throttle,
  takeLatest,
} from "redux-saga/effects";
import { selectBasketSlice } from "../basket";
import orderStatusActions from "../orderStatus/actions";
import actions from "./actions";
import { storedValueErrors } from "./constants";
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from "@/constants/events";
import { createHttpClient } from "@/services/client";
import { type RootState } from "@/types/app";
import { getOrigin } from "@/utils";
import { toDollars } from "@/utils/basket";
import { prepareErrorMessage } from "@/utils/global";
import {
  fireKAnalyticsError,
  fireKAnalyticsEvent,
} from "@/utils/koalaAnalytics";

/**
 * Payment type toggle
 *
 */
function* paymentTypeToggleSaga() {
  try {
    const state: RootState = yield select();
    const { checkoutBasket } = state.app.basket;

    // If toggling payment types for a brand that does not support split payment,
    // ensure any applied gift cards are cleared
    if (!checkoutBasket?.location.supports_split_payment) {
      yield put(actions.storedValueClear());
    }
  } catch (error) {
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
      name: ERROR_MESSAGES.PAYMENT_TOGGLE_SAGA_ERROR,
    });
  }
}

/**
 * Exchange transaction id for card aliases
 *
 */
export function* creditCardTransactionIdSaga(
  action: ReturnType<typeof actions.creditCardTransactionIdGet>
) {
  try {
    const origin = getOrigin(window.location.host);
    const client = createHttpClient({ origin });
    // Grab the Basket & Location IDs from the store.
    const { checkoutBasket }: ReturnType<typeof selectBasketSlice> =
      yield select(selectBasketSlice);

    // Exchange the Transaction ID for tokenized card details.
    const res: SagaReturnType<typeof getTokenizedCreditCard> = yield call(
      getTokenizedCreditCard,
      {
        basketId: checkoutBasket.id,
        locationId: checkoutBasket.location.id,
        transactionId: action.creditCard.id!,
      },
      { client }
    );

    // Store the full tokenized card details.
    const creditCardWithAlias: PaymentDetails = {
      ...action.creditCard,
      alias_cc: res.alias_cc,
      alias_cvv: res.alias_cvv,
      country: res.country,
      last_four: res.last_four,
      masked_card: res.masked_card,
    };
    yield put(actions.creditCardSet(creditCardWithAlias));
  } catch (error) {
    /**
     * If exchanging the Transaction ID for the tokenized card fails, store
     * the untokenized card and the Ordering API will re-attempt the exchange.
     */
    yield put(actions.creditCardSet(action.creditCard));
  }
}

/**
 * Get stored value
 *
 */
function* storeValuePostSaga(
  action: ReturnType<typeof orderStatusActions.storedValuePost>
) {
  try {
    const state: RootState = yield select();
    const { checkoutBasket } = state.app.basket;
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof getStoredValue> = yield call(
      getStoredValue,
      {
        basketId: checkoutBasket?.id,
        locationId: checkoutBasket?.location.id,
        cardNumber: action.cardNumber,
        pin: action.pin,
        captcha: action.captcha,
      },
      { client }
    );

    // If split-payment is not supported and the gift card balance does not cover the order total
    if (
      !checkoutBasket?.location.supports_split_payment &&
      response.balance < state.app.basket.checkoutBasket?.total
    ) {
      throw new Error(
        `Oops, card balance $${toDollars(
          response.balance
        )} does not cover order total.`
      );
    }

    // Send response to payment saga for calculation/processing
    yield put(
      actions.storedValueSet({
        balance: response.balance,
        card_number: action.cardNumber,
        pin: action.pin,
      })
    );

    yield put(orderStatusActions.orderPending());

    // KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.GIFT_CARD_APPLIED);
  } catch (error) {
    yield put(orderStatusActions.orderPending());

    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> =
      yield call(prepareErrorMessage, null, error);

    // Parse API error or split-payment error
    let errorMessage = errorResponse.error
      ? storedValueErrors.DEFAULT
      : (error as Error).message;

    // Additional check for PIN requirement errors
    if (
      // @ts-expect-error
      errorResponse?.error?.error_description.indexOf(
        "This billing scheme requires a PIN"
      ) > -1
    ) {
      errorMessage = storedValueErrors.PIN_REQUIRED;
    }

    // Additional check for captcha errors
    if (
      // @ts-expect-error
      errorResponse?.error?.error_description.indexOf(
        "We could not validate the authenticity of your request"
      ) > -1
    ) {
      errorMessage = storedValueErrors.CAPTCHA;
    }

    yield put(actions.storedValueError(errorMessage));

    // Fire event
    fireKAnalyticsError(
      ERROR_MESSAGES.POST_STORED_VALUE_ERROR,
      error,
      errorResponse
    );
  }
}

export default function* rootSaga() {
  yield throttle(
    1500,
    actions.CREDIT_CARD_TRANSACTION_ID_GET,
    creditCardTransactionIdSaga
  );
  yield takeLatest(orderStatusActions.STORED_VALUE_POST, storeValuePostSaga);
  yield takeLatest(actions.STORED_VALUE_TOGGLE, paymentTypeToggleSaga);
  yield takeLatest(actions.BILLING_ACCOUNT_SET, paymentTypeToggleSaga);
  yield takeLatest(actions.PAY_IN_STORE_SET, paymentTypeToggleSaga);
  yield takeLatest(actions.PAYMENT_TYPE_RESET, paymentTypeToggleSaga);
}
