import { ROUTES } from "@/constants/routes";

export const isAndroidShell = () =>
  typeof window !== "undefined" && window.KoalaAndroidShell ? true : false;

export const getAndroidShellPushNotificationToken = () =>
  isAndroidShell()
    ? { fcm_token: window.KoalaAndroidShell.getPushNotificationsToken() }
    : {};

export const requestAndroidShellRatingsModal = () => {
  if (isAndroidShell()) {
    window.KoalaAndroidShell.showRatingsModal();
  }
};

export const getAndroidShellVersion = () =>
  isAndroidShell() ? `v${window.KoalaAndroidShell.getVersionName()}` : "v1.0.0";

export const getAndroidShellDeviceId = (): string | null =>
  isAndroidShell() ? window.KoalaAndroidShell.getDeviceId() : null;

export const setAndroidCurrentPage = (
  pathname: string,
  back: () => void,
  push: (path: string) => void
) => {
  if (
    [ROUTES.ORDER_CONFIRMATION, ROUTES.LOGIN, ROUTES.HOMEPAGE].includes(
      pathname
    )
  ) {
    push(ROUTES.ANDROID);
  } else {
    back();
  }
};
