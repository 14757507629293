import { type ParsedUrlQuery } from "querystring";
import { type BasketItem, type Location, type MenuProduct } from "@koala/sdk";
import {
  type IGlobalFeatureBag,
  type FEATURE_FLAGS,
} from "@/constants/features";

const globalActions = {
  TOGGLE_BASKET: "/koala/global/TOGGLE_BASKET",
  TOGGLE_FULFILLMENT_MODAL: "/koala/global/TOGGLE_FULFILLMENT_MODAL",
  DELIVERY_ADDRESS_VALID: "/koala/global/DELIVERY_ADDRESS_VALID",
  DELIVERY_ADDRESS_INVALID: "/koala/global/DELIVERY_ADDRESS_INVALID",
  DISPLAY_TOAST: "koala/global/DISPLAY_TOAST",
  DISMISS_TOAST: "koala/global/DISMISS_TOAST",
  RENDER_POST_ITEM_ADD_COMPONENT: "koala/global/RENDER_POST_ITEM_ADD_COMPONENT",
  SET_CART_MIGRATION: "koala/global/SET_CART_MIGRATION",
  HYDRATE_PERSISTENT_PARAMETERS: "koala/global/HYDRATE_PERSISTENT_PARAMETERS",
  SET_PERSISTENT_PARAMETERS: "koala/global/SET_PERSISTENT_PARAMETERS",
  CLEAR_PERSISTENT_PARAMETERS: "koala/global/CLEAR_PERSISTENT_PARAMETERS",
  CLEAR_PERSISTENT_PARAMETERS_SUCCESS:
    "koala/global/CLEAR_PERSISTENT_PARAMETERS_SUCCESS",
  SET_ROUTER_PATH: "koala/global/SET_ROUTER_PATH",
  GENERATE_FEATURE_BAG: "koala/global/GENERATE_FEATURE_BAG",
  UPDATE_FEATURE_BAG: "koala/global/UPDATE_FEATURE_BAG",
  UPDATE_FEATURE_BAG_FLAG: "koala/global/UPDATE_FEATURE_BAG_FLAG",
  CLEAR_FEATURE_BAG: "koala/global/CLEAR_FEATURE_BAG",

  toggleBasket: (show: boolean) => ({
    type: globalActions.TOGGLE_BASKET,
    show,
  }),
  toggleFulfillmentModal: (
    show: boolean,
    location?: Location,
    fulfillmentType?: string,
    confirm?: boolean,
    timeslotOverride?: string,
    revalidate?: boolean,
    disableModalClose?: boolean,
    miniConveyanceSwitch?: boolean
  ) => ({
    type: globalActions.TOGGLE_FULFILLMENT_MODAL,
    show,
    location,
    fulfillmentType,
    confirm,
    timeslotOverride,
    revalidate,
    disableModalClose,
    miniConveyanceSwitch,
  }),
  displayToast: (message: string, forceDismiss?: boolean) => ({
    type: globalActions.DISPLAY_TOAST,
    message,
    status: "success",
    forceDismiss,
  }),
  displayErrorToast: (error: string, forceDismiss?: boolean) => ({
    type: globalActions.DISPLAY_TOAST,
    message: error,
    status: "error",
    forceDismiss,
  }),
  dismissToast: () => ({
    type: globalActions.DISMISS_TOAST,
  }),
  setCartMigration: (type?: string, pendingItem?: MenuProduct) => ({
    type: globalActions.SET_CART_MIGRATION,
    cartMigration: {
      type,
      pendingItem,
    },
  }),
  renderPostItemAddComponent: (item?: BasketItem, index?: number) => ({
    type: globalActions.RENDER_POST_ITEM_ADD_COMPONENT,
    item,
    index,
  }),
  hydratePersistentParameters: (routeParameters: ParsedUrlQuery) => ({
    type: globalActions.HYDRATE_PERSISTENT_PARAMETERS,
    routeParameters,
  }),
  setPersistentParameters: (routeParameters: ParsedUrlQuery) => ({
    type: globalActions.SET_PERSISTENT_PARAMETERS,
    routeParameters,
  }),
  clearPersistentParameters: () => ({
    type: globalActions.CLEAR_PERSISTENT_PARAMETERS,
  }),
  setRouterPathname: (pathname: string) => ({
    type: globalActions.SET_ROUTER_PATH,
    pathname,
  }),
  generateFeatureBag: () => ({
    type: globalActions.GENERATE_FEATURE_BAG,
  }),
  updateFeatureBag: (featureBag: IGlobalFeatureBag) => ({
    type: globalActions.UPDATE_FEATURE_BAG,
    featureBag,
  }),
  updateFeatureBagFlag: (featureFlag: FEATURE_FLAGS) => ({
    type: globalActions.UPDATE_FEATURE_BAG_FLAG,
    featureFlag,
  }),
  clearFeatureBag: () => ({
    type: globalActions.CLEAR_FEATURE_BAG,
  }),
};

export default globalActions;
