import { type OrganizationConfig } from "@koala/sdk";

const organizationActions = {
  FETCH_ORGANIZATION: "/koala/organization/FETCH_ORGANIZATION",
  FETCH_ORGANIZATION_SUCCESS: "/koala/organization/FETCH_ORGANIZATION_SUCCESS",
  FETCH_ORGANIZATION_FAIL: "/koala/organization/FETCH_ORGANIZATION_FAIL",

  fetchOrganization: () => ({
    type: organizationActions.FETCH_ORGANIZATION,
  }),
  fetchOrganizationSuccess: (organizationConfig: OrganizationConfig) => ({
    type: organizationActions.FETCH_ORGANIZATION_SUCCESS,
    organizationConfig,
  }),
  fetchOrganizationFail: () => ({
    type: organizationActions.FETCH_ORGANIZATION_FAIL,
  }),
};

export default organizationActions;
