import {
  deliveryStatusActionTypes,
  type IDeliveryStatusAction,
} from "./actions";
import { type IDeliveryStatusState } from "@/types/deliveryStatus";
import { getISODateInUTC } from "@/utils/dates";

/** @TODO decouple IDeliveryStatusState from the SDK response type. */
export const initialState: IDeliveryStatusState = {
  // @ts-ignore
  order_id: null,
  // @ts-ignore
  order_number: null,
  // @ts-ignore
  delivery_service: null,
  // @ts-ignore
  driver_name: null,
  // @ts-ignore
  driver_number: null,
  // @ts-ignore
  status: null,
  // @ts-ignore
  estimated_pick_up_time: null,
  // @ts-ignore
  estimated_drop_off_time: null,
  // @ts-ignore
  pick_up_time: null,
  // @ts-ignore
  drop_off_time: null,
  // @ts-ignore
  cancelled_at_time: null,
  // @ts-ignore
  cancelled_reason: null,
  // @ts-ignore
  lat: null,
  // @ts-ignore
  long: null,
  meta: {
    loading: false,
    error: null,
    params: null,
    // @ts-ignore
    lastUpdatedAt: null,
  },
};

export const deliveryStatus = (
  state: IDeliveryStatusState = initialState,
  action: IDeliveryStatusAction
): IDeliveryStatusState => {
  switch (action.type) {
    case deliveryStatusActionTypes.FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER: {
      const partialState: Partial<IDeliveryStatusState> = {
        order_number: action.order_number,
        meta: {
          error: null,
          loading: true,
          params: null,
        },
      };

      return Object.assign({}, state, partialState);
    }
    case deliveryStatusActionTypes.FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_SUCCESS: {
      const partialState: Partial<IDeliveryStatusState> = {
        meta: {
          error: null,
          loading: false,
          params: null,
          lastUpdatedAt: getISODateInUTC(),
        },
        ...action.data,
      };

      return Object.assign({}, state, partialState);
    }
    case deliveryStatusActionTypes.FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_FAIL: {
      const partialState: Partial<IDeliveryStatusState> = {
        meta: {
          error: action.error,
          loading: false,
          params: null,
          lastUpdatedAt: getISODateInUTC(),
        },
      };

      return Object.assign({}, state, partialState);
    }
    case deliveryStatusActionTypes.CLEAR_DELIVERY_STATUS_STATE:
      return Object.assign({}, initialState);

    default:
      return state;
  }
};
