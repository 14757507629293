export enum StoredValueFields {
  PIN = "pin",
  CARD = "card",
}

export const storedValueMessages = {
  "PIN field is required.": StoredValueFields.PIN,
  "Oops, gift card not found": StoredValueFields.CARD,
};

// Whitelisted API errors
export const storedValueErrors = {
  PIN_REQUIRED: "PIN field is required.",
  CAPTCHA: "Oops, we could not validate this gift card. Please try again.",
  DEFAULT: "Oops, gift card not found",
};
