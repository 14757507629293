import { type AnyAction } from "redux";
import actions from "./actions";
import {
  type IOrderStatusState,
  OrderStatuses,
  OrderStatusTypes,
} from "@/types/orderStatus";

export const initialState: IOrderStatusState = {
  type: OrderStatusTypes.OK,
  message: null,
  status: OrderStatuses.ORDER_NOT_INITIALIZED,
};

export const orderStatus = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    // Message Actions
    case actions.ORDER_STATUS_MESSAGE_CLEAR:
      return Object.assign({}, state, {
        type: OrderStatusTypes.OK,
        message: initialState.message,
      });
    case actions.ORDER_STATUS_MESSAGE_ERROR_SET:
      return Object.assign({}, state, {
        type: OrderStatusTypes.ERROR,
        message: action.message,
      });

    // Generic Actions
    case actions.ORDER_PENDING:
      return Object.assign({}, state, {
        type: OrderStatusTypes.OK,
        message: null,
        status: OrderStatuses.ORDER_PENDING,
      });
    case actions.ORDER_NOT_INITIALIZED:
      return initialState;

    // Initialization Sequence
    case actions.INITIALIZE_ORDER:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.INITIALIZING_ORDER,
      });
    case actions.INITIALIZE_ORDER_ERROR:
      return Object.assign({}, state, {
        type: OrderStatusTypes.ERROR,
        message: action.message,
        status: OrderStatuses.ORDER_NOT_INITIALIZED,
      });
    case actions.INITIALIZE_ORDER_RECONCILIATION:
      return Object.assign({}, state, {
        type: OrderStatusTypes.RECONCILIATION,
        message: action.message,
        status: OrderStatuses.ORDER_NOT_INITIALIZED,
      });

    // Promo Code
    case actions.PROMO_CODE_ADD:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.PROMO_CODE_ADDING,
      });
    case actions.PROMO_CODE_REMOVE:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.PROMO_CODE_REMOVING,
      });

    // Set Conveyance
    case actions.CONVEYANCE_SET:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.CONVEYANCE_SETTING,
      });
    case actions.CONVEYANCE_DELIVERY_SET:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.CONVEYANCE_DELIVERY_SETTING,
      });

    // Set Wanted At
    case actions.WANTED_AT_BASKET_SET:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.WANTED_AT_BASKET_SETTING,
      });
    case actions.WANTED_AT_BASKET_REMOVE:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.WANTED_AT_BASKET_REMOVING,
      });

    // Basket Rewards
    case actions.BASKET_REWARDS_GET:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.BASKET_REWARDS_GETTING,
      });
    case actions.BASKET_REWARDS_ADD:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.BASKET_REWARDS_ADDING,
      });
    case actions.BASKET_REWARDS_REMOVE:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.BASKET_REWARDS_REMOVING,
      });

    // Tip
    case actions.TIP_ADD:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.TIP_ADDING,
      });

    // Tip
    case actions.UTENSILS_SET:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.UTENSILS_SETTING,
      });

    // Basket Instructions
    case actions.BASKET_INSTRUCTIONS_SET:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.BASKET_INSTRUCTIONS_SETTING,
      });

    // Stored Value
    case actions.STORED_VALUE_POST:
      return Object.assign({}, state, {
        type: OrderStatusTypes.UPDATING,
        message: null,
        status: OrderStatuses.STORED_VALUE_POSTING,
      });

    // Submission Sequence
    case actions.ORDER_SUBMISSION_TRIGGER:
      return Object.assign({}, state, {
        type: OrderStatusTypes.TRANSMITTING,
        message: action.message,
        captcha: action.captcha,
        status: OrderStatuses.ORDER_SUBMISSION_TRIGGERED,
      });
    case actions.ORDER_SUBMISSION_PCI_ERROR:
      return Object.assign({}, state, {
        type: OrderStatusTypes.ERROR,
        status: OrderStatuses.ORDER_PENDING,
        message: action.message,
      });
    case actions.ORDER_SUBMISSION_PCI_FAILURE:
      return Object.assign({}, state, {
        type: OrderStatusTypes.ERROR,
        status: OrderStatuses.ORDER_SUBMISSION_PCI_FAILURE,
        message: action.message,
      });
    case actions.ORDER_SUBMISSION_ERROR:
      return Object.assign({}, state, {
        type: OrderStatusTypes.ERROR,
        status: OrderStatuses.ORDER_PENDING,
        message: action.message,
      });

    case actions.DESTROY_ORDER:
      return initialState;
    default:
      return state;
  }
};
