import { KoalaEnv } from "@koala/sdk";
import { z } from "zod";
import { isAndroidShell } from "@/utils/android";
import pkg from "package.json";

const runtimeCredentials = z.object({
  ENV: KoalaEnv,
  clientId: z.string(),
  clientSecret: z.string(),
});

/**
 * Returns the correct environment, Client ID, & Secret for the build.
 * The returned values depend on a few different factors:
 * 1. Are we in local development mode (i.e. running `yarn start:ordering`)?
 * 2. Are we in the Android Kotlin shell?
 * 3. Are we running in a deployed build?
 */
function setup(): z.infer<typeof runtimeCredentials> {
  const env = KoalaEnv.parse(process.env.NEXT_PUBLIC_ENV);

  // If we're in the Android Kotlin shell, use its Client ID & Secret.
  if (isAndroidShell()) {
    const config = runtimeCredentials.parse({
      ENV: env,
      clientId: window.KoalaAndroidShell.getClientId(),
      clientSecret: window.KoalaAndroidShell.getClientSecret(),
    });

    return config;
  }

  /**
   * Otherwise, the build is deployed. The Client ID & Secret share
   * the same environment variable names across all environments.
   */
  const config = runtimeCredentials.parse({
    ENV: env,
    clientId: process.env.NEXT_PUBLIC_UNIFIED_KOALA_CLIENT_ID,
    clientSecret: process.env.NEXT_PUBLIC_UNIFIED_KOALA_CLIENT_SECRET,
  });

  return config;
}

export const { ENV, clientId, clientSecret } = setup();

export const BUILD_VERSION = pkg.version;

export const SENTRY_DSN =
  "https://22cecf8f927247b29a79adc234f9d8b8@o141334.ingest.sentry.io/4504572378808320";
