import { type DeliveryStatus } from "@koala/sdk";

export interface IDeliveryStatusAction {
  type: deliveryStatusActionTypes;
  order_id?: string;
  order_number?: string;
  data?: DeliveryStatus;
  error?: any;
}

export enum deliveryStatusActionTypes {
  FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER = "/koala/deliveryStatus/FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER",
  FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_SUCCESS = "/koala/deliveryStatus/FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_SUCCESS",
  FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_FAIL = "/koala/deliveryStatus/FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_FAIL",
  CLEAR_DELIVERY_STATUS_STATE = "/koala/deliveryStatus/CLEAR_DELIVERY_STATUS_STATE",
}

export const deliveryStatusActions = {
  fetchDeliveryStatusByOrderNumber: (
    orderNumber: string
  ): IDeliveryStatusAction => ({
    type: deliveryStatusActionTypes.FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER,
    order_number: orderNumber,
  }),
  fetchDeliveryStatusByOrderNumberSuccess: (
    data: DeliveryStatus
  ): IDeliveryStatusAction => ({
    data,
    type: deliveryStatusActionTypes.FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_SUCCESS,
  }),
  fetchDeliveryStatusByOrderNumberFail: (
    error: any
  ): IDeliveryStatusAction => ({
    type: deliveryStatusActionTypes.FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER_FAIL,
    error,
  }),
  clearDeliveryStatusState: (): IDeliveryStatusAction => ({
    type: deliveryStatusActionTypes.CLEAR_DELIVERY_STATUS_STATE,
  }),
};
