var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.69.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { ENV, SENTRY_DSN } from "@/constants/envConfig";
import * as Sentry from "@sentry/nextjs";

/**
 * @TODO investigate mechanisms to declutter Sentry if we log a lot of junk.
 * @see https://docs.sentry.io/clients/javascript/tips/
 */
Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,
  release: process.env.SENTRY_RELEASE,
  // Disable tracing for now since it's not used in upstream services.
  tracesSampleRate: 0,
  denyUrls: ["https://siteimproveanalytics.com", "https://maps.googleapis.com"],
});
