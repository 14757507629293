import { type OrderDetails } from "@koala/sdk";

export interface IOrderDetailsAction {
  type: orderDetailsActionTypes;
  order_id?: string;
  data?: OrderDetails;
  error?: any;
}

export enum orderDetailsActionTypes {
  FETCH_ORDER_DETAILS_BY_ORDER_ID = "/koala/orderDetails/FETCH_ORDER_DETAILS_BY_ORDER_ID",
  FETCH_ORDER_DETAILS_BY_ORDER_ID_SUCCESS = "/koala/orderDetails/FETCH_ORDER_DETAILS_BY_ORDER_ID_SUCCESS",
  FETCH_ORDER_DETAILS_BY_ORDER_ID_FAIL = "/koala/orderDetails/FETCH_ORDER_DETAILS_BY_ORDER_ID_FAIL",
  CLEAR_ORDER_DETAILS_STATE = "/koala/orderDetails/CLEAR_ORDER_DETAILS_STATE",
  ADD_ORDER_DETAILS_STATE = "/koala/orderDetails/ADD_ORDER_DETAILS_STATE",
}

export const orderDetailsActions = {
  fetchOrderDetailsByOrderId: (orderId: string): IOrderDetailsAction => ({
    type: orderDetailsActionTypes.FETCH_ORDER_DETAILS_BY_ORDER_ID,
    order_id: orderId,
  }),
  fetchOrderDetailsByOrderIdSuccess: (
    data: OrderDetails
  ): IOrderDetailsAction => ({
    data,
    type: orderDetailsActionTypes.FETCH_ORDER_DETAILS_BY_ORDER_ID_SUCCESS,
  }),
  fetchOrderDetailsByOrderIdFail: (error: any): IOrderDetailsAction => ({
    type: orderDetailsActionTypes.FETCH_ORDER_DETAILS_BY_ORDER_ID_FAIL,
    error,
  }),
  clearOrderDetailsState: () => ({
    type: orderDetailsActionTypes.CLEAR_ORDER_DETAILS_STATE,
  }),
  addOrderDetailsState: (data: OrderDetails) => ({
    type: orderDetailsActionTypes.ADD_ORDER_DETAILS_STATE,
    data,
  }),
};
