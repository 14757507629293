import Router from "next/router";
import { put, select, takeLatest } from "redux-saga/effects";
import orderStatusActions from "../orderStatus/actions";
import actions from "./actions";
import { fireGACommerceViewCartEvent } from "@/analytics/commerce/google";
import { genericEventHandler } from "@/analytics/events";
import { GlobalEvents } from "@/analytics/events/constants";
import { type FEATURE_FLAGS } from "@/constants/features";
import { KOALA_SESSION_STORAGE_KEYS } from "@/constants/global";
import cmsConfigActions from "@/redux/cmsConfig/actions";
import meActions from "@/redux/me/actions";
import { type RootState } from "@/types/app";
import { KoalaFeatureHandler } from "@/utils/features";
import { derivePersistentURLParameters } from "@/utils/global";
import { getURLParam, removeURLParams } from "@/utils/urls";

function* hydratePersistentParametersSaga(
  action: ReturnType<typeof actions.hydratePersistentParameters>
) {
  const sessionStorageData = sessionStorage.getItem(
    KOALA_SESSION_STORAGE_KEYS.PARAMETERS
  );
  const parameters = sessionStorageData
    ? JSON.parse(sessionStorageData)
    : derivePersistentURLParameters(action.routeParameters);

  yield put(actions.setPersistentParameters(parameters));
}

function* setPersistentParametersSaga(
  action: ReturnType<typeof actions.setPersistentParameters>
) {
  if (Object.keys(action.routeParameters).length > 0) {
    sessionStorage.setItem(
      KOALA_SESSION_STORAGE_KEYS.PARAMETERS,
      JSON.stringify(action.routeParameters)
    );
  }
}

function* clearPersistentParametersSaga() {
  sessionStorage.removeItem(KOALA_SESSION_STORAGE_KEYS.PARAMETERS);
  yield put({ type: actions.CLEAR_PERSISTENT_PARAMETERS_SUCCESS });
}

function* generateFeatureBagSaga() {
  const state: RootState = yield select();
  const newFeatureBag = { ...state.app.global.featureBag };
  const FeatureHandler = new KoalaFeatureHandler(state.app);

  Object.keys(newFeatureBag).forEach((featureFlag) => {
    // @ts-expect-error `featureFlag` can't index `newFeatureBag`.
    newFeatureBag[featureFlag] = FeatureHandler.isFeatureAllowed(
      featureFlag as FEATURE_FLAGS
    );
  });

  yield put(actions.updateFeatureBag(newFeatureBag));
}

function* updateFeatureBagFlagSaga(
  action: ReturnType<typeof actions.updateFeatureBagFlag>
) {
  const state: RootState = yield select();
  const newFeatureBag = { ...state.app.global.featureBag };
  const FeatureHandler = new KoalaFeatureHandler(state.app);

  newFeatureBag[action.featureFlag] = FeatureHandler.isFeatureAllowed(
    action.featureFlag
  );

  yield put(actions.updateFeatureBag(newFeatureBag));
}

function* toggleBasketSaga(action: ReturnType<typeof actions.toggleBasket>) {
  const { show } = action;
  const state: RootState = yield select();
  const basket = state.app.basket;
  const basketMenu = state.app.menu.basketMenu;

  if (show) {
    genericEventHandler(GlobalEvents.HEADER__VIEW_BASKET);
    genericEventHandler(GlobalEvents.STORE__CART_VIEW);
    fireGACommerceViewCartEvent(basket, basketMenu);
  } else if (!show) {
    // Set focus to nav logo after closing basket
    const navLogo = document.getElementById("nav-logo");
    genericEventHandler(GlobalEvents.HEADER__CLOSE_BASKET);

    if (getURLParam(window.location.href, "openCart")) {
      const urlData = removeURLParams(["openCart"]);
      void Router.replace(urlData.url, "", { shallow: true });
    }

    if (navLogo) {
      navLogo.focus();
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(
    actions.HYDRATE_PERSISTENT_PARAMETERS,
    hydratePersistentParametersSaga
  );
  yield takeLatest(
    actions.SET_PERSISTENT_PARAMETERS,
    setPersistentParametersSaga
  );
  yield takeLatest(
    actions.CLEAR_PERSISTENT_PARAMETERS,
    clearPersistentParametersSaga
  );
  yield takeLatest(
    [
      actions.GENERATE_FEATURE_BAG,
      actions.SET_PERSISTENT_PARAMETERS,
      meActions.FETCH_ME_PAST_ORDERS_SUCCESS,
      meActions.CLEAR_ME,
      cmsConfigActions.FETCH_CONFIGS_SUCCESS,
      orderStatusActions.POST_INITIALIZE_ORDER,
    ],
    generateFeatureBagSaga
  );
  yield takeLatest(actions.UPDATE_FEATURE_BAG_FLAG, updateFeatureBagFlagSaga);
  yield takeLatest(actions.TOGGLE_BASKET, toggleBasketSaga);
}
