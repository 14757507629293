import {
  httpClient,
  fetchClientToken,
  fetchLoyaltyToken,
  resetToken,
} from "@koala/sdk/v4";
import {
  BUILD_VERSION,
  ENV,
  clientId,
  clientSecret,
} from "@/constants/envConfig";
import { getAndroidShellDeviceId } from "@/utils/android";

interface ClientOrigin {
  origin: string;
}

export function createHttpClient({ origin }: ClientOrigin) {
  const androidDeviceId = getAndroidShellDeviceId();
  return httpClient({
    credentials: { clientId, clientSecret },
    env: ENV,
    headers: {
      ...(androidDeviceId && { androidDeviceId }),
      serviceLabel: `Koala Web Ordering / ${BUILD_VERSION}`,
    },
    origin,
  });
}

export async function getClientToken({ origin }: ClientOrigin) {
  const androidDeviceId = getAndroidShellDeviceId();
  return fetchClientToken({
    credentials: { clientId, clientSecret },
    env: ENV,
    headers: {
      ...(androidDeviceId && { androidDeviceId }),
      serviceLabel: `Koala Web Ordering / ${BUILD_VERSION}`,
    },
    origin,
  });
}

export async function getLoyaltyToken(
  payload: Record<string, any>,
  { origin }: ClientOrigin
) {
  const androidDeviceId = getAndroidShellDeviceId();
  return fetchLoyaltyToken({
    credentials: { clientId, clientSecret },
    env: ENV,
    headers: {
      ...(androidDeviceId && { androidDeviceId }),
      serviceLabel: `Koala Web Ordering / ${BUILD_VERSION}`,
    },
    origin,
    payload,
  });
}

export function resetLoyaltyToken({ origin }: ClientOrigin) {
  const androidDeviceId = getAndroidShellDeviceId();

  return resetToken({
    credentials: { clientId, clientSecret },
    env: ENV,
    headers: {
      ...(androidDeviceId && { androidDeviceId }),
      serviceLabel: `Koala Web Ordering / ${BUILD_VERSION}`,
    },
    origin,
  });
}
