import { fallbackStrings, type Strings } from "@koala/sdk";
import { getStrings as getSDKStrings } from "@koala/sdk/v4";
import { type QueryClient, useQuery } from "@tanstack/react-query";
import { useOrganization } from "./organization";
import { queryKeys } from "./queries";
import { createHttpClient } from "@/services/client";
import { __DANGEROUS_EMPTY_REQUEST_ORIGIN, getOrigin } from "@/utils";

/**
 * A cache supported hook for retrieving the current brand's string configs
 * _powered by react query_
 *
 * @returns `Strings`
 */
export function useStrings() {
  const { data: orgData } = useOrganization();

  /**
   * Make sure that `useFooterQuery` is prefetched before
   * this component renders, otherwise it will explode!
   * @see __DANGEROUS_EMPTY_REQUEST_ORIGIN
   */
  let origin: string;

  if (typeof window !== "undefined") {
    origin = getOrigin(window.location.host);
  } else {
    origin = __DANGEROUS_EMPTY_REQUEST_ORIGIN;
  }

  const { data } = useQuery({
    queryKey: [queryKeys.strings],
    queryFn: () => getSDKStrings({ client: createHttpClient({ origin }) }),
    enabled: Boolean(orgData?.organization?.id),
    refetchOnWindowFocus: false,
  });

  if (!data) {
    return fallbackStrings;
  }

  return data;
}

/**
 * A cache supported function for retrieving the current brand's web configs
 * _powered by react query_
 *
 * @returns `Promise<WebConfig>`
 */
export async function getStrings(
  queryClient: QueryClient,
  origin: string
): Promise<Strings> {
  // fetch the web config object
  const data = await queryClient.fetchQuery(
    [queryKeys.strings],
    () => getSDKStrings({ client: createHttpClient({ origin }) }),
    { staleTime: 60000 }
  );

  return data;
}
