import {
  type Basket,
  type BasketItem,
  type Category,
  type DeliveryAddress,
  type Location,
  type RewardItem,
  type SubmitBasketData,
  type OrderDetails,
  type BasketInStorage,
} from "@koala/sdk";
import { type PRODUCT_LOCATION_LABELS } from "@/constants/checkout";
import { type IBasketFulfillment } from "@/types/fulfillment";

const basketActions = {
  INIT_NEW_BASKET: "/koala/basket/INIT_NEW_BASKET",
  INIT_LOCAL_STORAGE_BASKET: "/koala/basket/INIT_LOCAL_STORAGE_BASKET",
  BASKET_ITEMS_SYNCED_WITH_STORE:
    "/koala/basket/BASKET_ITEMS_SYNCED_WITH_STORE",
  HYDRATE_BASKET: "/koala/basket/HYDRATE_BASKET",
  CLEAR_BASKET: "/koala/basket/CLEAR_BASKET",
  DEPERSONALIZE_BASKET: "/koala/basket/DEPERSONALIZE_BASKET",
  REMOVE_ITEM: "/koala/basket/REMOVE_ITEM",
  ADD_ITEM: "/koala/basket/ADD_ITEM",
  ADD_ITEM_TOAST: "/koala/basket/ADD_ITEM_TOAST",
  REPLACE_BASKET: "/koala/basket/REPLACE_BASKET",
  SUBMIT_CHECKOUT: "/koala/basket/SUBMIT_CHECKOUT",
  SUBMIT_CHECKOUT_TRIGGERED: "/koala/basket/SUBMIT_CHECKOUT_TRIGGERED",
  ADD_REWARDS: "/koala/basket/ADD_REWARDS",
  REWARDS_SUCCESS: "/koala/basket/REWARDS_SUCCESS",
  REMOVE_REWARD: "/koala/basket/REMOVE_REWARD",
  PROMO_SUCCESS: "/koala/basket/PROMO_SUCCESS",
  REMOVE_PROMO: "/koala/basket/REMOVE_PROMO",
  REWARDS: "/koala/basket/REWARDS",
  BASKET_SUCCESS: "/koala/basket/BASKET_SUCCESS",
  COMPLETED: "/koala/basket/COMPLETED",
  ORDER_CONFIRMATION: "/koala/basket/ORDER_CONFIRMATION",
  VALIDATE_BASKET: "/koala/basket/VALIDATE_BASKET",
  ADD_PICKUP_TIME: "/koala/basket/ADD_PICKUP_TIME",
  REMOVE_PICKUP_TIME: "/koala/basket/REMOVE_PICKUP_TIME",
  REHYDRATE_LOCAL_STORAGE_LOCATION:
    "/koala/basket/REHYDRATE_LOCAL_STORAGE_LOCATION",
  UPDATE_BASKET_FULFILLMENT: "/koala/basket/UPDATE_BASKET_FULFILLMENT",
  REVALIDATE_BASKET_ITEMS: "/koala/basket/REVALIDATE_BASKET_ITEMS",
  REVALIDATE_BASKET_ITEMS_SUCCESS:
    "/koala/basket/REVALIDATE_BASKET_ITEMS_SUCCESS",
  DESTROY_BASKET: "/koala/basket/DESTROY_BASKET",
  REORDER_BASKET: "/koala/basket/REORDER_BASKET",
  REORDER_BASKET_SUBMIT: "/koala/basket/REORDER_BASKET_SUBMIT",
  REORDER_BASKET_SUCCESS: "/koala/basket/REORDER_BASKET_SUCCESS",
  REORDER_BASKET_FAILURE: "/koala/basket/REORDER_BASKET_FAILURE",
  REORDER_BASKET_REVIEW_MISSING_ITEMS: "REORDER_BASKET_REVIEW_MISSING_ITEMS",
  REORDER_BASKET_CONFIRM_MISSING_ITEMS: "REORDER_BASKET_CONFIRM_MISSING_ITEMS",
  BASKET_ITEM_SUCCESS: "/koala/basket/BASKET_ITEM_SUCCESS",

  basketItemsSyncedWithStore: (items: BasketItem[]) => ({
    payload: items,
    type: basketActions.BASKET_ITEMS_SYNCED_WITH_STORE,
  }),
  initBasket: (
    location: Location,
    menuCategories: Category[],
    basketItems?: BasketItem[],
    address?: DeliveryAddress,
    time_wanted?: string,
    referral_tracking_code?: string
  ) => ({
    type: basketActions.INIT_NEW_BASKET,
    location,
    menuCategories,
    basketItems,
    address,
    time_wanted,
    referral_tracking_code,
  }),
  initializeLocalStorageBasket: () => ({
    type: basketActions.INIT_LOCAL_STORAGE_BASKET,
  }),
  clearBasket: () => ({
    type: basketActions.CLEAR_BASKET,
  }),
  depersonalizeBasket: () => ({
    type: basketActions.DEPERSONALIZE_BASKET,
  }),
  hydrateBasket: () => ({
    type: basketActions.HYDRATE_BASKET,
  }),
  removeItem: (index: number, item: BasketItem) => ({
    type: basketActions.REMOVE_ITEM,
    index,
    item,
  }),
  addItem: (
    item: BasketItem,
    label: PRODUCT_LOCATION_LABELS,
    index?: number | null
  ) => ({
    type: basketActions.ADD_ITEM,
    item,
    label,
    index,
  }),
  addItemToast: (item: BasketItem, index?: number) => ({
    type: basketActions.ADD_ITEM_TOAST,
    item,
    index,
  }),
  replaceBasket: (
    basketItems: BasketItem[],
    location: Location,
    menuCategories: Category[],
    createdAt: string,
    address?: DeliveryAddress
  ) => ({
    type: basketActions.REPLACE_BASKET,
    basketItems,
    location,
    menuCategories,
    createdAt,
    address,
  }),
  addRewards: (
    basketId: string,
    rewardCodes: string[],
    locationId: number
  ) => ({
    type: basketActions.ADD_REWARDS,
    basketId,
    rewardCodes,
    locationId,
  }),
  returnAvailableRewards: (rewards: RewardItem[]) => ({
    // the available rewards returned from OLO
    type: basketActions.REWARDS,
    rewards,
  }),
  setAppliedRewardCodes: (appliedRewards: string[]) => ({
    // the rewards codes applied to the basket
    type: basketActions.REWARDS_SUCCESS,
    appliedRewards,
  }),
  removeReward: (
    basketId: string,
    rewardCode: string,
    appliedRewards: string[],
    locationId: number
  ) => ({
    type: basketActions.REMOVE_REWARD,
    basketId,
    rewardCode,
    appliedRewards,
    locationId,
  }),
  setAppliedPromoCode: (appliedPromo: string) => ({
    type: basketActions.PROMO_SUCCESS,
    appliedPromo,
  }),
  removePromo: (basketId: string, promoCode: string, locationId: number) => ({
    type: basketActions.REMOVE_PROMO,
    basketId,
    promoCode,
    locationId,
  }),
  submitCheckoutTriggered: (orderSubmitting: boolean) => ({
    type: basketActions.SUBMIT_CHECKOUT_TRIGGERED,
    orderSubmitting,
  }),
  submitCheckout: (
    basketId: string,
    locationId: number,
    orderData: SubmitBasketData
  ) => ({
    type: basketActions.SUBMIT_CHECKOUT,
    basketId,
    locationId,
    orderData,
  }),
  success: (checkoutBasket: Basket) => ({
    type: basketActions.BASKET_SUCCESS,
    checkoutBasket,
  }),
  completed: () => ({
    type: basketActions.COMPLETED,
  }),
  confirmation: () => ({
    type: basketActions.ORDER_CONFIRMATION,
  }),
  setPickupTime: (basketId: string, locationId: number, wantedAt: string) => ({
    type: basketActions.ADD_PICKUP_TIME,
    basketId,
    locationId,
    wantedAt,
  }),
  removePickupTime: (basketId: string, locationId: number) => ({
    type: basketActions.REMOVE_PICKUP_TIME,
    basketId,
    locationId,
  }),
  rehydrateLocalStorageLocation: (location: Location) => ({
    type: basketActions.REHYDRATE_LOCAL_STORAGE_LOCATION,
    location,
  }),
  updateBasketFulfillment: (fulfillment: IBasketFulfillment) => ({
    type: basketActions.UPDATE_BASKET_FULFILLMENT,
    fulfillment,
  }),
  revalidateBasketItems: (menuCategories: Category[]) => ({
    type: basketActions.REVALIDATE_BASKET_ITEMS,
    menuCategories,
  }),
  revalidateBasketItemsSuccess: (basket: BasketInStorage) => ({
    type: basketActions.REVALIDATE_BASKET_ITEMS_SUCCESS,
    basket,
  }),
  destroyBasket: () => ({
    type: basketActions.DESTROY_BASKET,
  }),
  reorderBasket: (
    order: OrderDetails,
    label: string,
    routeToCheckout = false,
    skipUnavailable = false
  ) => ({
    type: basketActions.REORDER_BASKET,
    order,
    label,
    routeToCheckout,
    skipUnavailable,
  }),
  reorderBasketSubmit: (
    order: OrderDetails,
    label: string,
    address?: DeliveryAddress,
    routeToCheckout = false,
    skipUnavailable = false
  ) => ({
    type: basketActions.REORDER_BASKET_SUBMIT,
    order,
    label,
    address,
    routeToCheckout,
    skipUnavailable,
  }),
  reorderBasketSuccess: (order: OrderDetails, routeToCheckout = false) => ({
    type: basketActions.REORDER_BASKET_SUCCESS,
    order,
    routeToCheckout,
  }),
  reorderBasketReviewMissingItems: () => ({
    type: basketActions.REORDER_BASKET_REVIEW_MISSING_ITEMS,
  }),
  basketItemSuccess: (value: boolean) => ({
    type: basketActions.BASKET_ITEM_SUCCESS,
    value,
  }),
};

export default basketActions;
