export const DATE_FORMAT = {
  HOURS_WITH_MINUTES: "h:mmaaa",
  MONTH_WITH_YEAR: "MM/yy",
  WEEKDAY: "EEEE",
  MONTH_DAY_YEAR: "MM/dd/yyyy",
  MONTH_DAY_YEAR_DASHED: "MM-dd-yyyy",
  WRITTEN_OUT_DATE: "MMMM dd, yyyy",
  SHORT_WRITTEN_DATE: "MMM dd, yyyy",
  YEAR_MONTH_DAY_DASHED: "yyyy-MM-dd",
  YEAR_MONTH_DAY: "yyyyMMdd",
  YEAR: "yyyy",
  LABEL: "eee, MMM d",
  FULFILLMENT_TIME: "eee, MMM d 'at' h:mmaaa",
  UTC_OFFSET: "x",
  ISO_DATE: "yyyy-MM-dd'T'HH:mm:ssXXX",
  DAY_MONTH_TIME: "MM/dd, h:mmaaa",
};
