import { fireGaGenericEvent, fireGaPageView } from "./analytics";
import { pushDataLayerGenericEvent, pushDataLayerPageView } from "./tagManager";
import {
  type IGlobalEventContext,
  type IGlobalEvents,
  type GlobalEvents,
} from "@/analytics/events/constants";
import { pushGTagGenericEvent } from "@/analytics/integrations/google/gtagjs";

// Generic Events
const GoogleCategories = {
  CTA: "CTA",
  AUTH: "Auth",
  ORDER: "Order",
  LOCATIONS: "Locations",
  ACCOUNT: "Account",
};
const GoogleEvents: IGlobalEvents = {
  HEADER__VIEW_BASKET: {
    eventName: "View Basket",
    eventCategory: GoogleCategories.ORDER,
  },
  HEADER__CLOSE_BASKET: {
    eventName: "Close Basket",
    eventCategory: GoogleCategories.ORDER,
  },
  AUTH__CLICK_SIGN_UP: {
    eventName: "Click Sign Up",
    eventCategory: GoogleCategories.AUTH,
  },
  AUTH__CLICK_SIGN_IN: {
    eventName: "Click Sign In",
    eventCategory: GoogleCategories.AUTH,
  },
  AUTH__CLICK_FORGOT_PASSWORD: {
    eventName: "Click Forgot Password",
    eventCategory: GoogleCategories.AUTH,
  },
  AUTH__CLICK_GUEST_CHECKOUT: {
    eventName: "Click Guest Checkout",
    eventCategory: GoogleCategories.AUTH,
  },
  AUTH__SIGN_UP_SUCCESS: {
    eventName: "Sign Up Success",
    eventCategory: GoogleCategories.AUTH,
  },
  AUTH__SIGN_IN_SUCCESS: {
    eventName: "Sign In Success",
    eventCategory: GoogleCategories.AUTH,
  },
  LOCATIONS__VIEW_ALL_LOCATIONS: {
    eventName: "View All Locations",
    eventCategory: GoogleCategories.LOCATIONS,
  },
  LOCATIONS__SEARCH_LOCATIONS: {
    eventName: "Address Search",
    eventCategory: GoogleCategories.LOCATIONS,
  },
  LOCATIONS__TOGGLE_MOBILE_DISPLAY: {
    eventName: "Toggle Mobile Display",
    eventCategory: GoogleCategories.LOCATIONS,
  },
  LOCATIONS__TRIGGER_DELIVERY_MODAL: {
    eventName: "Trigger Delivery Modal",
    eventCategory: GoogleCategories.LOCATIONS,
  },
  LOCATIONS__PRIMARY_LOCATION_CTA: {
    eventName: "Primary Location CTA",
    eventCategory: GoogleCategories.LOCATIONS,
  },
  GENERIC__CTA: {
    eventName: null,
    eventCategory: GoogleCategories.CTA,
  },
  AUTH__CLICK_LOGOUT: {
    eventName: "Click Logout",
    eventCategory: GoogleCategories.CTA,
  },
  ACCOUNT__ADD_FAVORITE_LOCATION: {
    eventName: "Favorite Shop",
    eventCategory: GoogleCategories.ACCOUNT,
  },
  ACCOUNT__REMOVE_FAVORITE_LOCATION: {
    eventName: "Unfavorite Shop",
    eventCategory: GoogleCategories.ACCOUNT,
  },
  DELIVERY__TOGGLE: {
    eventName: "Delivery Toggle",
    eventCategory: GoogleCategories.CTA,
  },
};

/**
 * Google Analytics
 */
export class GoogleAnalytics {
  /**
   * Page View Event
   */
  static pageView(page: string) {
    // GA
    fireGaPageView(page);

    // GTM
    pushDataLayerPageView(page);

    // Gtag.js automatically tracks page views
  }

  /**
   * Generic Events
   */
  static genericEvent(globalEvent: GlobalEvents, context: IGlobalEventContext) {
    /**
     * @TODO: Consolidate use of src/utils/googleAnalytics.js into this file. There are
     *  numerous places where generic events are being fired through methods specific for
     *  each analytics platform (Koala, Google, Facebook, etc.) and fired through the
     *  collective "branch" analytics handler.
     * @TODO: Add Ecommerce events to the list of generic events in IGlobalEvents and treat
     *  Ecommerce as a "branch" analytics handler that listens only to those events.
     */

    const event = GoogleEvents?.[globalEvent];
    if (event) {
      // GA
      /** @TODO ensure that the event is defined. */
      fireGaGenericEvent(event, context);

      // GTM
      /** @TODO ensure that the event is defined. */
      pushDataLayerGenericEvent(event, context);

      // Gtag.js
      pushGTagGenericEvent(event, context);
    }
  }
}
