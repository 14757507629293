const promoCodeActions = {
  PROMO_CODE_SET: "/koala/promoCode/PROMO_CODE_SET",
  PROMO_CODE_ERROR: "/koala/promoCode/PROMO_CODE_ERROR",

  promoCodeSet: (promoCode: string) => ({
    type: promoCodeActions.PROMO_CODE_SET,
    promoCode,
  }),
  promoCodeError: (message: string) => ({
    type: promoCodeActions.PROMO_CODE_ERROR,
    message,
  }),
};

export default promoCodeActions;
